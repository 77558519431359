@import "../../../../css/mixins/reports";
@import "../../../../css/mixins/default_media_query";
@import "../../../../css/mixins/default_buttons";
@import "../../../../css/variables";

.title {
  @include report-title;
  @include mobile-sm {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-md {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-lg {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include sm {
    line-height: 1;
    margin-top: 15px;
  }
  @include md {
    margin-bottom: 0;
  }
  @include lg {
    margin-bottom: 0;
  }
  @include max-screen {
    margin-bottom: 0;
  }
  width: 100%;
}

.subtitle {
  float: left;
  color: $color-gray-title;
  @include mobile-sm {
      margin-top: 15px;
  }
  @include mobile-md {
    margin-top: 15px;
  }
  @include mobile-lg {
    margin-top: 15px;
  }
}

.table {
  @include report-table;
}

.paginate {
  @include report-paginate;
}

.header {
  @include report-header;
}

.header-column {
  @include report-header-column;
  text-align: left;
  width: 100%;
}

.body {
  @include report-body;
}

.row {
  @include report-row;
  font-size: 1rem;
  line-height: 2.5;
}

.column {
  @include report-column;
  text-align: left;
  width: 100%;
}

.download {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: white;
  background-color: $btn-bg-color;
}

.account {
  @include report-field;
 @include sm {
    width: 145px;
  }
 @include md {
    width: 145px;
  }
 @include lg {
    width: 145px;
  }
}

.client {
  @include report-field;
  @include mobile-sm {
    width: 150px;
  }
  @include mobile-md {
    width: 150px;
  }
  @include mobile-lg {
    width: 180px;
  }
  @include sm {
    width: 300px;
  }
  @include md {
    width: 225px;
  }
  @include lg {
    width: 300px;
  }
  @include max-screen {
    width: 300px;
  }
}

.no-pointer {
  cursor: auto;
}
