@import '../../../css/mixins/default_media_query';
@import '../../../css/mixins/default_title';
@import '../../../css/mixins/default_table';
@import '../../../css/mixins/default_buttons';
@import '../../../css/variables';
@import '../../../css/paginate';

.paginate {
  @include paginate;
}

.table {
  @include wrapper;
  margin-top: 20px;
}

.header {
  @include header;
}

.header-column {
  @include header-column;
}

.actions {
  @include actions;
}

.btn-add {
  @include btn-add;
}

.btn-content {
  @include btn-content;
}

.title {
  @include sm {
    display: none;
  }
  @include md-sm {
    display: none;
    /*margin-top: 25px;*/
  }
  @include md {
    display: none;
    /*margin-top: 25px;*/
  }
  @include lg {
    display: none;
    /*margin-top: 91px;*/
  }
  color: #000;
  float: left;
  font-size: 13px;
  font-weight: bold;
  padding: 10px;
}

.btn {
  @include sm {
    width: 50%;
  }
  @include md-sm {
    display: none;
    /*margin-top: 25px;*/
  }
  @include md {
    width: 185px;
  }
  @include lg {
    width: 185px;
    // margin-left: 2em;
  }
  background-color: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 2rem;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background 0.3s ease;
  margin-left: 1rem;
  &:hover {
    background-color: $btn-hover;
  }
}

.content {
  width: 100%;
  float: left;
  position: relative;
}

.row {
  @include rowtb;
}

.removed {
  border-bottom: 0;
  border-color: $removed-color !important;
  border-top: solid 1px !important;
  color: $removed-color !important;
  fill: $removed-color !important;

  &:last-child {
    border-bottom: solid 1px !important;
  }
}

.column {
  @include column;
}

.username {
  @include field;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md-sm {
    width: 180px;
  }
  @include md {
    width: 200px;
  }
  @include lg {
    width: 210px;
  }
  @include max-screen {
    width: 210px;
  }
}

.group {
  @include field;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 100px;
  }
  @include md-sm {
    width: 115px;
  }
  @include md {
    width: 130px;
  }
  @include lg {
    width: 200px;
  }
  @include max-screen {
    width: 200px;
  }
}

.email {
  @include field;

  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md-sm {
    width: 250px;
  }
  @include md {
    width: 250px;
  }
  @include lg {
    width: 250px;
  }
  @include max-screen {
    width: 250px;
  }
}

.name {
  @include field;
  @include sm {
    width: 120px;
  }
  @include md-sm {
    width: 250px;
  }
  @include md {
    width: 250px;
  }
  @include lg {
    width: 250px;
  }
  @include max-screen {
    width: 250px;
  }
}

.column-icons {
  @include mobile-sm {
    overflow: auto;
    white-space: nowrap;
    width: 30px;
  }
  @include mobile-sm {
    overflow: auto;
    white-space: nowrap;
    width: 30px;
  }
  @include mobile-sm {
    overflow: auto;
    white-space: nowrap;
    width: 30px;
  }
  @include sm {
    white-space: nowrap;
    width: 50px;
  }
  @include md {
    white-space: nowrap;
    width: 63px;
  }
  @include lg {
    width: 75px;
  }
  @include max-screen {
    width: 75px;
  }
}

.link {
  color: inherit;
  fill: inherit;
  float: left;
  position: relative;
}

.icons {
  @include icons;
  margin-top: 0.2rem;
}

.company {
  @include mobile-sm {
    width: 100px;
  }
  @include mobile-md {
    width: 100px;
  }
  @include mobile-lg {
    width: 100px;
  }
  @include sm {
    width: 135px;
  }
  @include md {
    width: 130px;
  }
  @include lg {
    width: 250px;
  }
  @include max-screen {
    width: 350px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.companyHeader {
  @extend .company;
  @include mobile-sm {
    margin-right: 30px;
    width: 100px;
  }
  @include mobile-md {
    margin-right: 30px;
    width: 100px;
  }
  @include mobile-lg {
    margin-right: 30px;
    width: 100px;
  }
}

.zone {
  @include mobile-sm {
    width: 75px;
  }
  @include mobile-md {
    width: 75px;
  }
  @include mobile-lg {
    width: 135px;
  }
  @include sm {
    width: 135px;
  }
  @include md {
    width: 150px;
  }
  @include lg {
    width: 350px;
  }
  @include max-screen {
    width: 550px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  @include mobile-sm {
    width: 125px;
  }
  @include mobile-md {
    width: 125px;
  }
  @include mobile-lg {
    width: 135px;
  }
  @include sm {
    width: 135px;
  }
  @include md {
    width: 150px;
  }
  @include lg {
    width: 350px;
  }
  @include max-screen {
    width: 550px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitleContainer {
  @include mobile-sm {
    width: 57%;
  }
  @include mobile-md {
    width: 60%;
  }
  @include mobile-lg {
    width: 65%;
  }
  @include sm {
    width: 75%;
  }
  @include md {
    width: 80%;
  }
  @include lg {
    width: 85%;
  }
  @include max-screen {
    width: 90%;
  }
  float: right;
  height: auto;
  margin-left: 10px;
  margin-top: 22px;
  position: relative;
}

.subtitle {
  color: #999;
  float: left;
  font-size: 17px;
  height: auto;
  position: relative;
  line-height: 1.6;
}

.emptyRow {
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 0;
  box-sizing: border-box;
  color: #333;
  display: flex;
  fill: #666;
  float: left;
  font-weight: 300;
  height: calc(var(37) * 1px);
  justify-content: space-between;
  padding: 4px 10px;
  position: relative;
  transition: 0.3s ease;
  width: 100%;
  &:hover {
    background-color: $color-gray-soft;
  }
}

.tableRow {
  @include tableRow;
}

.header-column {
  @include header-column;
}

.tableRowWrapper {
  @include tableRowWrapper;
}
.table-body {
  @include table-body;
}

.toggle-container {
  @include mobile-sm {
    right: 55%;
  }
  @include mobile-md {
    right: 56%;
  }
  @include mobile-lg {
    right: 56%;
  }
  @include sm {
    right: 0;
    position: absolute;
    top: 70px;
    width: 130px;
  }
  @include md {
    right: 0;
    bottom: 40px;
    width: 130px;
  }
  @include lg {
    right: 10%;
  }
  @include max-screen {
    right: 10%;
  }
  position: relative;
  right: 10%;
  float: right;
}

.logsIcon {
  font-size: 26px;
  height: 26px;
  width: 26px;
}

.paginate-wrapper {
  position: absolute;
  right: 0;
  line-height: 25px;
  top: -37px;
  color: #999;
  font-size: 1rem;
}

.account {
  @include mobile-sm {
    width: 55px;
  }
  @include mobile-md {
    width: 55px;
  }
  @include mobile-lg {
    width: 55px;
  }
  @include sm {
    width: 55px;
  }
  @include md {
    width: 55px;
  }
  @include lg {
    width: 100px;
  }
  @include max-screen {
    width: 100px;
  }
}

.btn-import {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.btn-attendant {
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: 185px;
  }
  @include md {
    width: 185px;
  }
  @include lg {
    width: 185px;
  }
  @include max-screen {
    width: 185px;
  }
}

.trackerActions {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 65px;
  }
  @include md {
    width: 65px;
  }
  @include lg {
    width: 65px;
  }
  @include max-screen {
    width: 65px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.toggleButtonWrapper {
  @include mobile-sm {
    text-align: center;
    // padding: 4px;
    width: 30px;
  }
  @include mobile-md {
    text-align: center;
    // padding: 4px;
    width: 30px;
  }
  @include mobile-lg {
    text-align: center;
    // padding: 4px;
    width: 30px;
  }
}

.toggleButton {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1.5rem;
  height: 30px;
  width: 30px;
  line-height: 20px;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background 0.3s ease;
  &:hover {
    background-color: $btn-hover;
  }
}

.emptyButton {
  float: left;
  height: 30px;
  width: 30px;
}

.adminActions {
  @include mobile-sm {
    width: 30px;
  }
  @include mobile-md {
    width: 30px;
  }
  @include mobile-lg {
    width: 30px;
  }
  @include sm {
    width: 200px;
  }
  @include md {
    width: 200px;
  }
  @include lg {
    width: 200px;
  }
  @include max-screen {
    width: 200px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.otherActions {
  @include mobile-sm {
    width: 30px;
  }
  @include mobile-md {
    width: 30px;
  }
  @include mobile-lg {
    width: 30px;
  }
  @include sm {
    width: 160px;
  }
  @include md {
    width: 160px;
  }
  @include lg {
    width: 160px;
  }
  @include max-screen {
    width: 160px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.rowExtraInfoWrapper {
  float: left;
  padding: 4px 10px 4px 35px;
  width: calc(100% - 60px);
}

.buttonMenuWrapper {
  display: flex;
  // justify-content: space-evenly;
  float: right;
  margin-right: 10px;
  margin-left: 10px;
  width: 100px;
}

.mobileTable {
  @include mobile-sm {
    height: 63%;
  }
  @include mobile-md {
    height: 63%;
  }
  @include mobile-lg {
    height: 63%;
  }
}
