@import '../../../css/mixins/default_media_query';
@import '../../../css/mixins/default_title';
@import '../../../css/mixins/default_table';
@import '../../../css/mixins/default_buttons';
@import '../../../css/variables';
@import '../../../css/paginate';


.title {
  @include general-title;
  margin-bottom: 15px;
}

.cards {
  float: left;
  position: relative;
  width: 100%;
  margin-bottom: 2rem ;
}

.card-block {
  @include sm {
    margin-top: 10px;
  }
  width: 100%;
  position: relative;
}

.card-odd {
  float: left;
  margin-left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 100%;
}

.detail-btn {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid $sidebar-background-color;
  float: left;
  font-size: 0.9rem;
  // margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
  background: transparent;
  text-decoration: none;
  text-transform: uppercase;
  &:focus {
    outline: none;
  }

}

 .trackerSvg {
    float: right;
    margin-top: 5px;
}
