$bg-color: #f7f7f7;
$bg-color-hover: #e4e4e4;
$wrapper-border-color: #efefef;
$border-height: 7px;
$border-radius: 5px;
$theme-color: #000;
$title-border-color: #dedede;
$info-color: #333;

.wrapper {
  background: $bg-color;
  border: solid 1px $wrapper-border-color;
  border-radius: $border-radius;
  box-sizing: border-box;
  float: left;
  height: auto;
  outline: 0;
  overflow: hidden;
  padding: 0 15px;
  position: relative;
  transition: .3s ease;
  // width: 31%;

  &:hover {
    background-color: $bg-color-hover;
  }
}

.border {
  background-color: $theme-color;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  height: $border-height;
  left: 0;
  position: absolute;
  top: 0;
  transition: .3s ease;
  width: 100%;
}

.content {
  float: left;
  margin-top: $border-height;
  position: relative;
  width: 100%;
}

.content-top {
  float: left;
  height: 100%;
  margin-top: $border-height;
  position: relative;
  width: 100%;
}

.title {
  border-bottom: solid 1px $title-border-color;
  box-sizing: border-box;
  color: $theme-color;
  float: left;
  font-size: 20px;
  overflow: hidden;
  padding: 10px 0;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  transition: .3s ease;
  white-space: nowrap;
  width: 100%;
}


.info {
  box-sizing: border-box;
  color: $info-color;
  display: flex;
  flex-direction: row;
  float: left;
  justify-content: space-around;
  padding: 15px 0;
  position: relative;
  width: 100%;
}

.text {
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
}
