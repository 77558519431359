@import "../../../../css/mixins/reports";
@import "../../../../css/mixins/default_media_query";
@import "../../../../css/mixins/default_buttons";
@import "../../../../css/mixins/default_table";
@import "../../../../css/mixins/default_title";
@import "../../../../css/variables";


.title {
  @include report-title;
 @include sm {
    line-height: 1;
    margin-bottom: 60px;
    margin-top: 15px;
  }
 @include md {
    margin-bottom: 0;
  }
 @include lg {
    margin-bottom: 0;
  }
  width: 100%;
}

.subtitle {
 @include sm {
    line-height: 1;
    margin-top: 15px;
  }
 @include md {
    line-height: 1;
  }
 @include lg {
    line-height: 4;
  }
}

.table {
  @include report-table;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  // @include sm {
  //   display: none;
  // }
}


.paginate {
  @include report-paginate;
}

.header {
  @include report-header;
}

.header-column {
  @include report-header-column;
  text-align: left;
  width: 100%;
}

.body {
  @include report-body;
}

.row {
  @include report-row;
  font-size: 1rem;
  line-height: 2.5;
}

.column {
  @include report-column;
}

.download {
  //@include report-download;
   border-radius: 5px;
    width: 40px;
    height: 40px;
    text-align: center;
    color: white;
    background-color: $btn-bg-color;
}

.btn-content {
 @include sm {
    display: none;
  }
  margin-bottom: 10px;
  width: auto;
  float: left;
}

.account {
  @include report-field;
 @include sm {
    width: 145px;
  }
 @include md {
    width: 145px;
  }
 @include lg {
    width: 145px;
  }
}

.user {
  @include report-field;
 @include sm {
    width: 300px;
  }
 @include md {
    width: 300px;
  }
 @include lg {
    width: 300px;
  }
}

.day {
  @include report-field;
 @include sm {
    width: 200px;
  }
 @include md {
    width: 200px;
  }
 @include lg {
    width: 200px;
  }
}

.date-created {
  @include report-field;
 @include sm {
    width: 200px;
  }
 @include md {
    width: 200px;
  }
 @include lg {
    width: 200px;
  }
}

.date-deleted {
  @include report-field;
 @include sm {
    width: 200px;
  }
 @include md {
    width: 200px;
  }
 @include lg {
    width: 200px;
  }
}

.btn-print {
  @include button;
 @include sm {
    background: $btn-bg-color;
    height: 37px;
    margin-top: 85px;
    width: 100%;
  }
 @include md {
    display: none;
  }
 @include lg {
    display: none;
  }
}

.rotate {
  transform: rotate(180deg);
}

// .customTable {
//   border: solid 1px #ddd;
//   border-radius: 5px;
//   border-spacing: 0;
//   font: normal 15px'Montserrat', sans-serif;
//   width: 100%;
//   height: 100%;
// }

// .customTable thead th {
//   background-color: #ddd;
//   border: solid 1px #ddd;
//   color: #336b6b;
//   padding: 10px;
//   text-align: left;
//   text-shadow: 1px 1px 1px #fff;
// }

// .customTable thead td {
//   background-color: #ddd;
//   border: solid 1px #ddd;
//   color: rgb(0, 0, 0);
//   padding: 10px 10px 10px 15px;
//   font: normal 16px'Montserrat', sans-serif;
//   text-align: left;
//   text-shadow: 1px 1px 1px #fff;
// }

// .customTable tbody td {
//   border: solid 1px #ddd;
//   color: #333;
//   padding: 10px 0px 10px 25px;
//   text-shadow: 1px 1px 1px #fff;
// }

// .customTable tbody tr:nth-child(odd) {
//   background-color: $color-white;
// }

// .customTable tbody tr:nth-child(even) {
//   background-color: rgb(250, 250, 250);
// }

// .customTable tbody td {
//   border-left: none;
//   border-right: none;
// }



.title {
  @include mobile-sm {
    line-height: 1;
    float: left;
    height: auto;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-md {
    line-height: 1;
    float: left;
    height: auto;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-lg {
    line-height: 1;
    float: left;
    height: auto;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include sm {
    margin-bottom: 0;
  }
  @include md {
    margin-bottom: 0;
  }
  @include lg {
    margin-bottom: 0;
  }
  @include max-screen {
    margin-bottom: 0;
  }
  color: $color-grafite;
  float: left;
  font-size: 1.1rem;
  // height: 48px;
  line-height: 1;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.mainTitle {
  @include mobile-sm {
    float: left;
    width: 100%;
  }
  @include mobile-md {
    float: left;
    width: 100%;
  }
  @include mobile-lg {
    float: left;
    width: 100%;
  }
}

.subtitle {
  @include subtitle;
  }

.tableWrapper {
  @include mobile-sm {
    margin-left: 0;
  }
  @include mobile-md {
    margin-left: 0;
  }
  @include mobile-lg {
    margin-left: 0;
  }
  margin-left: 5%;
}
