@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';
@import '../../../../css/mixins/default_table';

.table {
  @include table;
  float: left;
  height: auto;
  position: relative;
  width: 100%;
}

.content {
  @include sm {
    margin-top: 5px;
     }
  width: 100%;
  float: left;
  position: relative;
}

.iotHeaderText {
  @include mobile-sm {
    margin-top: 10px;
    line-height: 1;
  }
  @include mobile-md {
    margin-top: 10px;
    line-height: 1;
  }
  @include mobile-lg {
    margin-top: 10px;
    line-height: 1;
  }
  @include sm {
    margin-top: 10px;
    line-height: 1;
  }
  width: 100%;
  color: $color-grafite;
  float: left;
  font-size: 0.8;
  height: 1.2rem;
  line-height: 3;
  margin-bottom: 1rem;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
}

.iotMenu {
  float: left;
  margin-bottom: 10px;
  width: 100%;
}

.iotButton {
  background-color: transparent;
  border: 0;
  color: $color-gray;
  cursor: pointer;
  font-size: 16px;
  outline: none;
}


.active {
  border-bottom: solid 1px;
  color: black;
  font-weight: bold;
}

.header {
  @include header;
}

.body {
  @include body;
}

.removed {
  @include removed;
  border-top: solid 1px !important;
}

.row {
  @include tableRow;
  border-bottom: 1px solid #ddd;
   &:hover {
    background-color: $color-gray-soft;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.headerTextContainer {
  width: 270px;
}

.headerImeiContainer {
  @extend .headerTextContainer;
  @include sm {
    width: 130px;
  }
}

.headerNameContainer {
  @extend .headerTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 130px;
  }
}

.headerCompanyContainer {
  @extend .headerTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.headerBatteryContainer {
  @extend .headerTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md {
    display: none;
  }
}

.headerLastEntryContainer {
  @extend .headerTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include sm-md {
    display: none;
  }
}

.headerBtn {
  background: transparent;
  border: 0;
  color: #333;
  cursor: pointer;
  float: left;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0;
  position: relative;
  text-transform: uppercase;
}

.iotTableBodyField {
  width: 265px;
  float: left;
  font-size: 1rem;
  line-height: 2.5;
}

.tableImeiField {
  @extend .iotTableBodyField;
  @include sm {
    width: 130px;
  }
}

.tableNameField {
  @extend .iotTableBodyField;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 120px;
  }
}

.tableCompanyField {
  @extend .iotTableBodyField;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.tableBatteryField {
  @extend .iotTableBodyField;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md {
    display: none;
  }
}

.tableLastEntryField {
  @extend .iotTableBodyField;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include sm-md {
    display: none;
  }
}

.tableUpperHeader {
  float: left;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
}

.headerStatus {
  @include sm {
    margin-right: 10px;
  }
  @include sm-md {
    margin-right: 10px;
  }
  @include md-sm {
    margin-right: 10px;
  }
  @include md {
    margin-right: 10px;
  }
  @include lg {
    margin-right: 10px;
  }
  width: 50px;
}

.statusTableBodyField {
  @include md {
    width: 50px;
  }
  @include lg {
    width: 50px;
  }
  float: left;
  font-size: 1rem;
  line-height: 2.5;
  width: 50px;
}

.online {
  background: #24be30;
  border-radius: 100%;
  display: block;
  height: 15px;
  margin: 0;
  margin-top: 6px;
  width: 15px;
}

.offline {
  background: #ededed;
  border-radius: 100%;
  display: block;
  height: 15px;
  margin: 0;
  margin-top: 6px;
  width: 15px;
}
