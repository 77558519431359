@import 'mixins/utils';
@import 'mixins/default_media_query';
@import 'variables';


.emergency-call {
  display: flex;
  flex-flow: column wrap;
  font-family: $globalfont;
  height: 768px;
  justify-content: space-between;
  width: 1000px;
}

.emergency__container-left {
  width: 59%;
  padding: 10px;
    box-sizing: border-box;
    border: solid 1px $color-gray-form;
    border-radius: 12px;
    height: 186px;
}

.emergency__container-right{
  width: 39%;
  display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 186px;
}

.emergency__container__line{
  height: 24px;
  width: 100%;
  font-weight: 300;
  font-size: 17px;
  padding: 2px 10px;
  box-sizing: border-box;
}

.emergency__container__line:nth-child(even) {
    background: $color-gray-soft;
}

.emergency__container__line:nth-child(odd){
  background:$color-odd;
}

.emergency__container__line--header {
  color: $color-tecno;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.emergency__container__line--body {
  color: $color-gray-form;
  border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.emergency__container__line--alarm {
  border-radius: 4px;
}

.emergency-container-line-address{
  height: 38px;
}

.emergency__container__line__alarmItem{
  color: $color-gray-form;
}

.emergency__container__line__alarmItem--name{
  width: 40%;
}

.emergency__container__line__alarmItem--number{
  width: 20%;
}

.emergency__container__line__alarmItem--date{
  width: 40%;
}

.emergency__container__line__alarmItem:last-child{
  text-align: right;
}

.emergency__contact-set{
  height: 22px;
  background-color:$color-gray-soft;
  border-radius: 4px;
  color: $color-tecno;
  font-weight: 300;
  padding: 2px 10px;
}

.emergency__container-header{
  width: $emergency-table-width;
  float: left;
  position: relative;
}

.emergency__container-body{
  width: calc(100% - #{$emergency-table-width});
  float: left;
  position: relative;
}

.emergency__containerItem{
  box-sizing: border-box;
    border: solid 1px $color-gray-form;
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    overflow: auto;
}

.emergency__containerItem--contact{
  max-height: 267px;
}

.emergency__containerItem--events{
  height: 200px;
}

.emergency__containerComment{
  width: 100%;
    height: 94px;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px $color-gray-form;
    border-radius: 12px;
}

.emergency__title{
  width: 100%;
  height: 30px;
  border-radius: 12px;
  background-color: $color-tecno;
  color: $color-white;
  padding: 3px 10px;
  font-weight: 400;
  font-size: 23px;
  box-sizing: border-box;
}

.emergency__containerComment__texfield{
  border-radius: 12px;
    border: solid 1px;
    color:$color-gray-input;
    width: 100%;
    box-sizing: border-box;
    margin-top: 7px;
    padding: 10px;
}

.atttend-emergency-client {
  width:700px;
  float:left;
  position:relative;
  color:$color-gray-icons;
  border: solid;
  padding: 15px;
}

.contact-btn-container {
  width: 100%;
  float: left;
  position: relative;
  height: 40px;
}

.contact-btn-content {
    float: left;
    position: relative;
    display: inline-block;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    width: 310px;
    top: 50%;
}

.attend-title{
  width: 100%;
  float: left;
  position: relative;
  text-align: center;
  color: $color-gray-form;
  font-size: 23px;
  font-family: $globalfont;
  margin-top: 20px;
}

.attend-list-container{
  width: 100%;
  height: 400px;
  float: left;
  color: $color-gray-form;
  border: solid 1px;
  border-radius: 10px;
  font-family: $globalfont;
  padding: 10px;
  overflow-y:auto;
  clear: left;
  margin-top: 4px;
  box-sizing: border-box;
}

.attend-list-container-header{
  color: $color-white;
    background: $color-tecno;
    width: 100%;
    height: 30px;
    padding: 6px;
    box-sizing: border-box;
    font-size: 15px;
    border-radius: 15px;
    font-weight: 300;
}

.attend-list-container-body{
  color: $color-gray-form;
    width: 100%;
    height: 30px;
    padding: 8px 6px;
    box-sizing: border-box;
    font-size: 12px;
    border-radius: 5px;
    font-weight: 300;
}

.attend-list-container-body:nth-child(even) {
    background:$color-gray-soft;
}

.attend-list-container-body:nth-child(odd){
  background:$color-odd;
}

.attend-audio-text {
  @include sm {
    font-size: 15px;
    margin-left: 0;
  };
  color: $color-tecno;
  font-family: $globalfont;
  font-size: 16px;
  font-weight: bold;
  margin-left: 17px;
  margin-top: 15px;
}

.attend-item-id {
  width: 40px;
  text-align: center;
}

.attend-item{
  width: 111px;
  border-left: solid 1px;
  box-sizing: border-box;
  text-align: center;
}

.attend-item:first-child{
  border-left: 0;
}

.attend-item-date{
  width: 182px;
  border-left: solid 1px;
  box-sizing: border-box;
  text-align: center;
}

.attend-item-default{
  box-sizing: border-box;
  text-align: left;
}

.attend-item-default--order{
  cursor: pointer;
  fill: $color-white;
  @include transition(all .3s ease);
}

.attend-item-default--order:hover{
  color:$color-gray-soft;
  fill: $color-gray-soft;
}

.attend-item-default--user-attendant-id{
  width: 40px;
    margin-left: 5px;
}

.attend-item-default--user-status{
  width: 90px;
}

.attend-item-default--attendant-status{
    width: 120px;
}

.attend-item-default--user-name{
  width: 100px;
}

.attend-item-default--attendant-name{
  width: 85px;
}

.attend-item-default--user-date{
  width: 214px;
  text-align: center;
}

.attend-item-default--attendant-date{
  width: 310px;
    text-align: center;
}

.attend-item-default--user-attendant{
  width: 111px;
}

.attend-item-default--user-action{
  width: 100%;
  height: 100%;
}

.attend-item-default--attendant-action{
  width: 115px;
    text-align: center;
}

.attend-btn {
    border: 0;
    height: 24px;
    color: $color-white;
    font-size: 12px;
    border-radius: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    float: left;
    position: relative;
    @include transition(background 0.3s ease);
    padding: 0;
    width: 80px;
    margin-left: 50%;
    transform: translateX(-50%);

}

.attend-det {
  border: 0;
  height: 24px;
  color: $color-white;
  font-size: 12px;
  border-radius: 12px;
  font-weight: 300;
  text-align: center;
  cursor: pointer;
  float: left;
  position: relative;
  @include transition(background 0.3s ease);
  padding: 0;
  width: 100%;
  max-width: 100px;
  margin-right: 1rem;
  // transform: translateX(-50%);

}

.attend-list-btn{
  margin-top: -5px;
}
