.poligon:hover {
  cursor: pointer;
  fill: #d7d4d4;
  stroke: #d7d4d4;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.poligon {
  fill: white;
  stroke: rgb(255, 255, 255);
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}
