@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.legend-wrapper {
  float: left;
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.legend-header {
  float: left;
  text-align: center;
  width: 100px;
}

.legend-content {
  float: left;
  color: $color-white;
  text-align: center;
  width: 100px;
}
