@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.trackerTitle {
  @include mobile-sm {
    line-height: 1;
    margin-top: 15px;
  }
  @include mobile-md {
    line-height: 1;
    margin-top: 15px;
  }
  @include mobile-lg {
    line-height: 1;
    margin-top: 15px;
  }
  @include sm {
    height: auto;
    margin-bottom: 10px;
  }
  color: $color-grafite;
  float: left;
  font-size: 1.1rem;
  height: 48px;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
  width: 100%;

  &--left {
    text-align: left;
  }
}

.mapWrapper {
  @include mobile-sm {
    margin-top: 5%;
    position: relative;
    width: 100%;
  }

  @include mobile-md {
    margin-top: 5%;
    position: relative;
    width: 100%;
  }

  @include mobile-lg {
    margin-top: 5%;
    position: relative;
    width: 100%;
  }

  @include sm {
    margin-top: 5%;
    position: relative;
    width: 100%;
  }

  @include md-sm {
    width: 100%;
  }

  @include md {
    width: 100%;
  }

  @include lg {
    width: 100%;
  }

  @include max-screen {
  }
  float: left;
  width: 100%;
}

.mapContent {
  @include mobile-sm {
    height: 320px;
  }
  @include mobile-md {
    height: 320px;
  }
  @include mobile-lg {
    height: 320px;
  }
  @include sm {
    height: 320px;
  }
  height: 380px;
  padding: 10px 0;
  margin: 0 auto;
  width: 100%;
}

.trackerContent {
  float: left;
  height: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.trackerMenu {
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  float: left;
  position: relative;
  width: 100%;
}

.trackerMenuOption {
  background-color: transparent;
  border: 0;
  color: $color-grafite;
  cursor: pointer;
  float: left;
  font-size: 0.9rem;
  height: 100%;
  text-align: left;
  width: 100%;
  border-bottom: solid 1px $btn-bg-color;
  text-transform: uppercase;
  &:focus {
    outline: none;
  }
}

.trackerMenuContainer {
  display: block;
  float: left;
  margin-left: 5%;
  width: 100%;
}

.trackerMenuContent {
  display: block;
  float: left;
  width: 100%;
}

.trackerMenuValue {
  display: block;
  float: left;
  margin-top: 15px;
  width: 100%;
}

.trackerMenuDate {
  float: left;
  margin-left: 5px;
  position: relative;
  width: 600px;
}

.trackerMenuText {
  border: 0;
  color: #415057;
  float: left;
  font-size: 1rem;
  text-align: left;
  width: 50px;
}

.rotate {
  transform: rotate(180deg);
}

.trackerMenuBtn {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  display: block;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 37px;
  margin-top: 15px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  transition: background 0.3s ease;
  font-weight: bold;
  width: 50%;
  &:hover {
    background-color: $btn-hover;
  }
}

.trackerPositionBtn {
  @include mobile-sm {
    width: 100%;
    right: initial;  
  }
  @include mobile-md {
    width: 100%;
    right: initial;
  }
  @include mobile-lg {
    width: 100%;
    right: initial;
  }
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  display: block;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  /* margin-top: 15px; */
  padding: 0;
  position: absolute;
  text-align: center;
  transition: background 0.3s ease;
  width: 25%;
  right: 0.5rem;
  &:hover {
    background-color: $btn-hover;
  }
}

.trackerSvg {
  float: right;
  margin-top: 5px;
}

.mapIcon {
  cursor: pointer;
  margin-left: -12px;
  margin-top: -41px;
  transition: fill 0.3s ease;
}

.mapDot {
  background: #2a86d7;
  border-radius: 100%;
  // background: radial-gradient(circle at 2px 5px, #2a86d7, #3a3434);
  display: block;
  height: 15px;
  margin: 0;
  width: 15px;
}

.errorWrapper {
  @include mobile-sm {
    float: left;
    margin-top: 15px;
  }
  @include mobile-md {
    float: left;
    margin-top: 15px;
  }
  @include mobile-lg {
    float: left;
    margin-top: 15px;
  }
}

.trackerError {
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  color: $color-gray;
  float: right;
  font-size: 20px;
  height: auto;
  position: relative;
  white-space: pre-line;
  width: 65%;
  word-wrap: break-word;
}

.disabled {
  color: #d2d2d2;
}

.hideModal {
  visibility: hidden;
}

.modal {
  z-index: 1002;
}

.leafletContainer {
  @include mobile-sm-height {
    height: 320px;
  }
  @include mobile-md-height {
    height: 320px;
  }
  @include mobile-lg-height {
    height: 320px;
  }
  height: 380px;
  width: 100%;
  margin: 0 auto;
}

.mapHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.generalInfoContainer {
  width: 100%;
}

.mapButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.mapHeaderSubtitleContainer {
  // color: $color-gray;
  font-size: 0.8rem;
  height: auto;
  text-transform: uppercase;
}

.headerSubtitle {
  margin-right: 5px;
  margin-left: 5px;
}

.generalInfoLastBtn {
  @include mobile-sm {
    width: 100%;
    right: initial;  
  }
  @include mobile-md {
    width: 100%;
    right: initial;
  }
  @include mobile-lg {
    width: 100%;
    right: initial;
  }
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  display: block;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  /* margin-top: 15px; */
  padding: 0;
  text-align: center;
  transition: background 0.3s ease;
  width: 25%;
  right: 0.5rem;
  &:hover {
    background-color: $btn-hover;
  }
}

.updateLocationBtn {
  @extend .generalInfoLastBtn;
  background: $color-green;
}

.continuousLocationBtn {
  @extend .generalInfoLastBtn;
  background: $color-tomato;
}
