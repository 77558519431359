@mixin mobile-sm {
    @media screen and (max-width: 320px) {
      @content;
    }
  }
  
  @mixin mobile-md {
    @media screen and (min-width: 321px) and (max-width: 375px) {
      @content;
    }
  }
  
  @mixin mobile-lg {
    @media screen and (min-width: 376px) and (max-width: 425px) {
      @content;
    }
  }
  
  @mixin sm {
    @media screen and (min-width: 426px) and (max-width: 768px) {
      @content;
    }
  }
  
  @mixin md-sm {
    @media screen and (min-width: 769px) and (max-width: 920px) {
      @content;
    }
  }
  
  @mixin sm-md {
    @media screen and (max-width: 1279px) {
      @content;
    }
  }
  
  @mixin md-lg {
    @media screen and (min-width: 768px) {
      @content;
    }
  }
  
  @mixin md {
    @media screen and (min-width: 921px) and (max-width: 1024px) {
      @content;
    }
  }
  
  @mixin lg {
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      @content;
    }
  }
  
  @mixin max-screen {
    @media screen and (min-width: 1441px) {
      @content;
    }
  }
  
  @mixin mobile-sm-height {
    @media screen and (max-height: 490px) {
      @content;
    }
  }
  
  @mixin mobile-md-height {
    @media screen and (min-height: 491px) and (max-height: 590px) {
      @content;
    }
  }
  
  @mixin mobile-lg-height {
    @media screen and (min-height: 591px) and (max-height: 700px) {
      @content;
    }
  }
  
  @mixin sm-height {
    @media screen and (min-height: 701px) and (max-height: 800px) {
      @content;
    }
  }
  
  @mixin md-height {
    @media screen and (min-height: 801px) and (max-height: 900px) {
      @content;
    }
  }
  
  @mixin lg-height {
    @media screen and (min-height: 901px) and (max-height: 1000px) {
      @content;
    }
  }
  
  @mixin max-screen-height {
    @media screen and (min-height: 1001px) {
      @content;
    }
  }
  