@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

$height: 26px;

.trackerMenuContainer {
  display: block;
  float: left;
  width: 100%;
}

.trackerMenuValue {
  display: block;
  float: left;
  margin-top: 15px;
  width: 100%;
}

.trackerMenu {
  @include mobile-sm {
    position: relative;
  }
  @include mobile-md {
    position: relative;
  }
  @include mobile-lg {
    position: relative;
 
  }
  float: left;
  position: relative;
  margin-bottom: 15px;
  top: 100%;
  width: 100% 
}

.trackerMenuText {
  border: 0;
  color: #415057;
  float: left;
  font-size: 0.8rem;
  text-align: left;
  text-transform: uppercase;
  font-weight: bolder;
}

.rotate {
  transform: rotate(180deg);
}

.trackerMenuBtn {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  display: block;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 37px;
  margin-top: 15px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  transition: background 0.3s ease;
  font-weight: bold;
  width: 50%;
  &:hover {
    background-color: $btn-hover;
  }
}


.geofenceSubMenuBtn {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  display: block;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 37px;
  margin-top: 15px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  transition: background 0.3s ease;
  font-weight: bold;
  width: 100%;
  &:hover {
    background-color: $btn-hover;
  }
}

.geofenceMenuOption {
  background-color: transparent;
  border: 0;
  color: $color-gray;
  cursor: pointer;
  float: left;
  height: 100%;
  text-align: left;
}

.geofenceSubmenuOption {
  color: $color-gray;
  float: left;
  height: auto;
  margin-top: 10px;
  position: relative;
  width: 100%;
}

.input {
  
  border: solid 1px #dfdfdf;
  box-sizing: border-box;
  color: #808285;
  float: left;
  font-family: $globalfont;
  height: 26px;
  outline: 0;
  padding: 0 5px;
  position: relative;
  transition: 0.3s ease;
  width: 100%;

  &:hover {
    border-color: $color-tecno;
  }
}

.block-value {
  @include mobile-sm {
    margin-top: 2px;
    margin-right: 0;
  }
  @include mobile-md {
    margin-top: 2px;
    margin-right: 0;
  }
  @include mobile-lg {
    margin-top: 2px;
    margin-right: 0;
     }
  float: right;
  font-size: 1rem;
  height: 32px;
  line-height: 2;
  margin-top: -3px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;

}

// .checkbox {
//   margin-left: 10px;
// }

.checkbox {
  width:2rem;
  height:2rem;
  margin-left: 0.5rem;
  @include mobile-sm {
    padding-top:0.5rem;
  } 
  @include mobile-md {
    padding-top:0.5rem;
  } 
  @include mobile-lg {
    padding-top:0.5rem;
  } 
  @include sm {
    padding-top:0.5rem;
  } 
  @include md-sm {
    padding-top:0.5rem;
  }
}


.selectInput {
  -moz-appearance: none;
  -webkit-appearance: none;
  // appearance: none;
  border: solid 1px #ccc;
  // border-radius: 0;
  clear: left;
  float: left;
  height: 26px;
  outline: 0;
  padding: 0 8px;
  width: 100%;

  &--enable {
    // background: transparent url("../../../../../img/select-icon.png") no-repeat center right 8px;
    background-size: 12px;
    color: $color-gray;
    cursor: pointer;
    transition: 0.3s ease;

    &:focus {
      border-color: $color-tecno;
    }

    &:hover {
      border-color: $color-tecno;
    }
  }
}

.selectInterval {
  width: 17%;
}

.mapWrapper {
  @include mobile-sm {
    margin-top: 15px;
    position: relative;
    width: 100%;
  }
  @include mobile-md {
    margin-top: 15px;
    position: relative;
    width: 100%;
  }
  @include mobile-lg {
    margin-top: 15px;
    position: relative;
    width: 100%;
  }
  @include sm {
    margin-top: 5%;
    position: relative;
    width: 100%;
  }
  width: 100%;
  float: right;
}

.mapContent {
  height: 650px;
  margin: 0 auto;
  width: 100%;
}

.geofenceTriangle {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  float: right;
  height: 0;
  margin-left: 2px;
  margin-top: 5px;
  position: relative;
  width: 0;
}

.rotate {
  transform: rotate(180deg);
}

.geofenceSlider {
  -webkit-appearance: none;
  background: #d3d3d3;
  border-radius: 50px;
  height: 25px;
  opacity: 0.7;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  width: 100%;
}

.geofenceSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: $btn-bg-color;
  border-radius: 50px;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.geofenceSlider:hover {
  opacity: 1;
}

.leafletContainer {
  @include mobile-sm {
    height: 450px;
  }
  @include mobile-md {
    height: 450px;
  }
  @include mobile-lg {
    height: 450px;
  }
  height: 650px;
  width: 100%;
  margin: 0 auto;
}

.geofenceNotConfigured {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
