.softphoneKeyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.keyboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.key {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.backspace,
.confirm {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
}

.confirm {
  background-color: #00b8cb;
  color: white;
  &:hover {
    background-color: #0098a8;
  }
}
