@import '../../css/variables';

.icon {
  // bottom: 5px;
  // cursor: pointer;
  // float: right;
  // margin-left: 5px;
  // margin-right: 15px;
  // position: relative;
  // bottom: 3rem;
  cursor: pointer;
  float: right;
  margin-left: 5px;
  right: 1rem;
  position: relative;
}

.icon .iconText {
  background-color: $btn-bg-color;
  border-radius: 6px;
  color: $color-white;
  float: left;
  font-family: "Montserrat", sans-serif;
  padding: 5px 0;
  /* display: inline-block; */
  position: absolute;
  bottom: 100%;
  // left: 2rem;
  transform: translateX(-100%);
  text-align: center;
  visibility: hidden;
  width: 260px;
  z-index: 1002;
  left: 0;

}
.icon:hover .iconText {
  visibility: visible;
}



