@import 'mixins/default_media_query';
@import 'variables';


.component-header {
  color: $color-tecno;
  float: left;
  font-size: 0.7rem;
  margin-bottom: 1rem;
  font-weight: bold;
  margin-left:0.5rem;
  @include mobile-sm {
    margin-left: 0;
    text-align: left;
  }
  @include mobile-md {
    margin-left: 0;
    text-align: left;
  }
  @include mobile-lg {
    margin-left: 0;
    text-align: left;
  }
  @include sm {
    margin-left: 0;
    text-align: left;
  }
}
.component-left {
  float: left;
  text-align: right;
  width: 25%;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
}
.component-right {
  float: left;
  text-align: left;
  padding: 0 10px;
  width: 75%;
}
.component-row {
  float: left;
  width: 100%;
  padding-top: 0.35rem;
  border-bottom: 1px solid $color-gray-soft;
  // line-height: 1.5rem;
  min-height: 2.5rem;
}
.component-label {
  box-sizing: border-box;
  color: $color-gray;
  float: left;
  font-size: 0.7rem;
  height: auto;
  position: relative;
  text-align: right;
  text-transform: uppercase;
  width: 25%;
  // padding-bottom: 5px;
  font-weight:bold; 
  @include mobile-sm {
    width: 100%;
    text-align: left;
  }
  @include mobile-md {
    width: 100%;
    text-align: left;
  }
  @include mobile-lg {
    width: 100%;
    text-align: left;
  }
  @include sm {
    width: 100%;
    text-align: left;
  }
  @include md-sm {
    width: 100%;
    text-align: left;
  }
  @include md {
    width: 25%;
  }
  @include lg {
    width: 25%;
  }
  @include max-screen {
    width: 25%;
  }
}

.component-text {
  box-sizing: border-box;
  font-size: 1rem;
  padding-left: 1rem;
  float: left;
  padding-bottom: 0.3rem;
  line-height: 1.2rem;
  @include mobile-sm {
    width: 100%;
    text-align: left;
    max-height: 4rem;
  }
  @include mobile-md {
    width: 100%;
    text-align: left;
    max-height: 4rem;
  }
  @include mobile-lg {
    width: 100%;
    text-align: left;
    max-height: 4rem;
  }
  @include sm {
    width: 100%;
    text-align: left;
    max-height: 4rem;
  }
  @include md-sm {
    width: 100%;
    text-align: left;
    max-height: 4rem;
  }
}
