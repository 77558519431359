@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.container {
  display: flex;
  padding-right: 10px;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
  font-family: $globalfont;
  font-weight: 700;
  width: 380px;
  color: $color-white;
}

.iconButton {
  -webkit-appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  color: $color-white;
}
