@import "../../../../css/mixins/reports";
@import "../../../../css/mixins/default_media_query";
@import "../../../../css/mixins/default_buttons";
@import "../../../../css/variables";
@import "../../../../css/mixins/default_title";

.title {
  @include report-title;
  @include mobile-sm {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-md {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-lg {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include sm {
    line-height: 1;
    margin-top: 15px;
  }
  @include md {
    margin-bottom: 0;
  }
  @include lg {
    margin-bottom: 0;
  }
  @include max-screen {
    margin-bottom: 0;
  }
  width: 100%;
}

.subtitle {
  @include subtitle;
}

.table {
  @include report-table;
   // @include sm {
  //   display: none;
  // }
}


.paginate {
  @include report-paginate;
}

.header {
  @include report-header;
}

.header-column {
  @include report-header-column;
  text-align: left;
  width: 100%;
}

.body {
  @include report-body;
}

.row {
  @include report-row;
  font-size: 1rem;
  line-height: 2.5;
}

.column {
  @include report-column;
}

.download {
  //@include report-download;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  color:$color-white;
  background-color: $btn-bg-color;
}

.id {
  width: 60px;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include md  {
    width: 60px;
  }
  @include lg {
    width: 55px;
  }
  @include max-screen {
    width: 55px;
  } 
}

.account {
  width: 85px; 
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include md-sm  {
    display: none;
  }
  @include md  {
    display: none;
  }
  
 
}

.client {
  @include report-field;
  @include mobile-sm {
    width: 150px;
  }
  @include mobile-md {
    width: 180px;
  }
  @include mobile-lg {
    width: 200px;
  }
  @include sm {
    width: 220px;
  }
  @include md-sm {
    width: 220px;
  }
  @include md {
    width: 225px;
  }
  @include lg {
    width: 250px;
  }
  @include max-screen {
    width: 250px;
  }
}

.attendant {
  @include report-field;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 220px;
  }
  @include md-sm {
    width: 220px;
  }
  @include md {
    width: 225px;
  }
  @include lg {
    width: 250px;
  }
  @include max-screen {
    width: 250px;
  }
}

.dateOpen {
  @include report-field;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  width: 200px;
}

.dateClosed {
  @include report-field;
  @include mobile-sm {
    width: 150px;
  }
  @include mobile-md {
    width: 180px;
  }
  @include mobile-lg {
    width: 200px;
  }
  width: 200px;
}

.audioCall {
  @include report-field;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 55px;
  }
  @include md-sm {
    width: 55px;
  }
  @include md {
    width: 85px;
  }
  @include lg {
    width: 55px;
  }
  @include max-screen {
    width: 55px;
  }
}

// .btn-print {
//   @include button;
//   @include sm {
//     height: 37px;
//     width: 100%;
//   }
//   @include md {
//     display: none;
//   }
//   @include lg {
//     display: none;
//   }
// }

.rotate {
  transform: rotate(180deg);
}
