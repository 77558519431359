$select-bg: #fcfcfc;
$select-border: #efefef;
$select-color: #555;

.wrapper {
  float: left;
  //overflow-y: auto;
  // padding: 15px 0;
  position: relative;
  transition: 0.3s ease;
  width: 100%;
  min-height: 3rem;
}

.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: $select-bg;
  border-color: $select-border;
  color: $select-color;
  cursor: pointer;
  float: left;
  font-size: 16px;
  padding: 5px 15px;
  position: relative;
  transition: 0.3s ease;
  width: 100%;
}

.code {
  float: left;
  font-size: 16px;
  margin-top: 10px;
  position: relative;
  width: 100%;
}
