@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.wrapper {
  float: right;
  padding-top: 50px;
  position: relative;
  width: 20%;
  height: 100%;
}

.isVisible {
  @include mobile-sm {
    width: 100%;
    padding-left: 50px;
  }
  @include mobile-md {
    width: 100%;
    padding-left: 50px;
  }
  @include mobile-lg {
    width: 100%;
    padding-left: 50px;
  }
  @include sm {
    width: 100%;
    padding-left: 50px;
  }
  @include md-sm {
    width: 100%;
    padding-left: 50px;
  }
  visibility: visible
}

.isHidden {
  @include mobile-sm {
    width: 0 !important;
  }
  @include mobile-md {
    width: 0 !important;
  }
  @include mobile-lg {
    width: 0 !important;
  }
  @include sm {
    width: 0 !important;
  }
  @include md-sm {
    width: 0 !important;
  }
  @include sm-md {
    width: 0 !important;
  }
  visibility: hidden
}

.errorMessageWrapper {
  float: left;
  padding: 15px;
  position: relative;
  width: 100%
}

.errorTitle {
  box-sizing: border-box;
  color: $color-grafite;
  float: left;
  position: relative;
  width: 100%;
  font-size: 1.1rem;
  font-weight: bold;
}

.errorText {
  box-sizing: border-box;
  color: $color-grafite;
  float: left;
  position: relative;
  width: 100%;
  font-size: 1.1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
