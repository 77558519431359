@import "mixins/utils";
@import 'variables';

* {
margin: 0;
}

.app {
  width:100%;
  height: 100%;
  position: absolute;
  display: block;
    overflow: hidden;
}

.app__container {
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: 0;
}

.app-general-container {
  width: 100%;
  height: 100%;
  float: left;
  position: absolute;
  z-index: 0;
}

.transmissor-form-container {
    width: 585px;
    max-height: 700px;
    color: #808285;
    border: solid 1px;
    border-radius: 12px;
    padding: 20px;
    font-family: $globalfont;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 3px;
    float: left;
}

.account-form-container {
    width: 595px;
    height: 650px;
    color: #808285;
    border: solid 1px;
    border-radius: 12px;
    padding: 20px;
    font-family: $globalfont;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 3px;
    box-sizing: border-box;
}

.form-input-container{
  float: left;
    position: relative;
    margin-top: 15px;
}

.form-input-container-account{
  width: 171px;
}

.form-input-container-transmissor{
  width: 100%;
}



.form-label{
    color: $color-tecno;
    margin-bottom: 3px;
    float: left;
    position: relative;
    margin-left: 8px;
}

.form-client-name{
    margin-top: 7px;
    float: left;
    position: relative;
    clear: left;
    margin-left: 8px;
}

.form-select--priority{
    width: 195px;
}





.form-select-enable:hover, .form-select-enable:focus{
    border-color: $color-tecno;
}

.clear{
  clear: both;
}

.form-input-30-char{
  width: 290px;
}

*:focus{
  outline: none;
}

.form-btn {
    border: 0;
    height: 20px;
    background: $color-tecno;
    color: $color-white;
    font-size: 15px;
    border-radius: 12px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    float: left;
    position: relative;
    @include transition(background 0.3s ease);
    padding: 0;
}

.form-btn:hover{
  background: #008fa2;
}

.form-btn-invalid {
  width: 124px;
    border: 0;
    height: 20px;
    background: #EDEDED;
    color: $color-white;
    font-size: 15px;
    border-radius: 12px;
    font-weight: 400;
    text-align: center;
    cursor: default;
    float: left;
    position: relative;
    @include transition(background 0.3s ease);
    padding: 0;
}

.form-btn-cancel{
    width: 124px;
}

.form-btn-save{
  margin-left: 62px;
    width: 124px;
}

.form-btn-add{
    bottom: 0;
    position: absolute;
    right: 0;
    width:166px;
    font-weight: 300;
}

.emergency-call-solved-btn{
    height: 34px;
    background: orange;
    border: solid 1px #af7202;
    cursor: pointer;
    color: $color-white;
    font-size: 17px;
    font-family: $globalfont;
    width: 100%;
}

.perfil-attendList{
    width: 710px;
    float:left;
    color: #666;
    border: solid 1px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 15px;
}

.control {
    font-size: 18px;
    position: relative;
    display: block;
    margin-bottom: 15px;
    padding-left: 30px;
    cursor: pointer;
}

.control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    width: 18px;
    height: 18px;
    background: #c3f6f9;
    border: solid 1px;
    border-radius: 4px;
    box-sizing: border-box;
    @include transition(all 0.3s ease);
}

.control:hover input ~ .control__indicator{
    background: $color-blue;
    border: solid 1px;
}

.control input:checked ~ .control__indicator {
    background: $color-blue;
}

.control:hover input:checked ~ .control__indicator {
    background: #0e647d;
    border-color: #0e647d;
}

.control input:disabled ~ .control__indicator {
    pointer-events: none;
    opacity: .6;
    background: #e6e6e6;
}

.control__indicator:after {
    position: absolute;
    display: none;
    content: '';
}

.control input:checked ~ .control__indicator:after {
    display: block;
}

.control--checkbox .control__indicator:after {
    top: 3px;
    left: 6px;
    width: 3px;
    height: 8px;
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 2px 2px 0;
}

.control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
}

.container-actions {
    margin-top: 25px;
    width: 300px;
    color: $color-tecno;
}

.container-actions__header{
    justify-content: space-between;
    display: flex;
}

.container-actions__priority {
    width: 150px;
}

.container-actions__element {
    width: 45px;
    display: flex;
    justify-content: center;
}

.reminder__container {
  box-sizing: border-box;
  height: 91%;
  padding-top: 55px;
  position: absolute;
  right: 0;
  transition: right .3s ease;
}

.reminder__hide {
  right: -520px;
}
