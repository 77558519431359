@import '../../../../css/mixins/default_media_query';
@import '../../../../css/mixins/default_table';
@import '../../../../css/variables';

.paginate {
  @include paginate;
}

.table {
  @include wrapper;
  margin-top: 20px;
}

.header {
  @include header;
}

.header-column {
  @include header-column;
}

.title {
  @include sm {
    display: none;
  }
  @include md-sm {
    display: none;
    /*margin-top: 25px;*/
  }
  @include md {
    display: none;
    /*margin-top: 25px;*/
  }
  @include lg {
    display: none;
    /*margin-top: 91px;*/
  }
  color: #000;
  float: left;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
}

.btn {
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: 185px;
    margin-left: 1rem;
  }
  @include md {
    width: 185px;
    margin-left: 1rem;
  }
  @include lg {
    width: 185px;
    margin-left: 1rem;
  }
  @include max-screen {
    width: 185px;
    margin-left: 1rem;
  }
  background-color:$btn-bg-color;
  border: 0;
  border-radius:5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 2rem;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  &:hover {
    background-color: $btn-hover;
  }
}

.content {
  @include sm {
    margin-top: 5px;
    width: 100%;
  }
  @include md-sm {
    margin-top: 5px;
    width: 100%;
  }
  @include md {
    width: 100%;
  }
  @include lg {
    width: 100%;
  }
  float: left;
  position: relative;
}

.row {
  @include rowtb;
}

.removed {
  @include removed;
}

.column {
  @include column;
}

.name {
  @include field;
  @include sm {
    width: 175px;
  }
  @include md-sm {
    width: 250px;
  }
  @include md {
    width: 300px;
  }
  @include lg {
    width: 300px;
  }
  @include max-screen {
    width: 300px;
  }
}

.code {
  @include field;
  @include mobile-sm {
   display: none;
  }
  @include mobile-md {
    display: none;
   }
   @include mobile-lg {
    display: none;
   }
  @include sm {
    width: 75px;
  }
  @include md-sm {
    width: 150px;
    padding-left: 1em;
  }
  @include md {
    width: 200px;
  }
  @include lg {
    width: 200px;
  }
  @include max-screen {
    width: 200px;
  }
}

.priority {
  @include field;
  
  @include mobile-sm {
    display: none;
   }
   @include mobile-md {
     display: none;
    }
    @include mobile-lg {
     display: none;
    }
  @include sm {
    display: none;
  }
  @include md-sm {
    width: 200px;
  }
  @include md {
    width: 200px;
  }
  @include lg {
    width: 200px;
  }
  @include max-screen {
    width: 200px;
  }
}

.column-icons {
  @include sm {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 30px;
  }
  @include md {
    width: 75px;
  }
  @include md {
    width: 85px;
  }
  @include lg {
    width: 85px;
  }
  @include max-screen {
    width: 85px;
  }
}

.link {
  color: inherit;
  fill: inherit;
  float: left;
  position: relative;
}

.icons {
  @include icons;
}



