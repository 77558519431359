@import 'mixins/default_media_query';
@import 'variables';

.user-menu {
  @include mobile-sm {
    width: 55px;
  }
  @include mobile-md {
    width: 55px;
  }
  @include mobile-lg {
    width: 60px;
  }
  @include sm {
    width: 76px;
  }
  box-sizing: border-box;
  float: right;
  font-family: $globalfont;
  height: 55px;
  overflow: hidden;
  position: relative;
  transition: .3s ease;
  width: 250px;

  &:hover {
    height: 160px;
  }
}

.user-menu-list {
    list-style-type: none;
    float: left;
    width: 100%;
    padding: 0;
}

.user-menu-list-items:first-child {
    height: 55px;
    background-color: $color-tecno;
    border: 0;
    color: $color-white;
    padding: 0 5px;
}

.user-menu-list-items {
    float: left;
    position: relative;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    border: solid 1px $color-gray-soft;
    border-top: 0;
    transition: background .3s ease;
    color: #333;
    fill: $color-white;
    height: 35px;
    background-color: $color-gray-soft;
}

.user-menu-list-items:first-child:hover {
    color: $color-white;
    fill: $color-gray-soft;
    background-color: $color-blue;
}

.user-menu-list-items:hover {
    color: $color-blue;
    background-color: $color-gray-soft;
}

.user-menu-list-items__text {
    background: none;
    border: none;
    outline: none !important;
    text-decoration: none;
    color: inherit;
    float: left;
    width: 100%;
    padding: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-sizing: border-box;
}

.user-container {
    height: inherit;
    position: relative;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: $globalfont;
    width: 100%;
    box-sizing: border-box;
    display: block;
    text-decoration: none;
    color: $color-white;
    fill: $color-white;

}

.user-container:hover {
    color: $color-gray-soft;
    fill: $color-gray-soft;

}

.user-container__svg {
  @include mobile-sm {
    float: right;
  }
  @include mobile-md {
    float: right;
  }
  @include mobile-lg {
    float: right;
  }
  @include sm {
    float: right;
  }
    fill: inherit;
    float: left;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    height: $svg_size;
    width: $svg_size;
}

#user-container__icon {
    fill: inherit;
    float: left;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
  }


.header-user-name-text {
    width: 100%;
    float: left;
    color: inherit;
    text-decoration: none;
}

.user-container__down {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
    fill: inherit;
    float: left;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    height: $down_size;
    width: $down_size;
}

.user-container__text {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
    float: left;
    position: relative;
    width: calc(100% - #{$svg_icons});
    line-height: 3.45;
    text-align: center;
    font-size: 16px;
}
.user-border {
    float: left;
    position: relative;
    width: 230px;
    height: 48px;
    border: solid 1px;
    color: $color-gray-form;
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;
    margin-top: -20px;
    transition: all .3s ease;
}
