@import "mixins/default_media_query";
@import "mixins/utils";
@import 'variables';

.table {
  width: 100%;
  float: left;
  position: relative;
  font-family: $globalfont;
  @include sm {
    background: $color-tecno;
    color: $color-white;
  }
}

.table-border {
  float: left;
  position: relative;
  width: 100%;
  border: solid 1px;
  color: #808285;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.table-bar {
  border-bottom: 3px solid $sidebar-background-color;
  float: left;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  @include mobile-sm {
    display:none;
  }
  @include mobile-md {
    display:none;
  }
  @include mobile-lg {
    display:none;
  }
  @include sm {
    display:none;
  }
  @include md-sm {
    display:none;
  }
}

.table-border--userPage {
  margin-top: 4px;
}

.table-title--userPage-attend {
  margin-top: 20px;
}

.table-container {
  float: left;
  position: relative;
  width: 100%;
}

.table-container-content {
  @include sm {
    float: left;
    position: relative;
    width: 100%;
    height: auto;
  }
}

.table-container:last-child {
  @include sm-md {
    border: 0;
  }
}

.table-title {
  background: $color-tecno;
  padding: 7px;
  color: $color-white;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  float: left;
  position: relative;
  @include sm {
    font-size: 25px;
    border-bottom: solid 1px #00b1c8;
  }
  @include md-lg {
    font-size: 32px;
  }
}

.table-container-account {
  width: 100%;
}

.table-container-tr {
  float: left;
  position: relative;
  width: 100%;
  @include sm {
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    font-size: 14px;
  }
}

.table-container-line {
  @include sm {
    height: 44px;
  }
  @include md-lg {
    border: solid 1px #76bec7;
    box-sizing: border-box;
    border-bottom: 0;
    padding: 7px;
    height: 34px;
  }
}

.table-container-info-title {
  @include sm {
    width: 100%;
    float: left;
    position: relative;
    color: rgb(144, 221, 227);
    text-align: center;
  }
}

.table-container-info-content {
  @include sm {
    width: 100%;
    float: left;
    position: relative;
    height: auto;
    min-height: 20px;
  }
}

.table-extras-contacts {
  @include sm {
    height: 50px;
    background: $color-tecno;
    border: solid 1px #02aec3;
  }
}

.table-container-title {
  width: 100%;
  height: 34px;
  background: #4abfce;
  color: $color-white;
  text-align: center;
  font-size: 18px;
  line-height: 1.88;
}

.table-container-title--height-0 + div {
  height: 0px !important;
  overflow: hidden;
}

.table-container-header {
  height: auto;
  float: left;
  position: relative;
  z-index: 1;
  @include sm {
    width: $sm-header-width;
    color: rgb(144, 221, 227);
  }
  @include md-lg {
    width: $md-lg-header-width;
    background: #7bcfda;
    color: $color-white;
  }
}

.table-container-body {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  z-index: 0;
  @include sm {
    padding-left: $sm-header-width;
  }
  @include md-lg {
    padding-left: $md-lg-header-width;
    color: #666;
  }
}

.table-extras {
  width: 100%;
  float: left;
  position: relative;
  box-sizing: border-box;
  font-family: $globalfont;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  @include sm {
    height: auto;
    background: #02aec3;
    border: solid 1px #76bec7;
    border-top: 0;
  }
  @include md-lg {
    height: 26px;
    border-top: 0;
  }
}

.table-extras-transmissor {
  @include sm {
    height: 34px;
    background: $color-tecno;
    border: solid 1px #02aec3;
    overflow: hidden;
  }
}

.table-extras-a {
  color: $color-white;
  text-decoration: none;
  float: left;
  position: relative;
  text-align: center;
  padding: 7px 0;
  box-sizing: border-box;
  @include transition(background $transition-time ease);
  cursor: pointer;
  //display: block;
  background: $color-tecno;
  border-radius: 20px;
  justify-content: space-between;
  @include sm {
    height: 34px;
  }
  @include md-lg {
    height: inherit;
  }
}

.table-extras-a-contact {
  @include sm {
    width: 32%;
    height: auto;
    fill: #fff;
  }
}

.table-extras-a-contact:first-child {
  @include sm {
    border-right: solid 1px #02aec3;
  }
  @include md-lg {
    border-right: solid 1px #76bec7;
  }
}

.table-extras-a-contact-svg {
  @include sm {
    fill: inherit;
  }
}

.table-extras-a-account {
  @include sm {
    width: 100%;
  }
  @include md-lg {
    width: 32%;
  }
  border-radius: 20px;
  background: $color-tecno;
}

.table-extras-a:hover {
  @include sm {
    background: #16a5b7;
  }
  @include md-lg {
    background: #88c8d0;
  }
}

.table-extras-a-account:first-child {
  @include sm {
    border-top: solid 1px #00a3b9;
  }
}

.table-extras-a-account:nth-child(2) {
  @include sm {
    border-top: solid 1px #00a3b9;
    border-bottom: solid 1px #00a3b9;
  }
  @include md-lg {
    border-left: solid 1px #76bec7;
    border-right: solid 1px #76bec7;
  }
}

.table-container-line:last-child {
  @include md-lg {
    border-bottom: solid 1px #76bec7;
  }
}

.table-container-header-td {
  @include md-lg {
    border-right: 0;
  }
}

.table-extras-a-border {
  @include md-lg {
    border-right: solid 1px #76bec7;
  }
}

.table-extras-a-transmissor-border {
  @include md-lg {
    border-right: solid 1px #76bec7;
  }
}

.table-attend-nobody {
  color: red;
}

.table-attend-busy {
  color: orange;
}

.confirm-remove-modal {
  background: #fff;
  border: solid 1px #999;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 135px;
  padding: 20px;
  text-align: center;
  position: fixed;
  z-index: 999;
  -webkit-transform: translate(-50%, -50%);
  transform: translate x(-50%, -50%);
  left: 50%;
  top: 30%;
  overlay: initial;
  position: fixed;
  top: 30%;
  left: 50%;
  z-index: 10;
  outline: 9999px solid rgba(0, 0, 0, .5);
}

/* Custom table header */

.table-headers {
display: flex;
}

.custom-table-header {
display: flex;
justify-content: flex-start;
align-items: top;
font-size:0.7rem;
}

.custom-table-header p {
margin-bottom: 0px;
font-weight: 700;
color: #333333;
font-size:0.7rem;
text-transform: uppercase;
}

.custom-table-wrapper {
margin-top: 35px;
}

.custom-table-row {
display: flex;
background: #FFF;
box-shadow: 5px 5px 14px 0 rgba(189, 189, 205, 0.1);
margin-bottom: 20px;
padding: 5px;
}

.custom-table-body {
margin-top: 15px;
}

.custom-table-cell {
padding: 0px;
display: flex;
justify-content: flex-start;
align-items: top;
font-size: 1rem;
}

.center-wrapper {
width: 100%;
}

.username-cell .center-wrapper {
display: flex;
}

.center-wrapper p {
margin-bottom: 0px;
}


.profile_name {
display: inline-block;
vertical-align: middle;
align-self: center;
}


.mobile-column-name {
display: none;
}

.flex-basis-10 {
flex-basis: 10%;
}

.flex-basis-20 {
flex-basis: 20%;
}

.flex-basis-30 {
flex-basis: 30%;
}

.flex-basis-50 {
flex-basis: 50%;
}


/* -------------------------------------------------------Responsive -------------------------------------------------------*/

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1199.98px) {
.tab-header {
    display: inherit;
}

}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {

.table-headers {
    display: none;
}

.custom-table-row {
    flex-direction: column;
}

.mobile-column-name {
    display: inline-block;
    min-width: 120px;
    font-weight: 600;
}
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {}
