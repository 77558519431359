@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.wrapper {
 @include sm {
    box-sizing: border-box;
    height: initial;
    left: 50px;
    padding: 10px;
    width: calc(100% - 50px);
  }
 @include md {
    left: 50%;
    transform: translateX(-50%);
    width: 355px;
  }
   @include lg {
    left: 50%;
    transform: translateX(-50%);
    width: 355px;
  }
    background: #fff;
    border: solid 1px #999;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-block;
    -webkit-box-orient: vertical; 
    -webkit-box-direction: normal; 
    -ms-flex-direction: column;
    flex-direction: column; 
    min-height: 135px;
    padding: 20px;
    text-align: center;
    position: fixed;
    z-index: 999;
    -webkit-transform: translate(-50%, -50%);
    transform: translate x(-50%, -50%);
    left: 50%;
    top: 30%;
    position: fixed;
    top: 30%;
    left: 50%;
    z-index: 999;
    outline: 9999px solid rgba(0,0,0,0.5);
}

.title {
  margin-top: 0;
  margin-bottom: 15px;
  color: $color-grafite;
  font-family: $globalfont;
  font-size: 1.2rem;
  text-align: center;
  white-space: pre-line;
  word-wrap: break-word;
}

.container {
 @include sm {
    float: left;
    margin-top: 10px;
    position: relative;
    width: 100%;
  }
 @include md {
    bottom: 20px;
    width: 289px;
  }
@include lg {
    bottom: 20px;
    width: 289px;
  }
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.btn {
  border-radius: 5px;
  height: 26px;
  width: 120px;
}

