@import "default_media_query";
@import "../variables";


@mixin general-title {
  color:$color-grafite;
  float: left;
  font-size: 1.1rem;
  // height: 48px;
  line-height: 1.5rem;
  margin-top: 0.3rem;
  position: relative;
  width: auto;
  // margin-top: 0.5rem;
  @include mobile-sm {
    line-height: 1.2rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  @include mobile-md {
    line-height: 1.2rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  @include mobile-lg {
    line-height: 1.2rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
 }

@mixin subtitle {
  color: $color-gray;
  float: left;
  font-size: 1.1rem;
  height: auto;
  position: relative;
  text-transform: uppercase;
}


@mixin name {
  @include mobile-sm {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
  }
  @include mobile-md {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
  }

  @include mobile-lg {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
  }

  @include sm {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 135px;
  }
  @include md-sm {
    width: 150px;
  }
  @include md {
    width: 150px;
  }
  @include lg {
    width: 550px;
  }
  @include max-screen {
    width: 550px;
  }
}

.adminActions {
  @include mobile-sm {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include mobile-md {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include mobile-lg {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include sm {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include md-sm {
    width: 200px;
  }
  @include md {
    width: 200px;
  }
  @include lg {
    width: 200px;
  }
  @include max-screen {
    width: 200px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.otherActions {
  @include mobile-sm {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include mobile-md {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include mobile-lg {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include sm {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include md-sm {
    width: 160px;
  }
  @include md {
    width: 160px;
  }
  @include lg {
    width: 160px;
  }
  @include max-screen {
    width: 160px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.tracker {
  color: inherit;
  cursor: pointer;
  fill: inherit;
  float: left;
  position: relative;
}

.trackerActions {
  @include mobile-sm {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include mobile-md {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include mobile-lg {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include sm {
    overflow-x: auto;
    overflow-y: hidden;
    width: 30px;
  }
  @include md-sm {
    width: 65px;
  }
  @include md {
    width: 65px;
  }
  @include lg {
    width: 65px;
  }
  @include max-screen {
    width: 65px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.rotate {
  transform: rotate(180deg);
}

.tooltip {
  display: inline;
  float: left;
  position: relative;

  &:hover {
    &::after {
      background: $tooltip-bg-color;
      border-radius: $border-radius;
      bottom: 47px;
      color: $tooltip-font-color;
      content: attr(title);
      left: 0;
      opacity: 0.8;
      padding: 5px 15px;
      position: absolute;
      width: 80px;
      z-index: 98;
    }

    &::before {
      border: solid;
      border-color: var(--tooltip-border-color) transparent;
      border-width: 6px 6px 0;
      bottom: 41px;
      content: "";
      left: 16px;
      position: absolute;
      z-index: 99;
    }
  }
}

.company {
  @include mobile-sm {
    width: 100px;
  }
  @include mobile-md {
    width: 100px;
  }
  @include mobile-lg {
    width: 100px;
  }
  @include sm {
    width: 135px;
  }
  @include md-sm {
    width: 150px;
  }
  @include md {
    width: 180px;
  }
  @include lg {
    width: 250px;
  }
  @include max-screen {
    width: 250px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.companyHeader {
  @extend .company;
  @include mobile-sm {
    margin-right: 30px;
    width: 100px;
  }
  @include mobile-md {
    margin-right: 30px;
    width: 100px;
  }
  @include mobile-lg {
    margin-right: 30px;
    width: 100px;
  }
}

.name {
  @include mobile-sm {
    width: 150px;
  }
  @include mobile-md {
    width: 150px;
  }
  @include mobile-lg {
    width: 150px;
  }
  @include sm {
    width: 150px;
  }
  @include md-sm {
    width: 300px;
  }
  @include md {
    width: 150px;
  }
  @include lg {
    width: 350px;
  }
  @include max-screen {
    width: 450px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitleContainer {
  @include mobile-sm {
    width: 57%;
  }
  @include mobile-md {
    width: 60%;
  }
  @include mobile-lg {
    width: 65%;
  }
  @include sm {
    width: 75%;
  }
  @include md-sm {
    width: 80%;
  }
  @include md {
    width: 80%;
  }
  @include lg {
    width: 85%;
  }
  @include max-screen {
    width: 90%;
  }
  float: right;
  height: auto;
  margin-left: 10px;
  margin-top: 22px;
  position: relative;
}
