@import '../../../../css/mixins/default_media_query';
@import '../../../../css/mixins/default_buttons';
@import '../../../../css/variables';

.mapErrorComp {
  width: 100%;
  float: left;
}

.mapErrorMsg {
  width: 100%;
  float: left;
  font-size: 1rem;
  margin: 10px;
}

.mapErrorBtn {
  @include btn;
  float: left;
  font-weight: bold;
  border: 0;
  // height: 37px;
  margin-bottom: 10px;
  position: relative;
  width: auto;
  &:hover {
    background-color: $btn-hover;
    color: $color-white;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
