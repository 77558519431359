@import '../../../css/mixins/default_media_query';

.dashboard-wrapper {
  float: left;
  height: auto;
  position: relative;
  width: 100%;
}

.attendChart {
   @include lg {
    margin-left: 10px;
  }
  @include max-screen {
    margin-left: 10px;
  }
  width: 100%;
  float: left;
  margin-top: 15px;
  position: static;
}

.clientChart {
  @include lg {
   margin-left: 10px;
 }
 @include max-screen {
   margin-left: 10px;
 }
 width: 100%;
 float: left;
 margin-top: 15px;
 position: static;
}


.cards {
  @include mobile-sm {
    display: block;
    width: 100%;
  }
  @include mobile-md {
    display: block;
    width: 100%;
  }
  @include mobile-lg {
    display: block;
    width: 100%;
  }
  @include sm {
    width: 100%;
  }
  @include md {
    width: 100%;
  }
  @include lg {
    display: grid;
    float: left;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    margin-top: 15px;
    position: relative;
    width: 100%;
  }
  @include max-screen {
    display: grid;
    float: left;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    margin-top: 15px;
    position: relative;
    width: 100%;
  }
  position: relative;
  width: 100%;
  background: transparent;
  background-color: transparent;
}
