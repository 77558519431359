@import '../../css/mixins/default_media_query';
@import '../../css/variables';

:root {
  --sidebar-width: 50;
  --padding: 10;
}

.wrapper {
    @include sm {
      width: 80% !important;
    }
    @include md-sm {
      width: 368px;
    }
    
    @include md {
      width: 368px;
    }
    @include lg {
      width: 368px;
    }
    bottom: 0;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    position: relative;
    right: 0;
  
  }
  
  .btn {
    @include sm  {
      width: 47% !important;
    }
    @include md-sm {
      width: 175px;
    }
    @include md {
      width: 175px;
    }
    @include lg {
      width: 175px;
    }
    border: 0;
    border-radius: 5px;
    color: $color-white;
    float: left;
    font-family: $globalfont;
    font-size: 0.9rem;
    height: 2rem;
    //padding: 0;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    transition: background .3s ease;
    text-decoration: none;
}

.active {
  // background: $color-tecno;
  cursor: pointer;

  &:hover {
    background: $btn-hover;
  }
}

.disable {
  background: #ededed;
  cursor: default;
  pointer-events: none;
}
