@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.reminder-wrapper {
  float: left;
  width: 100%;
  margin-bottom: 40px;
  font-size: 1rem;
  }

.reminder-minutes {
  padding-right: 5px;
  @include mobile-sm {
    width: auto;
  }
  @include mobile-md {
    width: auto;
  }
  @include mobile-lg {
    width: auto;
  }
  @include sm {
    width: auto;
  }
  @include md-sm {
    width: auto;
  }
}

.reminder-btn-download {
  border: 0;
  border-radius: 5px;
  background: $btn-bg-color;
  font-weight: bold;
  color: $color-white;
  float: right;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  width: 35px;
  &:hover {
    background-color: $btn-hover;
  }
}

.noReminder {
  margin: 10px;
  float: left;
  width: 100%;
}
