@import '../../../css/mixins/default_table';
@import '../../../css/mixins/default_media_query';
@import '../../../css/mixins/default_title';

.title {
  @include general-title;
  @include sm {
    line-height: 1;
    margin-top: 15px;
  }
   @include md-sm {
    line-height: 1;
    margin-top: 5px;
  }
  @include md {
    line-height: 1;
  }
  @include lg {
    line-height: 1;
  }
}

.input-wrapper {
  @include sm {
    width: 100%;
  }
  @include md-sm {
    width: 50%;
  }
  @include md {
    width: 50%;
  }
  @include lg {
    width: 50%;
  }
  float: left;
}

.checkbox {
  width:2rem;
  height:2rem;
  @include mobile-sm {
    padding-top:0.5rem;
  } 
  @include mobile-md {
    padding-top:0.5rem;
  } 
  @include mobile-lg {
    padding-top:0.5rem;
  } 
  @include sm {
    padding-top:0.5rem;
  } 
  @include md-sm {
    padding-top:0.5rem;
  }
}

.savetip {
  cursor: none;
}

.savetip-container {
  background-color: $btn-bg-color;
  border-radius: 6px;
  color: $color-white;
  float: left;
  font-family: "Montserrat", sans-serif;
  padding: 10px;
  /* display: inline-block; */
  position: absolute;
  bottom: 100%;
  // left: 2rem;
  transform: translateY(-40%);
  text-align: center;
  visibility: hidden;
  width: 450px;
  z-index: 1002;
  left: 0;
}

.savetip-text {
  float: left;
  width: 100%;
  text-align: left;
  text-transform: none;
}

.savetip:hover .savetip-container {
  visibility: visible;
}
.form-text-error {
  @include sm {
    display: none;
  }
  color: $color-tomato;
  display: block;
  float: left;
  font-size: 10px;
  margin-top: 4px;
  position: absolute;
  right: 0;
  text-align: right;
  width: 100%;
}

.action-error {
  color: $color-tomato;
  font-size: 10px;
  position: absolute;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.exclude-button {
  @include sm  {
    width: 47% !important;
  }
  @include md-sm {
    width: 175px;
  }
  @include md {
    width: 175px;
  }
  @include lg {
    width: 175px;
  }
  border: 0;
  border-radius: 5px;
  color: $color-white;
  float: left;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;

  &--color-gray {
    background: $color-gray;
    font-weight: bold;

    &:hover {
      background: $color-gray-hover;
    }
  }
}
