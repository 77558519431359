@import 'mixins/default_media_query';
@import 'variables';

.rowctt {
  border: solid 1px $color-gray-table;
  border-top: 0;
  box-sizing: border-box;
  color: $color-black-light;
  display: flex;
  fill: $color-gray-icons;
  float: left;
  font-weight: 300;
  // height: 37px;
  justify-content: space-between;
  padding: 4px 10px;
  position: relative;
  width: 100%;

  &--deleted {
    border: solid 1px $color-deleted-row;
    border-bottom: 0;
    color: $color-deleted-row;
    fill: $color-deleted-row;

    &:last-child {
      border-bottom: solid 1px;
    }
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.column {
  color: inherit;
  fill: inherit;
  float: left;
  font-size: 1rem;
  line-height: 2.5;
  position: relative;
  transition: .5s ease;

  &--1icon {
    text-align: center;
    width: 35px;
  }

  &--2icons {
    text-align: center;
    width: 63px;
  }

  &--3icons {
    width: 85px;
  }

  &--5icons {
    width: 160px;
  }

  &--6icons {
    width: 190px;
  }
}

.actions-container {
  color: inherit;;
  fill: inherit;
  float: left;
  position: relative;
}

.action-icons {
  cursor: pointer;
  fill: inherit;
  transition: fill .3s ease;

  &:hover {
    fill: $color-tecno;
  }
}
