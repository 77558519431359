@mixin transition($args...) {
    -moz-transition: $args;
      -ms-transition: $args;
    -o-transition: $args;
    -webkit-transition: $args;
    transition: $args;
  }
  
  @mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
      @content;
    }
    @-moz-keyframes #{$animation-name} {
      @content;
    }
    @-ms-keyframes #{$animation-name} {
      @content;
    }
    @-o-keyframes #{$animation-name} {
      @content;
    }
    @keyframes #{$animation-name} {
      @content;
    }
  }
  
  @mixin animation($str) {
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    -webkit-animation: #{$str};
    animation: #{$str};
  }
  
  @mixin transform($str) {
    -moz-transform: #{$str};
    -ms-transform: #{$str};
    -webkit-transform: #{$str};
    transform: #{$str};
  }
  