@import '../../../css/mixins/default_media_query';
@import '../../../css/mixins/default_buttons';
@import '../../../css/variables';

.wrapper{
    float: left;
    font-family: $globalfont;
    left: 50%;
    overflow-y: auto;
    padding: 0 15px;
    position: relative;
    transform: translateX(-50%);
    height: 95%;
    margin-top: 25px;
    width: 30%;
}

.container{
    float: left;
    position: relative;
    width: 100%;
}

.title{
    color: $color-tecno;
    font-size: 22px;
    height: 28px;
    margin-bottom: 40px;
    text-align: center;
    width: 100%;
}

.containerTitle{
    border-bottom: solid 1px;
    color: #638084;
    font-size: 14px;
    font-weight: bold;
    height: 23px;
    text-align: left;
}

.content{
    float: left;
    padding: 30px 0;
    position: relative;
    width: 100%;
}

.row50{
    float: left;
    font-size: 1rem;
    height: 34px;
    margin: 5px 0;
    position: relative;
    width: 50%;
}

.row100{
    float: left;
    font-size: 1rem;
    height: 34px;
    margin: 5px 0;
    position: relative;
    width: 100%;
}

.field{
    box-sizing: border-box;
    color: $color-gray;
    float: left;
    font-size: 1rem;
    height: 26px;
    line-height: 2;
    padding: 0 10px;
    position: relative;
    text-align: right;
    width: 100px;
}

.required{
    color: $color-tecno;
    // position: absolute;
    right: 3px;
}

.value{
    float: left;
    font-size: 1rem;
    height: 26px;
    line-height: 2;
    position: relative;
    width: calc(100% - 148px);
}

.input{
    border: solid 1px #ccc;
    box-sizing: border-box;
    color: #808285;
    float: left;
    font-family: $globalfont;
    height: 26px;
    outline: 0;
    padding: 0 5px;
    position: relative;
    transition: .3s ease;
    width: 100%;
}

.select{
    -webkit-appearance: none;
    border: solid 1px #ccc;
    border-radius: 0;
    clear: left;
    float: left;
    height: 26px;
    outline: 0;
    padding: 0 8px;
    width: 100%;

    &--enable{
        background: transparent url('../../../img/select-icon.png') no-repeat center right 8px;
        background-size: 12px;
        color: #808285;
        cursor: pointer;
        transition: .3s ease;
    }
}

.btn{
    @include btn;
    height: 33px;
    width: 175px;
    border: 0;
    transition: background .3s ease;
    &:hover {
        background: $color-tecno-hover;
      }
      &:focus {
        outline: none;
      }
}

.btnDisabled {
  background-color: $color-gray-soft;
}

.hasError{
    border-color: $color-red !important;
}
