@import '../../../css/mixins/default_media_query';

.input-wrapper {
  @include sm {
    width: 100%;
  }
  @include md {
    width: 50%;
  }
  @include lg {
    width: 50%;
  }
}

.checkbox {
  width:2rem;
  height:2rem;
  margin-left: 0.5rem;
  @include mobile-sm {
    padding-top:0.5rem;
  } 
  @include mobile-md {
    padding-top:0.5rem;
  } 
  @include mobile-lg {
    padding-top:0.5rem;
  } 
  @include sm {
    padding-top:0.5rem;
  } 
  @include md-sm {
    padding-top:0.5rem;
  }
}
