@import '../variables';


@mixin btn {
  height: 2rem;
  border: 0;
  border-radius: 5px;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  padding: 0.5rem;
  position: relative;
  text-align: center;
  transition: background 0.3s ease;
  background: $btn-bg-color;
  text-transform: uppercase;
  width: auto;
  text-decoration: none;
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  &:hover {
    background-color: $btn-hover;
    color: $color-white;
  }
  &--add {
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
    clear: left;
    // height: 37px;
    width: 173px;
  }

  &--add-contact-phone {
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
    @include sm {
      width: 100%;
    }
    height: 26px;
    // margin-top: 10px;
    width: 380px;
  }

  &--add-transmissor {
    float: left;
    font-family: $globalfont;
    font-size: 0.8rem;
    height: 100%;
    position: absolute;
    width: 100%;
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
    clear: left;
    // height: 37px;
    width: 240px;
  }

  &--attend-emergency {
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
  }

  &--bold {
    font-weight: bold;
  }

  &--bottom {
    bottom: 42px;
  }

  &--color-gray {
    background: $color-gray;
    color: $color-gray-font;
  }

  &--color-green {
    background: $color-green;
    font-weight: bold;

    &:hover {
      background: $color-green-hover;
    }
  }

  &--color-invalid {
    background: $color-gray-invalid;
    cursor: default;
    pointer-events: none;
  }

  &--color-gray {
    background: $color-gray-invalid;
    cursor: default !important;
  }

  &--color-orange {
    background: $color-orange;

    &:hover {
      background: $color-orange-hover;
    }
  }

  &--color-red {
    background: $color-red;
    font-weight: bold;

    &:hover {
      background: $color-red-hover;
    }
  }

  &--btn-bg-color {
    background-color: $btn-bg-color;
    font-weight: bold;

    &:hover {
      background-color: $btn-hover;
    }
  }

  &--confirm-remove-modal {
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
    font-size: 0.8rem;
    height: 35px;
    width: 130px;
  }

  &--download {
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
    float: right;
    height: 37px;
    top: 15px;
    width: 125px;
  }

  &--download-text {
    font-weight: bold;
    margin-right: 5px;
    position: relative;
    top: 30%;
  }

  &--get-attend-or-attend-voice-call {
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
    height: 37px;
    width: 197px;
  }

  &--modal-attend {
    bottom: 20px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  &--right {
    float: right;
    line-height:initial;
  }

  &--multi {
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
    clear: left;
    height: 37px;
    width: 185px;
  }

  &--form {
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
    @include sm {
      width: 45%;
    }
    height: 26px;
    width: 175px;
  }
}

@mixin btn-add {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 2rem;
  // line-height: 1.5rem;
  padding: 0.3rem;
  position: relative;
  text-align: center;
  transition: background 0.3s ease;
  &:hover {
    background-color: $btn-hover;
    color: $color-white;
  }
  @include mobile-sm {
    width: 100%;
    margin-bottom: 2%;
    margin-left: 0px !important;
  }
  @include mobile-md {
    width: 100%;
    margin-bottom: 2%;
    margin-left: 0px !important;
  }
  @include mobile-lg {
    width: 100%;
    margin-bottom: 2%;
    margin-left: 0px !important;
  }
  @include sm {
    width: 30%;
    margin-right: 5px;
  }
  @include md-sm {
    width: 173px;
  }
  @include md {
    width: 173px;
  }
  @include lg {
    width: 173px;
  }
  @include max-screen {
    width: 173px;
  }
}

@mixin button {
  height: 2rem;
  border: 0;
  border-radius: 5px;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  padding: 0.5rem;
  position: relative;
  text-align: center;
  transition: background 0.3s ease;
  background: $btn-bg-color;
  text-transform: uppercase;
  text-decoration: none;
  width: auto;
}

//button container
@mixin btn-content {
  float: left;
  height: 2.5rem;
  @include mobile-sm {
    height: auto;
    width: 100%;
  }
  @include mobile-md {
    height: auto;
    width: 100%;
  }
  @include mobile-lg {
    height: auto;
    width: 100%;
  }
  @include sm {
    height: auto;
    width: 100%;
    // margin-bottom: 5px;
    // width: 369px;
  }
  @include md-sm {
    width: 35rem;
    float: right;
  }
  @include md {
    width: 35rem;
    margin-left: 1rem;
  }
  @include lg {
    width: 35rem;
    margin-left: 1rem;
  }
  @include max-screen {
    width: 35rem;
    margin-left: 1rem;
  }
}

@mixin toggleButtonWrapper {
  text-align: center;
  width: 30px;
}

@mixin toggleButton {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1.5rem;
  font-weight: bolder;
  height: 30px;
  width: 30px;
  line-height: 20px;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background 0.3s ease;
  &:hover {
    background-color: $btn-bg-color;
    color: $color-white;
  }
}



@mixin btn-mobile {
  @include mobile-sm {
    width: 100%;
    overflow: hidden;
    padding-left: 2px;
    text-overflow: ellipsis;
  }

  @include mobile-md {
    width: 100%;
    overflow: hidden;
    padding-left: 2px;
    text-overflow: ellipsis;
  }
  @include mobile-lg {
    width: 100%;
    overflow: hidden;
    padding-left: 2px;
    text-overflow: ellipsis;
  }
  @include sm {
    width: 100%;
    overflow: hidden;
    padding-left: 2px;
    text-overflow: ellipsis;
  }
}

@mixin btn-call {
  border: 0;
  border-radius: 5px;
  bottom: 25px;
  color: $color-white;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  padding-left: 15px;
  position: relative;
  transition: background 0.3s ease;
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }

  &--cursor {
    cursor: pointer;
  }

  &--right {
    @include mobile-sm {
      float: left;
      margin-top: 70px;
    }
    @include mobile-md {
      float: left;
      margin-top: 70px;
    }
    @include mobile-lg {
      float: left;
      margin-top: 70px;
    }
    @include sm {
      float: left;
      margin-top: 70px;
    }
    @include md {
      float: left;
    }
    @include md-sm {
      float: left;
    }
    @include lg {
      float: right;
      margin-right: 325px;
    }
    @include max-screen {
      float: right;
      margin-right: 325px;
    }
  }

  &--text {
    float: left;
    padding-top: 10px;
    position: relative;
  }
}

@mixin btn-audio-control {
  display: inline-flex;
  float: left;
  @include mobile-sm {
    clear: left;
    margin-bottom: 5px;
    margin-top: -15px;
  }
  @include mobile-md {
    clear: left;
    margin-bottom: 5px;
    margin-top: -15px;
  }
  @include mobile-lg {
    clear: left;
    margin-bottom: 5px;
    margin-top: -15px;
  }
  @include sm {
    clear: left;
    margin-bottom: 5px;
    margin-top: -15px;
  }
  @include md-sm {
    clear: left;
    margin-bottom: 5px;
    margin-top: -20px;
  }
  @include md {
    clear: left;
    margin-bottom: 5px;
    margin-top: -20px;
  }
  @include lg {
    margin-top: -40px;
  }
  @include max-screen {
    margin-top: -40px;
  }
  
}

@mixin btn-audio-single {
  border-radius: 100%;
  height: 50px;
  margin-right: 15px;
  overflow: hidden;
  width: 50px;
  @include mobile-sm {
    margin-right: 5px;
  }
  @include mobile-md {
    margin-right: 5px;
  }
  @include mobile-lg {
    margin-right: 5px;
  }
  @include sm {
    margin-right: 5px;
  }
}

@mixin btn-delete {
  background: transparent;
  border: 0;

  &--alarm {
    margin-left: -10px;
  }
}

@mixin btn-search {
  float: right;
  height: 35px;
  line-height: 2.8;
  width: 40px;
}

@mixin btn-start {
  fill: $color-white;
  padding-right: 10px;
  stroke: $color-white;
}

@mixin btn-listen-color {
  background: $color-audio-blue;
}

@mixin btn-speak-color {
  background: $color-audio-yellow;
}

@mixin btn-two-way-audio-color {
  background: $color-audio-red;
}

@mixin btn-report {
  border-radius: 10px;
  height: 40px;
  position: relative;
  width: 45px;
}

@mixin tooltip {
  display: inline;
  float: left;
  position: relative;

  &:hover {
    &::after {
      background: $color-black;
      border-radius: 5px;
      bottom: 47px;
      color: $color-white;
      content: attr(title);
      left: 0;
      opacity: 0.8;
      padding: 5px 15px;
      position: absolute;
      width: 80px;
      z-index: 98;
    }

    &::before {
      border: solid;
      border-color: $color-black-light transparent;
      border-width: 6px 6px 0;
      bottom: 41px;
      content: "";
      left: 16px;
      position: absolute;
      z-index: 99;
    }
  }
}

@mixin transparent {
  background-color: transparent;
  background: transparent;
}
