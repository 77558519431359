.login-container-up {
	animation: 1s linear 1s 1 forwards moveup;
	
}

.login-container-down {
	animation: 1s linear 1s 1 forwards movedown;
	
}

.fadeout {
    animation: 0.1s linear 2s 1 dispOff forwards, 2s linear 1 forwards transparent;
}

.fadein {
	animation: 0.5s linear 1 forwards bgcolor;
}

@keyframes dispOff {
	0% {
		left:0;
	}
	100%{
		left:-9999px;
	}
}

@keyframes transparent {
	0% {
		opacity:1;
	}
	100%{
		opacity:0;
	}
}

@keyframes bgcolor {
	0% {
		opacity:0;
	}
	100%{
		opacity:1;
	}
}

@keyframes moveup {
	0% {
		margin-top: 0;
	}
	100% {
		margin-top: -500px;
	}
}

@keyframes movedown {
	0% {
		margin-top: -500px;
	}
	100% {
		margin-top: 0px;
	}
}

