@import 'mixins/default_media_query';
@import 'variables';


.wrapper {
  @include sm {
    width: 100%;
  }
  @include md {
    width: 100%;
  }
  @include lg {
    width: 100%;
  }
  float: left;
  font-family: $globalfont;
  height: inherit;
  left: 50%;
  overflow-y: auto;
  padding: 0 15px;
  position: relative;
  transform: translateX(-50%);
}

.title {
  color: $color-grafite;
  float: left;
  font-size: 1.1rem;
  line-height: 1.1rem;
  margin-bottom: 10px;
  position: relative;
  width: auto;

  &--client-name {
    color: $sidebar-icon-color;
  }
}

.container {
  float: left;
  position: relative;
  width: 100%;
}

.required {
  color: $color-tecno;
  // position: absolute;
  right: 3px;
}

.input {
  border: solid 1px $input-border;
  box-sizing: border-box;
  color: $color-gray;
  float: left;
  font-family:$globalfont;
  height: $heightform;
  outline: 0;
  padding: 0 5px;
  position: relative;
  transition: 0.3s ease;
  width: 100%;

  &:hover {
    border-color: $color-tecno;
  }

  &--disable {
    color: $color-gray-disable;
  }

  &--error {
    border: solid 1px $color-tomato;

    &:hover {
      border: solid 1px $color-tomato;
    }
  }

  &--ok {
    border: solid 1px $sidebar-background-color-hover;
  }
}

.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  // appearance: none;
  border: solid 1px $input-border;
  border-radius: 0;
  clear: left;
  float: left;
  height: $height;
  outline: 0;
  padding: 0 8px;
  width: 100%;

  &--enable {
    background: transparent url("../img/select-icon.png") no-repeat center right
      8px;
    background-size: 12px;
    color: $color-gray;
    cursor: pointer;
    transition: 0.3s ease;

    &:focus {
      border-color: $color-tecno;
    }

    &:hover {
      border-color: $color-tecno;
    }
  }
}

// .checkbox {
//   margin: 0 15px;
// }

.checkbox {
  width:2rem;
  height:2rem;
  margin-left: 0.5rem;
  @include mobile-sm {
    padding-top:0.5rem;
  } 
  @include mobile-md {
    padding-top:0.5rem;
  } 
  @include mobile-lg {
    padding-top:0.5rem;
  } 
  @include sm {
    padding-top:0.5rem;
  } 
  @include md-sm {
    padding-top:0.5rem;
  }
}

.message {
  color: $color-tomato;
  font-size: 10px;
  position: absolute;
  white-space: nowrap;
  width: 100%;

  &--100 {
    bottom: -18px;
    text-align: left;
  }

  &--50 {
    bottom: -6px;
    text-align: right;
  }
}

.disabled {
  cursor: not-allowed;
  background-color: $bg-color;
}
