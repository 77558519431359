@import "../../../css/mixins/default_media_query.scss";
@import "../../../css/_variables.scss";

.client-name {
  float: left;
  font-size: 19px;
  position: relative;
  width: 100%;
}

.textArea {
  @include mobile-sm {
    height: 150px;
    width: 98%;
  }
  @include mobile-md {
    height: 150px;
    width: 98%;
  }
  @include mobile-lg {
    height: 150px;
    width: 98%;
  }
  @include sm {
    height: 200px;
    width: 99%;
  }
  @include md-sm {
    height: 334px;
    width: 97%;
  }
  @include md {
    height: 334px;
    width: 97%;
  }
  @include lg {
    width: 98.5%;
    height: 330px;
  }
  @include max-screen {
    width: 99%;
    height: 330px;
  }
  height: 314px;
  border: 1px solid #ccc
}

.commentWrapper {
  @include mobile-sm {
    float: left;
    width: 100%;
    margin-top: 10px;
  }
  @include mobile-md {
    float: left;
    width: 100%;
    margin-top: 10px;
  }
  @include mobile-lg {
    float: left;
    width: 100%;
    margin-top: 10px;
  }
  @include sm {
    float: left;
    width: 99%;
    margin-top: 10px;
  }
  @include md-sm {
    float: left;
    width: 99%;
    min-height: 407px;
  }
  @include md {
    float: left;
    width: 99%;
    min-height: 407px;
  }
  @include lg {
    float: left;
    margin-left: 10px;
    min-height: 407px;
    width: 99%;
    height: 420px;
  }
  @include max-screen {
    float: left;
    width: 29%;
    margin-left: 10px;
    margin-top: 10px;
    // padding: 35px 0;
    min-height: 407px;
    height: 420px;
  }

  float: left;
}

.btnSave {
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  width: 99.5%;
  border: 0;
  border-radius: 5px;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  padding: 0;
  position: relative;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  height: 37px;
  transition: background 0.3s ease;
  background: $btn-bg-color;
  font-weight: bold;
  &:hover {
    background-color: $btn-hover;
    color: $color-white;
  }
}

.btnDisabled {
  background-color: $color-gray-soft;
  cursor: not-allowed;

  .saveTooltip {
    display: none;
    cursor: default;
    position: absolute;
    background-color: $btn-bg-color;
    padding: 10px;
    border-radius: 6px;
    color: $color-white;
    bottom: 100%;
    transform: translateX(-50%);
    left: 50%;
    white-space: nowrap;
    font-size: 0.9rem;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: $btn-bg-color transparent transparent transparent;
    }
  }

  &:hover {
    background-color: $color-gray-soft;
  }

  &:hover .saveTooltip {
    display: block;
    opacity: 1;
  }

}

.btnSaveNoTimer {
  @include mobile-sm {
    bottom: 25px;
  }
  @include mobile-md {
    bottom: 25px;
  }
  @include mobile-lg {
    bottom: 25px;
  }
}

.btnCall {
  border: 0;
  border-radius: 5px;
  bottom: 25px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  // height: 37px;
  padding-left: 15px;
  position: relative;
  transition: background 0.3s ease;
  width: 185px;
  @include sm {
    float: left;
  }
  @include md-sm {
    float: left;
  }
  @include md {
    float: left;
  }
  @include lg {
    float: right;
    margin-right: 34%;
  }
  @include max-screen {
    float: right;
    margin-right: 30%;
  }
}

.timerDetail {
  @include sm {
    margin-top: 1%;
    position: absolute;
    right: 7%;
    top: 10px;
    width: 275px;
  }
  @include md-sm {
    margin-top: 1%;
    position: absolute;
    right: 7%;
    top: 10px;
    width: 275px;
  }
  @include md {
    margin-top: 1%;
    position: absolute;
    right: 7%;
    top: 10px;
    width: 275px;
  }
  @include lg {
    position: absolute;
    right: 7%;
    top: 10px;
    width: 275px;
  }
  @include max-screen {
    position: absolute;
    right: 7%;
    top: 10px;
    width: 275px;
  }
  font-family: $globalfont;
  z-index: 999;
}

.blockContainer {
  @include mobile-sm {
    padding-top: 10px;
  }
  @include mobile-md {
    padding-top: 10px;
  }
  @include mobile-lg {
    padding-top: 10px;
  }
  @include max-screen {
    padding-top: 10px;
    width: 70%;
  }
}

.btnMapContainer {
  float: left;
  position: absolute;
  top: 28px;
  @include mobile-sm {
    top: 80px;
    visibility: hidden;
  }
  @include mobile-md {
    top: 80px;
    visibility: hidden;
  }
  @include mobile-lg {
    visibility: hidden;
  }
}

.btnMap {
  @include sm {
    margin-right: 5px;
  }
  background: #21b31a;
  border: 0;
  border-radius: 100%;
  color: $color-white;
  cursor: pointer;
  height: 50px;
  margin-right: 15px;
  overflow: hidden;
  width: 50px;
  &:hover {
    background: #0f5d0b;
  }
}

.btnHasMap {
  margin-left: 75px;
}

.btnClock {
  @include sm {
    margin-right: 5px;
  }
  background: #0046b5;
  border: 0;
  border-radius: 100%;
  color: $color-white;
  cursor: pointer;
  height: 50px;
  margin-bottom: 15px;
  margin-right: 15px;
  overflow: hidden;
  width: 50px;
  &:hover {
    background: #00235a;
  }
}

.btnIcon {
  font-size: 30px;
}

.middleInfoContainer {
  float: left;
  width: 100%;
  @include mobile-sm {
    margin-top: 70px;
  }
  @include mobile-md {
    margin-top: 70px;
  }
  @include mobile-lg {
    margin-top: 70px;
  }
}

.btnContainer {
  float: left;
  position: relative;
  @include mobile-sm {
    margin-top: 90px;
    position: absolute;
    width: 249px;
  }
  @include mobile-md {
    margin-top: 90px;
    position: absolute;
    width: 305px;
  }
  @include mobile-lg {
    margin-top: 90px;
    position: absolute;
    width: 355px;
  }
}

.btnTakeCall {
  bottom: 30px;
  margin-left: 60px;
  padding-left: 20px;
  @include mobile-sm {
    bottom: 0;
  }
  @include mobile-md {
    bottom: 0;
  }
  @include mobile-lg {
    bottom: 0;
  }
}

.no-audio-call {
  clear: left;
  color: #999;
  float: left;
  font-weight: bold;
  position: absolute;
  top: 40px;
  width: 305px;
}

.accordionHeader {
  float: right;
  margin-top: 5px;
}

.rotate {
  transform: rotate(180deg);
}

.attendRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.errorMessage {
  margin: 10px 0 10px 0;
  float: left;
  color: $color-red;
}
