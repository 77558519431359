@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.wrapper {
  @include mobile-sm {
    width: 250px;
  }
  @include mobile-md {
    width: 250px;
  }
  @include mobile-lg {
    width: 250px;
  }
  @include sm {
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    width: 355px;
  }
  @include md-sm {
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    width: 355px;
  }
  @include md {
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    width: 355px;
  }
  @include lg {
    left: 50%;
    transform: translateX(-50%);
    width: 355px;
  }
  @include max-screen {
    left: 50%;
    transform: translateX(-50%);
    width: 555px;
  }
  @include sm-height {
    top: 30%;
  }
  background: #fff;
  border: solid 1px #999;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 155px;
  padding: 20px;
  text-align: center;
  position: fixed;
  z-index: 999;
  -webkit-transform: translate(-50%, -50%);
  transform: translate x(-50%, -50%);
  left: 50%;
  top: 30%;
  overlay: initial;
  position: fixed;
  top: 30%;
  left: 50%;
  z-index: 10;
  outline: 9999px solid rgba(0, 0, 0, .5);
}

.title {
  margin-top: 0;
  color: #565252;
  font-family: $globalfont;
  font-size: 19px;
  text-align: center;
  white-space: pre-line;
  word-wrap: break-word;
}

.text {
  margin-top: 0;
  color: #999;
  font-family: $globalfont;
  font-size: 17px;
  text-align: center;
  white-space: pre-line;
  word-wrap: break-word;
}

.textOption {
  margin-top: 35px;
  color: $color-red;
  font-family: $globalfont;
  font-size: 17px;
  text-align: center;
  white-space: pre-line;
  word-wrap: break-word;
}

.checkboxContainer {
  float: right;
}