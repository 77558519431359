@import '../../../../../css/mixins/default_media_query.scss';
@import '../../../../../css/_variables.scss';
@import '../../../../../css/mixins/default_buttons';

.header-wrapper {
  float: left;
  width: 100%;
}

.left-wrapper {
  float: left;
  width: 50%;
}

.right-wrapper {
  @include mobile-sm{
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }
  @include mobile-md{
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }
  @include mobile-lg{
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }
  @include sm {
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }
  @include md-sm {
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }
}

.client-name {
  float: left;
  font-size: 2rem;
  position: relative;
  width: 100%;
}

.closed-call {
  float: left;
  font-size: 15px;
  margin-top: 10px;
  color: $color-gray-input-font;
}

.btn-content {
  @include mobile-sm{
    width: 275px;
  }
  @include mobile-md{
    width: 275px;
  }
  @include mobile-lg{
    width: 275px;
  }
  float: left;
  width: 330px;
}

.btn-call {
  @include btn;
  border: 0;
  clear: left;
  float: left;
  margin-top: 5px;
  height: 33px;
  width: 100%;
  &:hover {
    background: $color-tecno-hover;
  }
  &:focus {
    outline: none;
  }
}

.btn-external-call {
  @include btn;
  background: orange;
  border: 0;
  clear: left;
  float: left;
  margin-top: 5px;
  height: 33px;
  width: 100%;
  &:hover {
    background: $color-tecno-hover;
  }
  &:focus {
    outline: none;
  }
}

.btn-wide {
  width: 230px;
}

.warning-container {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-left: 20%;
}

.button-warning {
  @include btn;
}

.button-warning-disabled {
  @include btn;
  background-color: $color-gray;
}

.btnTakeCall {
  border: 0;
  @include btn;
  height: 33px;
  width: 100%;
  background: $color-green;
  &:hover {
    background: $color-green-hover;
  }
}

.btn-hangup-container {
  display: block;
  float: left;
  width: 100%;
}

.btn-audio-single {
  @include sm {
    margin-right: 5px;
  }
  border: 0;
  border-radius: 100%;
  height: 50px;
  margin-right: 15px;
  overflow: hidden;
  width: 50px;
}

.btn-listen-color {
  background: $color-audio-blue;
  &:hover {
    background: $color-audio-blue-hover;
  }
}

.btn-speak-color {
  background: $color-audio-yellow;
  &:hover {
    background: $color-audio-yellow-hover;
  }
}

.btn-two-way-audio-color {
  background: $color-audio-red;
  &:hover {
    background: $color-audio-red-hover;
  }
}

.btn-audio-control {
  @include mobile-sm{
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  @include mobile-md{
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  @include mobile-lg{
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  @include sm {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  @include md-sm {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  @include md {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  @include lg {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  display: inline-flex;
  float: left;
  margin-left: 70px;
}

.btn-audio-single {
  @include sm {
    margin-right: 5px;
  }
  border-radius: 100%;
  height: 50px;
  margin-right: 15px;
  overflow: hidden;
  width: 50px;
}

.btn-listen-color {
  background: $color-audio-blue;
}

.btn-speak-color {
  background: $color-audio-yellow;
}

.btn-two-way-audio-color {
  background: $color-audio-red;
}

.age-number {
  float: right;
  font-size: 40px;
  width: 60px;
  line-height: 1;
  height: 60px;
  color: $color-tecno;
}

.age-years {
  font-size: 20px;
  color: black;
  position: relative;
  line-height: 0.7;
  float: left;
  padding: 0;
  margin: 0;
}

.clock-frame{
  float: right;
  color: $color-tecno;
  border: solid 6px;
  border-radius: 100%;
  height: 70px;
  width: 70px;
}

.clock-digits{
  float: left;
  width: 100%;
  color: $color-black;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.btn-clock {
  @include mobile-sm{
    display: none;
  }
  @include mobile-md{
    display: none;
  }
  @include mobile-lg{
    display: none;
  }
  @include sm {
    margin-right: 5px;
  }
  background: #0046b5;
  border: 0;
  border-radius: 100%;
  color: $color-white;
  cursor: pointer;
  height: 50px;
  float: left;
  font-size: 35px;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-left: 40px;
  overflow: hidden;
  width: 50px;
  &:hover {
    background: #00235a;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.audio-status-text {
  @include mobile-sm{
    display: none;
  }
  @include mobile-md{
    display: none;
  }
  @include mobile-lg{
    display: none;
  }
  float: left;
  margin-top: 12px;
  height: 30px;
}

.btn-reply {
  margin-left: 10px;
  border: 0;
  border-radius: 5px;
  background: #4196CD;
  font-weight: bold;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  height: 2rem;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  width: 35px;
  &:hover {
    background-color: $btn-hover;
  }
}

.btn-send {
  border: 0;
  border-radius: 5px;
  background: #4196CD;
  font-weight: bold;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  height: 2rem;
  text-transform: uppercase;
  margin-top: 10px;
  float: right;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  width: 135px;
  &:hover {
    background-color: $btn-hover;
  }
}

.btn-cancel {
  border: 0;
  border-radius: 5px;
  background: #4196CD;
  font-weight: bold;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  height: 2rem;
  text-transform: uppercase;
  margin-top: 10px;
  float: left;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  width: 135px;
  &:hover {
    background-color: $btn-hover;
  }
}

.btn-reply-container {
  margin: 10px;
  width: 98%;
  padding-right: 10px;
}


.client-info-container {
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: space-between;
}

.clock-container {
  display: flex;
  width: 5%;
  align-items: center;
  justify-content: flex-end;
}

.call-controls-container {
  display: flex;
  width: 40.5%;
  align-items: center;
  justify-content: space-between;
}

.call-alexa-container {
  display: flex;
  width: 40.5%;
  justify-content: space-between;
  flex-direction: column;
}

.call-alexa-modal-container {
  padding: 8px;
}

.call-client-message {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.call-message-label {
  color: #b4b4b4;
  text-transform: uppercase;
  font-weight: bold;
}

.call-info-text {
  float: left;
  position: relative;
  margin-top: 15px;
}

.call-audio-status {
  float: left;
  margin-top: 15px;
  height: 30px;
  width: 130px;
}

.call-message-text {
  float: left;
  position: relative;
  margin-top: 15px;
  width: 100%;
}
