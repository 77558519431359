@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.form-text-error {
  @include sm {
    display: none;
  }
  color: $color-tomato;
  display: block;
  float: left;
  font-size: 10px;
  margin-top: 4px;
  position: absolute;
  right: 0;
  text-align: right;
}
