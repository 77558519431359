@import '../../css/mixins/default_media_query.scss';
@import '../../css/_variables.scss';
@import '../../css/mixins/default_buttons';

.main {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
}

.main > * {
  margin: 10px;
}

.error-btn {
  @include btn;
}
