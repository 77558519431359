@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';
@import '../../../../css/mixins/default_buttons';

.header-wrapper {
  float: left;
  width: 100%;
  font-size: 0.8rem;
}

.left-wrapper {
  float: left;
  width: 50%;
}

.right-wrapper {
  @include mobile-sm{
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }
  @include mobile-md{
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }
  @include mobile-lg{
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }
  @include sm {
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }
  @include md-sm {
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
  }
}

.client-name {
  float: left;
  font-size: 2rem;
  position: relative;
  margin-left: 10px;
  width: 100%;
}

.closed-call {
  float: left;
  font-size: 15px;
  margin-top: 10px;
  color: $color-gray-input-font;
}

.btn-call {
  border: 0;
  border-radius: 0;
  clear: left;
  color: $color-white;
  background: $color-tecno;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 14px;
  margin-top: 5px;
  outline: none;
  transition: background .3s ease;
  height: 33px;
  width: 330px;
  &:hover {
    background: $color-tecno-hover;
  }
  &:focus {
    outline: none;
  }
}

.age-number {
  float: right;
  font-size: 40px;
  width: 60px;
  line-height: 1;
  height: 60px;
  color: $color-tecno;
}

.age-years {
  font-size: 20px;
  color: black;
  position: relative;
  line-height: 0.7;
  float: left;
  padding: 0;
  margin: 0;
}

.clock-frame{
  float: right;
  color: $color-tecno;
  border: solid 6px;
  border-radius: 100%;
  height: 70px;
  width: 70px;
}

.clock-digits{
  float: left;
  width: 100%;
  color: $color-black;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.btn-external-call {
  @include btn;
  background: orange;
  border: 0;
  clear: left;
  float: left;
  margin-top: 5px;
  height: 33px;
  width: 100%;
  &:hover {
    background: $color-tecno-hover;
  }
  &:focus {
    outline: none;
  }
}

.btn-wide {
  width: 230px;
}