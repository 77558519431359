@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.container {
  position: fixed;
  z-index: 1001;
  background-color: $color-white;
  border: solid 1px $tooltip-border-color;
  border-radius: 6px;
  top: 40%;
  width: 525px;
  right: 40%;
  height: auto;
  padding: 10px;
  outline: 9999px solid rgba(0,0,0,0.5);
}

.button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #dc0b0b;
  background-color: $color-white;
  border: 0;
  font-size: 0.8rem;
  padding: 0.5rem;
  text-align: center;
  padding: 0;
  width: auto;
}

.text {
  float: left;
  width: 100%;
  margin-top: 5px;
}

.text-title {
  font-size: 16px;
  color: $info-color;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.text-subtitle {
  color: $color-gray;
  font-weight: bold;
}
