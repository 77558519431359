@import '../../../../css/mixins/default_media_query.scss';
@import '../../../../css/_variables.scss';

.commentWrapper {
  @include mobile-sm {
    float: left;
    width: 100%;
    margin-top: 10px;
  }
  @include mobile-md {
    float: left;
    width: 100%;
    margin-top: 10px;
  }
  @include mobile-lg {
    float: left;
    width: 100%;
    margin-top: 10px;
  }
  @include sm {
    float: left;
    width: 100%;
    margin-top: 10px;
  }
  @include md-sm {
    float: left;
    width: 100%;
  }
  @include md {
    float: left;
    width: 100%;
  }
  @include lg {
    float: left;
    margin-left: 10px;
    width: 100%;
  }
  @include max-screen {
    float: left;
    width: 100%;
    margin-left: 10px;
    margin-top: 10px;
  }

  float: left;
}

.textArea {
  @include mobile-sm {
    height: 150px;
    width: 98%;
  }
  @include mobile-md {
    height: 150px;
    width: 98%;
  }
  @include mobile-lg {
    height: 150px;
    width: 98%;
  }
  @include sm {
    height: 200px;
    width: 99%;
  }
  @include md-sm {
    height: 334px;
    width: 97%;
  }
  @include md {
    height: 334px;
    width: 97%;
  }
  @include lg {
    width: 98.5%;
    height: 330px;
  }
  @include max-screen {
    width: 99%;
    height: 330px;
  }
  height:  314px;
}
