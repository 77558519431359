@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.information-wrapper {
  width: 100%;
  float: left;
  font-size: 0.8rem;
}

.info-btn-save {
  border: 0;
  border-radius: 5px;
  background: $color-green;
  font-weight: bold;
  color: $color-white;
  float: right;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  margin-left: 5px;
  width: 35px;
  &:hover {
    background: $color-green-hover;
  }
}

.info-btn-cancel {
  border: 0;
  border-radius: 5px;
  background: $color-red;
  font-weight: bold;
  color: $color-white;
  float: right;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  margin-right: 5px;
  width: 35px;
  &:hover {
    background: $color-red-hover;
  }
}

.info-btn-edit {
  border: 0;
  border-radius: 5px;
  background: $btn-bg-color;
  font-weight: bold;
  color: $color-white;
  float: right;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  width: 35px;
  &:hover {
    background-color: $btn-hover;
  }
}

.label {
  box-sizing: border-box;
  color: $color-gray;
  float: left;
  font-size: 0.7rem;
  height: 26px;
  text-transform: uppercase;
  position: relative;
  text-align: right;
  height: auto;
  width: 25%;
  font-weight: bold;
  padding-top: 0.5rem;
  @include mobile-sm {
    width: 100%;
    text-align: left;
    height: auto;
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
    text-align: left;
    height: auto;
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
    text-align: left;
    height: auto;
    width: 100%;
  }
  @include sm {
    width: 100%;
    text-align: left;
    height: auto;
    width: 100%;
  }
  @include md-sm {
    width: 100%;
    text-align: left;
    height: auto;
    width: 100%;
  }
}

.value {
  float: left;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 0.9rem;
  padding-top: 0.6rem;
  margin-left: 1rem;
  line-height: 1.2rem;
}

.chat-row {
  float: left;
   width: 100%;
  //  height: 2.5rem;
   vertical-align: middle;
}
