@import '../../css/mixins/default_media_query';
@import '../../css/variables';


$width: 50px;
$width-expanded: 270px;

.sidebar {
  @include mobile-sm {
    position: absolute;
    width: 0;
  }
  @include mobile-md {
    position: absolute;
    width: 0;
  }
  @include mobile-lg {
    position: absolute;
    width: 0;
  }
  @include sm {
    position: absolute;
    width: 0;
  }
  @include md-sm {
    position: absolute;
    width: $width;
  }
  @include md {
    position: absolute;
    width: $width;
  }
  @include lg {
    float: left;
    position: relative;
    width: $width;
  }
  @include max-screen {
    float: left;
    position: relative;
    width: $width;
  }
  background-color: $sidebar-background-color;
  border-left: 0;
  border-radius: 2px;
  border-right: 1px solid $sidebar-border-color;
  box-sizing: border-box;
  color: $sidebar-icon-color;
  font-family: $globalfont;
  height: 100%;
  overflow: hidden;
  transition: .3s ease-in;
  z-index: 997;
}

.ul {
  box-sizing: border-box;
  float: left;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  transition: inherit;
  width: inherit;
  z-index: 999;
}

// #manage_icon{
//   display: block;
//   fill: inherit;
//   float: left;  
//   position: relative;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 50px;
//   padding-left: 1em;
// }

.icons{
  display: block;
  fill: inherit;
  float: left;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  text-align: center;
  color: $color-white;
  &:hover {
    color: $color-white;
    fill: $color-white;
    background-size: 50px 50px;
    } 
}

.iconsnopad{
  display: block;
  fill: inherit;
  float: left;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  
  
  &:hover {
    background-color: $sidebar-background-color-hover;
    color: $color-white;
    fill: $color-white;
    background-size: 50px 50px;
     } 
}



.li {
  box-sizing: border-box;
  color: $sidebar-icon-color;
  cursor: pointer;
  fill: $sidebar-icon-color;
  float: left;
  height: 50px;
  list-style: none;
  position: relative;
  transition: inherit;
  width: $width-expanded;

  &:first-child {
    height: 55px;

    &:hover {
      background-color: $sidebar-background-color-hover;
      fill: $color-white;
    }
  }

  &:hover {
    background-color: $sidebar-background-color-hover;
    color: $color-white;
    fill: $color-white;
  }
}

.active {
  background-color: $sidebar-background-color-hover;
  color: $color-white;
  fill: $color-white;
}

.text {
  color: $color-white;
  float: left;
  height: auto;
  position: relative;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
}

.link {
  color: $color-white;
  fill: inherit;
  float: left;
  font-size: 16px;
  height: inherit;
  position: relative;
  width: 100%;
}

.svg {
  display: block;
  fill: $color-white;
  float: left;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: $width;
  padding: 0.2rem;
}

.logo {
  display: block;
  float: left;
  height: 25px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 210px;
}

.vidapoint-text {
  float: left;
  position: absolute;
  padding-right: 15px;
  margin-right: 25px;
  left: 20px;
  bottom: -60px;
}

.button {
  background-color: transparent;
  border: 0;
  color: $color-white;
  cursor: pointer;
  float: left;
  height: 50px;
  outline: 0;
  padding: 0;
  position: relative;
}


.arrow {
  float: left;
  width: 40px;
}

.icon {
  transition: .3s ease;
}

.padrigth {
  padding-right: 1em;
}

.arrow-icon {
  cursor: pointer;
  float: right;
  margin-right: 15px;
  position: relative;
  transition: .3s ease;
}
