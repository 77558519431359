@import '../../../css/mixins/default_media_query';
@import '../../../css/mixins/default_title';
@import '../../../css/mixins/default_table';
@import '../../../css/mixins/default_buttons';
@import '../../../css/variables';
@import '../../../css/paginate';

.wrapper {
  @include mobile-sm {
    width: 100%;
    font-size: 1rem;
    float: left;
  }
  @include mobile-md {
    width: 100%;
   
  }
  @include mobile-lg {
    width: 100%;
     }
  @include sm {
    width: 100%;
   
  }
  @include md-sm {
    width: auto;
  }
  @include md {
    width: auto;
     }
  @include lg {
    width: auto;
    
  }
  @include max-screen {
    width: auto
    }
  height: auto;
  width: 100%;
  float: left;
  position: relative;
}

.title {
  @include general-title;
  @include sm {
    margin-bottom: 0;
  }
}

.list {
  font-family: $globalfont;
  margin-top: 0;
  @include mobile-sm {
    width: 100%;
    font-size: 1rem;
    float: left;
  }
  @include mobile-md {
    width: 100%;
    font-size: 0.9rem;
    float: left;
  }
  @include mobile-lg {
    width: 100%;
    font-size: 0.9rem;
    float: left;
  }
  @include sm {
    width: 100%;
    font-size: 0.9rem;
    float: left;
  }
  @include md-sm {
    width: auto;
    font-size: 0.9rem;
    display: inline-block;
    float: left;
  }
  @include md {
    width: auto;
    font-size: 0.9rem;
    display: inline-block;
    float: left;
  }
  @include lg {
    width: 120px;
    font-size: 0.9rem;
    float: left;
  }
  @include max-screen {
    width: 120px;
    font-size: 0.9rem;
    float: left;
  }
}

.ul {
  @include mobile-sm {
    grid-gap: 0;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 50%;
    transform: translate(-50%);
    display: inline-flex;
  }
  @include mobile-md {
    grid-gap: 0;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 50%;
    transform: translate(-50%);
    display: inline-flex;
  }
  @include mobile-lg {
    grid-gap: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-left: 50%;
    transform: translate(-50%);
  }
  @include sm {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-left: 50%;
    transform: translate(-50%);
  }
  @include md-sm {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-left: 50%;
    transform: translate(-50%);
  }
  @include md {
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-left: 50%;
    transform: translate(-50%);
  }
  @include lg {
    grid-template-rows: 1fr 1fr 1fr;
    width: inherit;
  }
  @include max-screen {
    grid-template-rows: 1fr 1fr 1fr;
    width: inherit;
  }
  display: inline-flex;
  float: left;
  list-style: none;
  padding: 0;
  position: relative;
}

.li {
  box-sizing: border-box;
  float: left;
  height: 44px;
  position: relative;
  // @include lg {
  //   border-bottom: solid 1px;
  //  border-color:$color-gray;

  //   // &:last-child {
  //   //   border: 0;
  //   // }
  // }
}

.button {
  background-color: transparent;
  border: 0;
  color: $color-gray;
  cursor: pointer;
  height: 100%;
  width: 100%;
  @include mobile-sm {
    font-size: 0.6rem;
    text-align: center;
  }
  @include mobile-md {
    font-size: 0.6rem;
    text-align: center;
  }
  @include mobile-lg {
    font-size: 0.6rem;
    text-align: center;
  }
  @include sm {
    font-size: 0.8rem;
    text-align: center;
  }
  @include md {
    font-size: 0.8rem;
    text-align: center;
  }
  @include md-sm {
    font-size: 0.8rem;
    text-align: center;
  }
  @include lg {
    font-size: 0.9rem;
    text-align: left;
  }
  @include max-screen {
    font-size: 0.9rem;
    text-align: left;
  }
}

.active {
  @include sm {
    border-bottom: solid 1px;
  }
  color: $color-black;
  font-weight: bold;
}

.title {
  color: $color-grafite;
  font-size: 1.1rem;
  // height: 1.5rem;
  line-height: 1.5rem;
  /* margin-bottom: 10px; */
  position: relative;
  width: auto;
  display: inline-block;
  margin-top: 0.5rem;
}
