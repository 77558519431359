@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.wrapper {
  @include mobile-sm {
    box-sizing: border-box;
    height: initial;
    left: 50px;
    padding: 10px;
    width: calc(100% - 50px);
  }
  @include mobile-md {
    box-sizing: border-box;
    height: initial;
    left: 50px;
    padding: 10px;
    width: calc(100% - 50px);
  }
  @include mobile-lg {
    box-sizing: border-box;
    height: initial;
    left: 50px;
    padding: 10px;
    width: calc(100% - 50px);
  }
  @include sm {
    box-sizing: border-box;
    height: initial;
    left: 50px;
    padding: 10px;
    width: calc(100% - 50px);
  }
  @include md-sm {
    left: 50%;
    transform: translateX(-50%);
    width: 555px;
  }
  @include md {
    left: 50%;
    transform: translateX(-50%);
    width: 555px;
  }
  @include lg {
    left: 50%;
    transform: translateX(-50%);
    width: 555px;
  }
  @include max-screen {
    left: 50%;
    transform: translateX(-50%);
    width: 555px;
  }
  background: #fff;
  border: solid 1px #999;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 20px;
  position: fixed;
  outline: 9999px solid rgba(0, 0, 0, 0.5);
  top: 105px;
  z-index: 1001;
}

.title {
 @include sm {
    margin-top: 0;
  }
 @include md {
    margin-top: 25px;
  }
   @include lg {
    margin-top: 25px;
  }
  color: #999;
  font-size: 17px;
  text-align: center;
}

.container {
  @include mobile-sm {
    float: left;
    margin-top: 10px;
    position: relative;
    width: 100%;
  }
  @include mobile-md {
    float: left;
    margin-top: 10px;
    position: relative;
    width: 100%;
  }
  @include mobile-lg {
    float: left;
    margin-top: 10px;
    position: relative;
    width: 100%;
  }
  @include sm {
    float: left;
    margin-top: 10px;
    position: relative;
    width: 100%;
  }
  @include md-sm {
    bottom: 20px;
    width: 289px;
  }
 @include md {
    bottom: 20px;
    width: 289px;
  }
   @include lg {
    bottom: 20px;
    width: 289px;
  }
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.btn {
  background-color: $color-tecno;
  border: 0;
  border-radius: 5px;
  color: $color-white;
  cursor: pointer;
  float: right;
  font-family: $globalfont;
  font-size: 1rem;
  height: 30px;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  width: 175px;
}

.btn:hover {
  background-color: $btn-hover;
}

.actions{
  border-radius: 5px;
  float: left;
  position: relative;
}

.hideModal{
  visibility: hidden;
}

.text {
  margin: 5px 5px 5px 5px;
}
