@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.wrapper {
  height: 100%;
  width: 100%;
  // border-width: 6px;
  //border-radius: 5px;
  border-color: transparent;
  background-color: transparent;
  padding-bottom: 5px;
  @include sm-md {
    margin-bottom: 1rem;
  }
}

// .wrapper:hover {
//   background-color: #e4e4e4;
// }

.header {
  padding: 5px;
  max-height: 150px;
  border-style: solid;
  // border-width: 6px;
  // border-radius: 5px 5px 0 0;
  border-color: transparent;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}

.headerItem {
  color: white;
  position: relative;
  //margin: 10px 40px 10px 40px;
  // max-height: 22.5px;
  //width: 95%;
  display: inline-block;
  word-wrap: break-word;
  font-family: $globalfont;
  //width: 200px;
  overflow: hidden;
  //white-space: nowrap;

}

.body {
  // border-width: 6px;
  // border-radius: 0 0 5px 5px;
  border-color: transparent;
  // height: 99% !important;
  // min-height: 200px;
  max-height: 200px;
  // overflow-y: scroll;
  display: inline-block;
  width: 100%;
}

.footer {
  padding: 5px;
  border-top-style: solid;
  border-top-color: gray;
  border-top-width: 1px;
  height: 60px;
  width: 97%;
  margin: 1%;
}

.headerWrapper {
  padding: 4.5px;
  border-style: solid;
  border-width: 6px;
  //border-radius: 5px;
  border-color: transparent;
}

.leftContainer {
  float: left;
  height: 99.9%;
  color: white;
}

.rightContainer {
  float: right;
  height: 99.9%;
  color: white;
}

.small-box {
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.small-box .icon {
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 0;
  font-size: 90px;
  color: rgba(0,0,0,0.15);
}
