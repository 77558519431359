@import 'mixins/default_media_query';
@import 'variables';


.block {
  float: left;
  position: relative;
  width: 100%;

  &--textarea {
    height: 380px;
    margin-bottom: 60px;
  }
}

.block-title {
  border-bottom: solid 1px;
  box-sizing: border-box;
  color: $block-title-color;
  float: left;
  font-size: 0.7rem;
  height: 1.5rem;
  position: relative;
  width: 100%;
  font-weight: bold;
  &--attendance {
    font-weight: 400;
  }
}

.block-content {
  @include sm {
    padding: 10px 0;
  }
  float: left;
  padding: 1rem 0;
  position: relative;
  width: 100%;
}

.block-content-container {
  @include sm {
    height: auto;
  }
  float: left;
  font-size: 1rem;
  height: 34px;
  margin: 5px 0;
  position: relative;
  display: inline-flex;
  width: 100%;
  border-bottom: solid 1px $color-gray; 

  &--100 {
    width: 100%;
  }

  &--50 {
    @include sm {
      width: 100%;
    }
    width: 50%;
  }

  &--33 {
    @include sm {
      width: 100%;
    }
    @include md {
      width: 33%;
    }
    width: 33%;
  }

  &--250 {
    width: 250px;
  }
}

.block-hide {
  @include sm {
    display: none;
  }
  @include md {
    display: none;
  }
}

.block-field {
  box-sizing: border-box;
  color: $color-gray;
  text-align: right;
  float: left;
  font-size: 0.7rem;
  font-weight: bold;
  // height: $height;
  line-height: 2;
  // padding: 0 9px;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  text-overflow: ellipsis;
  // width: $field-width;
  &--full {
    width: 160px;
  }
  @include mobile-sm {
    text-align: left;
  }

  @include mobile-md {
    text-align: left;
  }

  @include mobile-lg {
    text-align: left;
  }
  @include sm {
    text-align: left;
    height: auto;
  }
}

.block-margin-value {
  margin-left: $field-width;
}

.block-value {
  
  float: left;
  font-size: 1rem;
  // height: $height;
  line-height: 2;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5rem;
  padding: 0 0.5rem ;
  text-align: left;

  @include mobile-sm {
    text-align: left;
  }

  @include mobile-md {
    text-align: left;
  }

  @include mobile-lg {
    text-align: left;
  }

  @include sm {
    text-align: left;
    height: auto;
  }
  // width: auto;
  // width: calc(100% - #{$field-width});
}

