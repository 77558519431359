@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';
@import '../../../../css/mixins/default_table';

.table {
  @include table;
  float: left;
  height: auto;
  position: relative;
  width: 100%;
}

.header {
  @include header;
}

.body {
  @include body;
}

.row {
  @include tableRow;
  border-bottom: 1px solid #ddd;
   &:hover {
    background-color: $color-gray-soft;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.content {
  @include sm {
    margin-top: 5px;
     }
  width: 100%;
  float: left;
  position: relative;
}

.headerBtn {
  background: transparent;
  border: 0;
  color: #333;
  cursor: pointer;
  float: left;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0;
  position: relative;
  text-transform: uppercase;
}

.headerTextContainer {
  width: 270px;
}

.bodyTextContainer {
  width: 265px;
  float: left;
  font-size: 1rem;
  line-height: 2.5;
}

.headerStatus {
  @include sm {
    margin-right: 10px;
  }
  @include sm-md {
    margin-right: 10px;
  }
  @include md-sm {
    margin-right: 10px;
  }
  @include md {
    margin-right: 10px;
  }
  @include lg {
    margin-right: 10px;
  }
  width: 50px;
}

.headerName {
  @extend .headerTextContainer;
  @include mobile-sm {
    width: 130px;
  }
  @include mobile-md {
    width: 130px;
  }
  @include mobile-lg {
    width: 130px;
  }
  @include sm {
    width: 130px;
  }
}

.headerGroupName {
  @extend .headerTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.headerCompanyName {
  @extend .headerTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: 130px;
  }
}

.headerLastOnlineAt {
  @extend .headerTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.bodyName {
  @extend .bodyTextContainer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include mobile-sm {
    width: 120px;
  }
  @include mobile-md {
    width: 120px;
  }
  @include mobile-lg {
    width: 120px;
  }
  @include sm {
    width: 120px;
  }
}

.bodyGroupName {
  @extend .bodyTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.bodyCompanyName {
  @extend .bodyTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: 120px;
  }
}

.bodyLastOnline {
  @extend .bodyTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.bodyStatus {
  @include md {
    width: 50px;
  }
  @include lg {
    width: 50px;
  }
  float: left;
  font-size: 1rem;
  line-height: 2.5;
  width: 50px;
}


.online {
  background: #24be30;
  border-radius: 100%;
  display: block;
  height: 15px;
  margin: 0;
  margin-top: 6px;
  width: 15px;
}

.offline {
  background: #ededed;
  border-radius: 100%;
  display: block;
  height: 15px;
  margin: 0;
  margin-top: 6px;
  width: 15px;
}
