@import 'mixins/default_media_query';
@import 'variables';

.confirm-remove-modal {
  @include sm {
    box-sizing: border-box;
    height: initial;
    padding: 10px;
    width: 100%;
  }
  background: $color-white;
  border: solid 1px #999;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 135px;
  padding: 20px;
  text-align: center;
  position: fixed;
  z-index: 999;
  -webkit-transform: translate(-50%, -50%);
  transform: translate x(-50%, -50%);
  left: 50%;
  top: 30%;
  overlay: initial;
  position: fixed;
  top: 30%;
  left: 50%;
  z-index: 10;
  outline: 9999px solid rgba(0, 0, 0, .5);
}

.contactto-text-modal {
  @include sm {
    margin-top: 0;
  }
  color: $sidebar-icon-color;
  font-size: 17px;
  margin-top: 25px;
  text-align: center;
}

.contactto-btn-container {
  @include sm {
    float: left;
    margin-top: 10px;
    position: relative;
    width: 100%;
    bottom: initial;
  }
  bottom: 20px;
  width: 289px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

/* ########### ALARM MODAL ########### */

.alarm-modal-container {
  background: rgba(0,0,0,0.75);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 998;
}

.alarm-modal-content {
  @include sm {
    width: 100%;
  }
  background: $color-white;
  border-radius: 5px;
  float: left;
  height: 200px;
  margin-left: 50%;
  margin-top: 80px;
  position: relative;
  transform: translateX(-50%);
  width: 435px;
}

.alarm-modal-emergency {
  color: $color-gray-title;
  font-family: $globalfont;
  font-size: 29px;
  position: relative;
  text-align: center;
  top: 35px;
}

.alarm-modal-client {
  color: $color-gray-title;
  font-family: $globalfont;
  font-size: 23px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  top: 45px;
  white-space: nowrap;
}

.alarm-modal-button-container{
    position: absolute;
    bottom: 16px;
    height: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.alarm-modal-btn{
    color: $color-blue;
    position: absolute;
    background: white;
    border-radius: 10px;
    height: inherit;
    font-weight: bold;
    font-family: $globalfont;
    background: white;
    border: 0;
    width: 100px;
}

.alarm-modal-cancel-btn{
    right: 0;
}

.alarm-modal-close-btn {
    float: right;
    fill:$sidebar-icon-color;
    cursor: pointer;
}

.warning-modal-wrapper {
  background: #ecc057;
  border-radius: 5px;
  font-size: 18px;
  left: 50%;
  max-height: 100%;
  max-width: 600px;
  opacity: 1;
  overflow-y: auto;
  padding: 10px 20px 10px 10px;
  position: absolute;
  top: 35px;
  transform: translateX(-50%);
  z-index: 999;
}

.warning-modal-message {
  color: #ffffff;
  float: left;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.warning-modal-button {
  background: none;
  border: none;
  color: $bg-color;
  position: absolute;
  right: 0;
  top: 0;
  &:hover {
    color: $bg-color-hover;
  }
}
