@import '../../../css/mixins/default_media_query';
@import '../../../css/variables';

.info {
  box-sizing: border-box;
  color: $info-color;
  display: flex;
  flex-direction: column;
  float: left;
  justify-content: space-between;
  // padding: 15px 0;
  position: relative;
  width: 100%;
}

.icon {
  border-radius: 5px;
  box-sizing: border-box;
  color: $icon-white;
  float: left;
  padding: $padding-icon-value;
  position: relative;
  transition: 0.3s ease;
  /*
  &:hover {
    background-color: $icon-bg-color-hover;
    color: $icon-color-hover;

    & + .description {
      top: -34px;
    }
  }
  */
}

.icon-bg {
  border-radius: 5px;
  box-sizing: border-box;
  color: $icon-white;
  float: right;
  padding: $padding-icon-value;
  position: relative;
  transition: 0.3s ease;
}

.iconwrapper{
  width: 5rem;
  height: 5rem;
  padding-left: 0.5rem;
  display: inline-block;
  @include sm-md{ 
    width: 4rem;
    height: 4rem;
  }
}


.text {
  @include mobile-sm {
    width: 75%;
  } 
  @include mobile-md {
    width: 75%;
  }
  @include mobile-lg {
    width: 75%;
  }
  @include sm {
    width: 75%;
  }
  @include md-sm {
    width: 70%;
  }
  @include md {
    width: 70%;
  }
  @include lg {
    width: 60%;
  }
  @include max-screen {
    width: 65%;
  }
  box-sizing: border-box;
  font-size: 17px;
  float: right;
  overflow: hidden;
  padding-left: 10px;
  padding: 5px 0;
  display: block;
}

.value {
  @include mobile-sm {
    width: 100%;
  } 
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: 100%;
  }
  @include md-sm {
    width: 100%;
  }
  @include md {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @include lg {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @include max-screen {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  box-sizing: border-box;
  //float: left;
  margin-left: 0.5rem;
  overflow: hidden;
  padding: $padding-icon-value;
  position: relative;
  text-align: left;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  width: calc(100% - 35px);
  color: white;
}

.description {
  background-color: $icon-bg-color-hover;
  border-radius: 5px;
  box-sizing: border-box;
  color: $icon-color-hover;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  top: -100px;
  transition: 0.3s ease;
  width: 100%;
  z-index: 2;
}

.card-footer {
  color: white;
  float: left;
  width: 100%;
  padding: 0;
  font-size: 0.8rem;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.small-box {
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

.small-box .icon {
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 0;
  font-size: 90px;
  color: rgba(0,0,0,0.15);
}
