@import 'mixins/default_media_query';
@import 'variables';

.loading-bro {
  font-family: $globalfont;
  margin: 50px auto;
  width: 250px;

  > h1 {
    color: $color-gray;
    font-size: 2.5em;
    font-weight: 300;
    margin-bottom: 1em;
    text-align: center;
  }
}

#load {
  animation: loading 3s linear infinite;
  width: 150px;
  #loading-inner {
    stroke: {
      dasharray: 300;
      dashoffset: 0;
      linecap: round;
      miterlimit: 10;
      width: 10;
    }
    fill: transparent;
    animation: loading-circle 2s linear infinite;
    stroke: $color-tecno;
  }
}

.loading-svg-wrapper {
  float: left;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: auto;
}

@keyframes loading {
  0% {
    transform: rotate(0);
    }
  100% {
    transform: rotate(360deg);
    }
}
@keyframes loading-circle {
  0% {
    stroke-dashoffset: 0;
    }
  100% {
    stroke-dashoffset: -600;
    }
}
