@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.wrapper {
    display: block;
    float: left;
    margin-left: 0.5rem;
    width: 100%;
    height: 100%;
    padding-top:0.5rem;
}

.textLabel {
    border: 0;
    color: #415057;
    float: left;
    font-size: 0.9rem;
    line-height: 2;
    text-align: left;
}

.input {
    // border: solid 1px #dfdfdf;
    box-sizing: border-box;
    color: #808285;
    float: left;
    font-family: $globalfont;
    height: 26px;
    margin-left: 10px;
    outline: 0;
    padding: 0 5px;
    position: relative;
    top: 3px;
    transition: 0.3s ease;
    width: 49%;
    font-size: 0.9rem;
  
    &:hover {
      border-color: $color-tecno;
    }
    @include mobile-sm {
      width: 85%;
    }
    @include mobile-md {
      width: 85%;
    }
    @include mobile-lg {
      width: 85%;
    }
    @include sm {
      width: 85%;
    }
    @include md-sm {
      width: 85%;
    }
  }

.inputContainer {
    float: left;
    width: 100%;
    font-size: 0.9rem;
}

// .checkbox {
//     float: left;
//     height: 30px;
//     margin-left: 10px;
//     width: 16px;
// }
.checkbox {
  width:2rem;
  height:2rem;
  margin-left: 0.5rem;
  @include mobile-sm {
    padding-top:0.5rem;
  } 
  @include mobile-md {
    padding-top:0.5rem;
  } 
  @include mobile-lg {
    padding-top:0.5rem;
  } 
  @include sm {
    padding-top:0.5rem;
  } 
  @include md-sm {
    padding-top:0.5rem;
  }
}


.tooltipContainer {
  // float: left;
  color: $btn-bg-color;
  @include mobile-sm {
    margin-right: 1.5rem;
  }
  @include mobile-md {
    margin-right: 1.5rem;
  }
  @include mobile-lg {
    margin-right: 1.5rem;
  }
}

.button {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  display: block;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 37px;
  margin-top: 15px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  transition: background 0.3s ease;
  font-weight: bold;
  width: 50%;
  &:hover {
    background-color: $btn-hover;
  }
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
    @include sm {
      width: 100%;
    }
    @include md-sm {
      width: 100%;
    }
  }
