@import '../../css/mixins/default_media_query.scss';
@import '../../css/variables.scss';

.mapDot {
  background: #2a86d7;
  border-radius: 100%;
  // background: radial-gradient(circle at 2px 5px, #2a86d7, #3a3434);
  display: block;
  height: 15px;
  margin: 0;
  width: 15px;
}

.SOSDot {
  background: #980000;
}

.map-container {
  @include mobile-sm {
     height: 320px;
  }
  @include mobile-md {
    height: 320px;
  }
  @include mobile-lg {
    height: 320px;
  }
  @include sm {
    height: 320px;
  }
  @include md-sm {
    height: 420px;
  }
  width: 100%;
  height: 520px;
  margin: 0 auto;
}
