@import "../../../../css/mixins/reports";
@import "../../../../css/mixins/default_media_query";
@import "../../../../css/mixins/default_buttons";
@import "../../../../css/variables";

.title {
  @include report-title;
  @include mobile-sm {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-md {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-lg {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include sm {
    line-height: 1;
    margin-top: 15px;
  }
  @include md {
    margin-bottom: 0;
  }
  @include lg {
    margin-bottom: 0;
  }
  @include max-screen {
    margin-bottom: 0;
  }
  width: 100%;
}

.subtitle {
  float: left;
  color: $color-gray-title;
  @include mobile-sm {
     margin-top: 15px;
  }
  @include mobile-md {
    margin-top: 15px;
  }
  @include mobile-lg {
    margin-top: 15px;
  }
}

.table {
  @include report-table;
   
  // @include sm {
  //   display: none;
  // }
}

.paginate {
  @include report-paginate;
}

.header {
  @include report-header;
}

.header-column {
  @include report-header-column;
}

.body {
  @include report-body;
}

.row {
  @include report-row;
}

.column {
  @include report-column;
}

.download {
  //@include report-download;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: $color-white;
  background-color: $btn-bg-color;
}

.id {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 60px;
  }
  @include md-sm {
    width: 60px;
  }
  @include md {
    width: 65px;
  }
  @include lg {
    width: 75px;
  }
  @include max-screen {
    width: 75px;
  }
}

.account {
  @include report-field;
  width: 75px;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md-sm {
    display: none;
  }
}

.client {
  @include report-field;
  @include mobile-sm {
    width: 150px;
  }
  @include mobile-md {
    width: 180px;
  }
  @include mobile-lg {
    width: 200px;
  }
  @include sm {
    width: 250px;
  }
  @include md-sm {
    width: 225px;
  }
  @include md {
    width: 225px;
  }
  @include lg {
    width: 250px;
  }
  @include max-screen {
    width: 250px;
  }
}

.dateOpen {
  @include report-field;
  width: 200px;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
}

.dateClosed {
  @include report-field;
  width: 200px;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md-sm {
    display: none;
  }
  @include md {
    display: none;
  }
}

.resolvedBy {
  @include report-field;
  @include mobile-sm {
    width: 150px;
  }
  @include mobile-md {
    width: 180px;
  }
  @include mobile-lg {
    width: 200px;
  }
   @include sm {
    width: 220px;
  }
  @include md-sm {
    width: 220px;
  }
  @include md {
    display: 250px;
  }
  @include lg {
    width: 300px;
  }
  @include max-screen {
    width: 300px;
  }
}

.duration {
  @include report-field;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md-sm {
    width: 85px;
  }
  @include md {
    width: 85px;
  }
  @include lg {
    width: 55px;
  }
  @include max-screen {
    width: 55px;
  }
}
.btn-print {
  @include button;
  @include sm {
    height: 37px;
    margin-top: 85px;
    width: 100%;
  }
  background: $btn-bg-color;
}

.rotate {
  transform: rotate(180deg);
}
