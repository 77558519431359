@import 'mixins/default_media_query';
@import 'variables';

$management-width: 160px;
$list-table-header-height: 37px;

.list-title {
  @include sm {
    height: 22px;
    line-height: 1;
    margin-bottom: 10px;
  }
  color: $color-tecno;
  float: left;
  font-size: 22px;
  height: 48px;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

.list-subtitle {
  clear: left;
  float: left;
  font-size: 16px;
  margin-bottom: 20px;
  position: relative;
}

.list-subtitle--field {
  color: $color-gray;
}

.list-subtitle--value {
  color: $color-black;
}

.list-management-subtitle {
  color: $color-black;
  float: left;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 91px;
  padding: 10px;
}

.list-management-header {
  color: $color-black;
  float: left;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 91px;
  padding: 10px 10px 10px 0;
}

.list-table {
  @include sm {
    margin-top: 10px;
  }
  box-sizing: border-box;
  float: left;
  height: calc(100% - 160px);
  margin-top: 20px;
  position: relative;
  width: 100%;
}

.list-table-title {
  background-color: $color-tecno;
  border-radius: 5px;
  box-sizing: border-box;
  color: $color-white;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  line-height: 2.6;
  padding-left: 10px;
  width: 100%;

  &--monitoring {
    border: 0;
    text-align: left;
  }
}

.list-table-header {
  background-color: $color-gray-table;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-sizing: border-box;
  color: $color-black-light;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  height: $list-table-header-height;
  justify-content: space-between;
  line-height: 1.7;
  padding: 9px 10px;
  width: 100%;
}

.list-table-header-btn {
  background: transparent;
  border: 0;
  color: $color-black-light;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0;

  &:hover {
    color: $color-tecno;
  }
}

.list-table-body {
  float: left;
  height: calc(100% - #{$list-table-header-height});
  position: relative;
  width: 100%;

  &--management {
    height: calc(100% - 100px);
  }

  &--monitoring {
    height: calc(100% - 150px);
  }
}

.list-table-body-row {
  border: solid 1px $color-gray-table;
  border-top: 0;
  box-sizing: border-box;
  color: $color-black-light;
  display: flex;
  float: left;
  font-weight: 300;
  // height: 37px;
  justify-content: space-between;
  padding: 4px 10px;
  position: relative;
  width: 100%;

  &--deleted {
    color: tomato;
    border: solid 1px tomato;
    border-bottom: 0;

    &:last-child {
      border-bottom: solid 1px;
    }
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.list-table-body-column {
  color: inherit;
  float: left;
  font-size: 1rem;
  line-height: 2.5;
  position: relative;
  transition: .5s ease;
}

.list-table-report {
  cursor: pointer;
  text-decoration: none;
  transition: .5s ease;

  &:hover {
    background: $color-gray-soft;
  }
}

.list-table--500 {
  width: 500px;
}

.list-table-right {
  text-align: right;
}

.list-table--800 {
  width: 800px;
}

.list-table--550 {
  width: 550px;
}

.list-table--350 {
  width: 350px;
}

.list-table--310 {
  width: 310px;
}

.list-table--308 {
  width: 308px;
}

.list-table--300 {
  width: 300px;
}

.list-table--250 {
  width: 250px;
}

.list-table--200 {
  width: 200px;
}

.list-table--145 {
  width: 145px;
}

.list-table--100 {
  width: 100px;
}

.list-table--70 {
  width: 70px;
}

.list-table--63 {
  width: 63px;
}

.list-table--55 {
  width: 55px;
}

.list-table--25 {
  width: 25px;
}

.list-table--8 {
  margin-right: 8px;
}

.list-table--left {
  left: 35px;
}

.list-table--10-left {
  margin-left: 10px;
}

.list-table-column--date {
  width: 160px;
}

.action--1icon {
  text-align: center;
  width: 35px;
}

.action--2icons {
  text-align: center;
  width: 63px;
}

.action--3icons {
  width: 85px;
}

.action--5icons {
  width: 160px;
}

.action--6icons {
  width: 190px;
}

.list-table-body-action-icons {
  cursor: pointer;
  fill: $color-gray-icons;
  transition: fill .3s ease;

  &:hover {
    fill: $color-tecno;
  }
}

.list-table-body-dashboard {
  line-height: 1.8;
}

.management-menu {
  float: left;
  height: inherit;
  position: relative;
  width: $management-width;
}

.management-content {
  float: left;
  height: inherit;
  position: relative;
  width: calc(100% - #{$management-width});
}

.management-list {
  clear: left;
  display: flex;
  flex-direction: column;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 132px;
  justify-content: space-around;
  margin-top: 20px;
  width: 108px;

  &--active {
    color: $color-black;
    font-weight: bold;
  }
}

.management-list-ul {
  float: left;
  list-style: none;
  padding: 0;
  position: relative;
}

.management-list-item {
  border-bottom: solid 1px $color-gray;
  color: $color-gray;
  cursor: pointer;
  font-size: inherit;
  height: 44px;
  line-height: 3.38;
  padding: 0 5px;
  text-align: left;
  transition: .3s ease;
  width: 100%;

  &--active {
    color: $color-black;
    font-weight: bold;
  }

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    color: $color-black;
    font-weight: bold;
  }
}
