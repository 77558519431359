@import '../../../../css/mixins/default_media_query.scss';
@import '../../../../css/_variables.scss';
@import '../../../../css/mixins/default_buttons';

.container {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-left: 20%;
}

.button-warning {
  @include btn;
}

.button-warning-disabled {
  @include btn;
  background-color: $color-gray;
}
