@import '../../../css/mixins/default_media_query';
@import "../../../css/mixins/default_title";
@import "../../../css/mixins/default_table";
@import "../../../css/mixins/default_buttons";
@import "../../../css/variables";
@import "../../../css/paginate";

.title {
  @include general-title;
}

.subtitle {
  @include mobile-sm {
    flex-direction: column;
  }
  @include mobile-md {
    flex-direction: column;
  }
  @include mobile-lg {
    flex-direction: column;
  }
  @include sm {
    flex-direction: column;
  }
  @include md-sm {
    flex-direction: column;
  }
  display: flex;
  font-size: 1rem !important;
  font-weight: 400 !important;
  @include subtitle;
}

.subtitleContainer {
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: 100%;
  }
  @include md-sm {
    width: 100%;
  }
  @include md {
    width: auto;
  }
  @include lg {
    width: auto;
  }
  @include max-screen {
    width: auto;
  }
  float: right;
  height: auto;
  margin-left: 10px;
  margin-top: 10px;
  position: relative;
  color: #b4b4b4;
}

.paginate {
  @include paginate;
}

.table {
  @include table;
  @include wrapper;
  margin-top: 1.5rem;
}

.header {
  @include header;
}

.header-column {
  @include header-column;
}

.table-body {
  @include table-body;
}

.row {
  @include rowtb;
}

.column {
  @include column;
}

.removed {
  @include removed;
}

.actions {
  @include actions;
}

.icons {
  margin-bottom: 0.5rem;
  @include icons;
}

.add {
  @include add;
}

.account {
  @include mobile-sm {
    width: 75px;
  }
  @include mobile-md {
    width: 75px;
  }
  @include mobile-lg {
    width: 75px;
  }
  @include sm {
    width: 75px;
  }
  @include md-sm {
    width: 75px;
  }
  @include md {
    width: 75px;
  }
  @include lg {
    width: 80px;
  }
  @include max-screen {
    width: 80px;
  }
}

.btn-add {
  @include btn-add;
}

.btn {
  @include btn; 
       &--bold {
        font-weight: bold;
      }
    }

.btn-content {
  @include btn-content;
  @include md {
    float: right !important;
    width: auto !important;
  }
  @include lg {
    float: right !important;
    width: auto !important;
  }
  @include max-screen {
    float: right !important;
    width: auto !important;
  }
}

.emptyRow {
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 0;
  box-sizing: border-box;
  color: #333;
  display: flex;
  fill: #666;
  float: left;
  font-weight: 300;
  height: calc(var(37) * 1px);
  justify-content: space-between;
  padding: 4px 10px;
  position: relative;
  transition: 0.3s ease;
  width: 100%;
  &:hover {
    background-color: $color-gray-soft;
  }
}

.toggle-container {
  @include mobile-sm {
    position: relative;
    right: 10%;
    float: left;
    left: 0.7rem;
    top:1rem;
  }
  @include mobile-md {
    position: relative;
    right: 10%;
    float: left;
    left: 0.7rem;
    top: 1rem;
  }
  @include mobile-lg {
    position: relative;
    right: 10%;
    float: left;
    left: 0.7rem;
    top:1rem;
  }
  @include sm {
    right: 0;
    position: absolute;
    top: 70px;
    width: 130px;
  }
  @include md-sm {
    left: 0px;
    bottom: 0px;
    width: 130px;
    top: 1rem;
    float: left;
  }
  @include md {
    right: 0px;
    bottom: 0px;
    width: 130px;
  }
  @include lg {
    right: -0.5em;
  }
  @include max-screen {
    right: -1.5em;
  }
  position: relative;
  right: 10%;
  float: right;
  font-size:0.8rem;
}

.logsIcon {
  font-size: 26px;
  height: 26px;
  width: 26px;
}

.paginate-wrapper {
  @include mobile-sm {
    top: -0.9rem;
  }
  @include mobile-md {
    top: -0.9rem;
  }
  @include mobile-lg {
    top: -0.9rem;
  }
  @include sm {
    top: -0.9rem;
  }
  @include md-sm {
    top: -2rem;
  }
  @include md {
    top: -1rem;
  }
  @include lg {
    top: -1rem;
  }
  @include max-screen {
    top: -1rem;
  }
  position: absolute;
  right: 0;
  line-height: 25px;
  top: -171px;
  color: #999;
  font-size: 0.8rem;
}

.account {
  @include mobile-sm {
    width: 55px;
  }
  @include mobile-md {
    width: 55px;
  }
  @include mobile-lg {
    width: 55px;
  }
  @include sm {
    width: 55px;
  }
  @include md-sm {
    width: 55px;
  }
  @include md {
    width: 55px;
  }
  @include lg {
    width: 100px;
  }
  @include max-screen {
    width: 100px;
  }
}

.btn-import {
  
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.trackerActions {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 65px;
  }
  @include md-sm {
    width: 65px;
  }
  @include md {
    width: 65px;
  }
  @include lg {
    width: 65px;
  }
  @include max-screen {
    width: 65px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 0.7rem;
  text-transform: uppercase; 
}

.toggleButtonWrapper {
  @include toggleButtonWrapper;
}

.toggleButton {
  @include toggleButton;
}

.adminActions {
  @include mobile-sm {
    width: 30px;
  }
  @include mobile-md {
    width: 30px;
  }
  @include mobile-lg {
    width: 30px;
  }
  @include sm {
    width: 200px;
  }
  @include md-sm {
    width: 200px;
  }
  @include md {
    width: 200px;
    padding-top: 0.4rem;
  }
  @include lg {
    width: 200px;
    padding-top: 0.4rem;
  }
  @include max-screen {
    width: 200px;
    padding-top: 0.4rem;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.otherActions {
  @include mobile-sm {
    width: 30px;
  }
  @include mobile-md {
    width: 30px;
  }
  @include mobile-lg {
    width: 30px;
  }
  @include sm {
    width: 160px;
  }
  @include md-sm {
    width: 160px;
  }
  @include md {
    width: 160px;
  }
  @include lg {
    width: 160px;
  }
  @include max-screen {
    width: 160px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.tableRowWrapper {
  @include tableRowWrapper;

  &:hover {
    background-color: $color-gray-soft;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.tableRow {
  border-top: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  fill: #666;
  float: left;
  font-weight: 300;
  font-size: 1rem;
  line-height: 2.5;
  // height: 37px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 4px 10px;
  padding-right: 25px;
  position: relative;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: 100%;
}

.svg-icon {
  font-size: 23px;
  color: #666;
  padding-top: 3px;
}

.rowExtraInfoWrapper {
  float: left;
  padding: 4px 10px 4px 35px;
  width: calc(100% - 60px);
}

.buttonMenuWrapper {
  display: flex;
  justify-content: space-evenly;
  float: right;
  margin-right: 10px;
  margin-left: 10px;
  width: 270px;
}
