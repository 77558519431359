@import "../../../css/mixins/default_media_query.scss";
@import "../../../css/_variables.scss";

.accordionRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.accordionHeader {
  float: right;
  margin-top: 5px;
}

.rotate {
  transform: rotate(180deg);
}
