@import 'mixins/default_media_query';
@import 'variables';	


.paginate-wrapper {
  position: absolute;
    right: 0;
    line-height: 2rem;
    top: -40px;
    color: $paginate-text-color;
    font-size: $paginate-font-size;
}

.paginate-arrow{
  float: left;
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: all .3s ease;
  padding: 0;
  border: 0;
  background: transparent;
}

.paginate-arrow-left {
  @include mobile-sm {
    margin-right: 35px;
  }
  @include mobile-md {
    margin-right: 35px;
  }
  @include mobile-lg {
    margin-right: 35px;
  }
}

.paginate-arrow-left:hover::before, .paginate-arrow-left:hover::after, .paginate-arrow-right:hover::before, .paginate-arrow-right:hover::after{
  background-color: $color-blue;
}

.paginate-arrow-left::before {
  float: left;
    position: relative;
    display: block;
    content: '';
    width: 2px;
    height: 17px;
    background-color: $color-gray-icons;
    transform: translatex(12px) rotate(45deg);
}

.paginate-arrow-left::after {
  float: left;
    position: relative;
    display: block;
    content: '';
    width: 2px;
    height: 17px;
    background-color: $color-gray-icons;
    transform: translate(10px, 11px) rotate(135deg);
}

.paginate-arrow-right::before {
  float: left;
    position: relative;
    display: block;
    content: '';
    width: 2px;
    height: 17px;
    background-color: $color-gray-icons;
    transform: translatex(12px) rotate(135deg);
}

.paginate-arrow-right::after {
  float: left;
    position: relative;
    display: block;
    content: '';
    width: 2px;
    height: 17px;
    background-color: $color-gray-icons;
    transform: translate(10px, 11px) rotate(45deg);
}
