@import 'variables';
@import 'block';
@import 'detailPage';


.attendace-container {
  // float: left;
  display:inline-block;
  height: inherit;
  left: 50%;
  overflow-y: auto;
  padding: 0 10px;
  position: relative;
  transform: translateX(-50%);
  width: 100%;
}

.attendance-timer {
  font-family: $globalfont;
  position: absolute;
  right: 30px;
  top: 10px;
  width: 275px;
  z-index: 999;
}

.attendance-timer-text {
  clear: left;
  color: $sidebar-icon-color;
  // float: left;
  display:inline-block;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 305px;
}

