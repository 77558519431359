@import '../../css/mixins/default_media_query';
@import '../../css/variables';


.btn-audio-control {
  display: flex;
  flex-direction: column;
}

.btn-audio-single {
  @include sm {
    margin-right: 5px;
  }
  border: 0;
  border-radius: 100%;
  height: 50px;
  margin-right: 15px;
  overflow: hidden;
  width: 50px;
}

.btn-listen-color {
  background: $color-audio-blue;
  &:hover {
    background: $color-audio-blue-hover;
  }
}

.btn-speak-color {
  background: $color-audio-yellow;
  &:hover {
    background: $color-audio-yellow-hover;
  }
}

.btn-two-way-audio-color {
  background: $color-audio-red;
  &:hover {
    background: $color-audio-red-hover;
  }
}

.audio-status-text {
  @include mobile-sm{
    display: none;
  }
  @include mobile-md{
    display: none;
  }
  @include mobile-lg{
    display: none;
  }
  float: left;
  margin-top: 12px;
  height: 30px;
}
