@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.btn-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.btn-make-call-container {
  display: flex;
}

.make-call-btn {
  background: $btn-bg-color;;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 37px;
  padding: 0;
  margin-top: 10px;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  width: 360px;
  &:hover {
    background: $color-tecno-hover;
  }
}

.back-btn {
  background: $color-audio-red;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 37px;
  padding: 0;
  margin-top: 10px;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  width: 260px;
  &:hover {
    background: $color-audio-red-hover;
  }
}
