@import "../../../../css/mixins/reports";
@import "../../../../css/mixins/default_media_query";
@import "../../../../css/mixins/default_buttons";
@import "../../../../css/variables";

.title {
  @include report-title;
  @include mobile-sm {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-md {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-lg {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include sm {
    line-height: 1;
    margin-top: 15px;
  }
  @include md {
    margin-bottom: 0;
  }
  @include lg {
    margin-bottom: 0;
  }
  @include max-screen {
    margin-bottom: 0;
  }
  width: 100%;
}

.table {
  @include report-table;
    // @include sm {
  //   display: none;
  // }
}

.paginate {
  @include report-paginate;
}

.header {
  @include report-header;
}

.header-column {
  @include report-header-column;
  text-align: left;
  width: 100%;
}

.body {
  @include report-body;
}

.row {
  @include report-row;
  font-size: 1rem;
  line-height: 2.5;
}

.column {
  @include report-column;
}

.download {
  //@include report-download;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: $color-white;
  background-color: $btn-bg-color;
}

.subtitle {
  @include mobile-sm {
    line-height: 1;
    margin-top: 15px;
  }
  @include mobile-md {
    line-height: 1;
    margin-top: 15px;
  }
  @include mobile-lg {
    line-height: 1;
    margin-top: 15px;
  }
  color: #999;
  float: left;
  font-size: 1.1rem;
}

.total-attend {
  @include report-field;
  @include sm {
    width: 300px;
  }
  @include md {
    width: 300px;
  }
  @include lg {
    width: 300px;
  }
}

.btn-print {
  @include button;
  @include sm {
    background: $color-tecno;
    height: 37px;
    margin-top: 85px;
    width: 100%;
  }
  @include md {
    display: none;
  }
  @include lg {
    display: none;
  }
}

.rotate {
  transform: rotate(180deg);
}


.name {
  @include sm {
    width: 300px;
  }
  @include md {
    width: 300px;
  }
  @include lg {
    width: 300px;
  }
  @include max-screen {
    width: 300px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// .subtitle {
//   @include mobile-sm {
//     float: left;
//     line-height: 1;
//     margin-top: 15px;
//   }
//   @include mobile-md {
//     float: left;
//     line-height: 1;
//     margin-top: 15px;
//   }
//   @include mobile-lg {
//     float: left;
//     line-height: 1;
//     margin-top: 15px;
//   }
//   @include sm {
//     float: right;
//     line-height: 1;
//   }
//   @include md {
//     float: right;
//     line-height: 1;
//   }
//   @include lg {
//     float: right;
//     line-height: 2;
//   }
//   @include max-screen {
//     float: right;
//     line-height: 2;
//   }
//   color: #999;
//   font-size: 1.2rem;
// }

.id-header {
  @include sm {
    width: 300px;
  }
  @include md {
   width: 300px;
  }
  @include lg {
    width: 300px;
  }
  @include max-screen {
    width: 300px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.total-clients {
  @include sm {
    width: 300px;
  }
  @include md {
   width: 300px;
  }
  @include lg {
    width: 300px;
  }
  @include max-screen {
    width: 300px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
