@import '../../../../css/mixins/default_media_query';
@import '../../../../css/mixins/default_table';
@import '../../../../css/variables';

.content {
  @include mobile-sm {
    margin-top: 20px;
  }
  @include mobile-md {
    margin-top: 20px;
  }
  @include mobile-lg {
    margin-top: 20px;
  }
  @include sm {
    margin-top: 20px;
  }
  @include md {
    // margin-top: 20px;
  }
  @include lg {
    // margin-top: 10px;
  }
  @include max-screen {
    // margin-top: 10px;
  }
  width: 100%;
  float: left;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.clientLocations {
  margin-top: 20px;
  display: flex;
  // flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  .clientList {
    width: 100%;
    margin-bottom: 20px;
  }

  .mapContainer {
    width: 100%;
    height: 400px;
  }
}

.tableContent {
  @include sm {
    margin-top: 5px;
     }
  width: 45%;
  float: left;
  position: relative;
  margin-right: 15px;
}

.table {
  @include table;
  float: left;
  height: auto;
  position: relative;
  width: 100%;
}

.header {
  @include header;
}

.body {
  @include table-body;
  max-height: 555px !important;
}

.row {
  @include tableRow;
  border-bottom: 1px solid #ddd;
   &:hover {
    background-color: $color-gray-soft;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.rowHovered {
  background-color: $color-gray-soft;
}

.headerTextContainer {
  width: 270px;
}

.headerAccount {
  @include sm {
    margin-right: 10px;
  }
  @include sm-md {
    margin-right: 10px;
  }
  @include md-sm {
    margin-right: 10px;
  }
  @include md {
    margin-right: 10px;
  }
  @include lg {
    margin-right: 10px;
  }
  width: 50px;
}

.bodyTextContainer {
  width: 265px;
  float: left;
  font-size: 1rem;
  line-height: 2.5;
}

.nameTextContainer {
  @extend .bodyTextContainer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bodyAccount {
  @include md {
    width: 50px;
  }
  @include lg {
    width: 50px;
  }
  float: left;
  font-size: 1rem;
  line-height: 2.5;
  width: 50px;
}

.map-container {
  @include mobile-sm {
     height: 395px;
  }
  @include mobile-md {
    height: 395px;
  }
  @include mobile-lg {
    height: 395px;
  }
  @include sm {
    height: 395px;
  }
  @include md-sm {
    height: 495px;
  }
  width: 65%;
  height: 595px;
  margin: 0 auto;
}

.clickableRow {
  cursor: pointer;
}

.btnContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  @include sm {
    width: 50%;
  }
  @include md-sm {
    display: none;
    /*margin-top: 25px;*/
  }
  @include md {
    width: 185px;
  }
  @include lg {
    width: 185px;
    // margin-left: 2em;
  }
  width: 200px;
  background-color: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 2rem;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background 0.3s ease;
  margin-left: 1rem;
  &:hover {
    background-color: $btn-hover;
  }
}
