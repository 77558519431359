
@import 'variables';

  .contactto-login {
    background: $color-white;
    display: block;
    font-family: $globalfont;
    height: 100%;
    left: 0;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 999;
  }

  .contactto-login-content {
    margin: auto;
    padding: 8% 0 0;
    width: 314px;
  }

  .contactto-reset-container {
    height: 640px;
  }

  .login-success-reset {
    color: #15971b;
    float: left;
    font-family: Montserrat, sans-serif;
    font-size: 10px;
    margin-bottom: 10px;
    margin-top: -5px;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .contactto-login-logo {
    display: block;
    float: left;
    height: 54px;
    left: 50%;
    margin-bottom: 30px;
    position: relative;
    top: 50%;
    // transform: translateX(-50%);
    transform: translate(-50%);
    width: 300px;
  }

  .contactto-login-content-container {
    background: $color-white;
    margin: 0 auto;
    overflow: auto;
    position: relative;
    text-align: center;
    z-index: 1;

    &--hide {
      display: none;
    }
  }

  .contactto-login-content-container-logo {
    margin-bottom: 15px;
  }

  .contactto-login-reset-password {
    transform: translateX(-50%);
    color: $color-tecno;
    display: block;
    float: left;
    font-family: $globalfont;
    font-size: 22px;
    height: 33px;
    left: 50%;
    margin-bottom: 35px;
    position: relative;
    top: 50%;
    transform: translate(-50%);
    width: 300px;
  }
  

  .contactto-login-content-container-form {
    // -webkit-transition: all .2s ease;
    transition:  .2s ease-in;

    &--hide {
      display: none;
    }
  }

  .login-form-input {
    //-webkit-transition: inherit;
    background: $color-gray-input-bg;
    border: solid 1px transparent;
    box-sizing: border-box;
    color: $color-gray-input-font;
    font-family: $globalfont;
    font-size: 14px;
    height: 45px;
    outline: 0;
    padding: 15px;
    transition: .3s ease;
    width: 314px;

    &--border {
      border-bottom: solid 1px;
      height: 46px;
    }

    &--error {
      border: solid 1px $color-tomato;
    }
  }

  .contactto-login-content-container-form-button {
    //-webkit-transition: inherit;
    background: $btn-bg-color;
    border: 0;
    color: $color-white;
    cursor: pointer;
    font-family: $globalfont;
    font-size: 12px;
    height: 43px;
    margin-top: 18px;
    outline: 0;
    padding: 15px;
    text-transform: uppercase;
    transition: inherit;
    width: 314px;
    border-radius: 5px;

    &:active {
      background: $color-tecno-hover;
    }

    &:focus {
      background: $color-tecno-hover;
    }

    &:hover {
      background: $color-tecno-hover;
    }

    &--invalid {
      background: $color-gray-invalid;
      cursor: default;
    }
    &--invalid:hover {
      background: $color-gray-invalid;
    }
  }

  // .contactto-login-content-container-form-button:hover,.contactto-login-content-container-form-button:active,.contactto-login-content-container-form-button:focus {
  //   background: #284f6c;
  // }

  .contactto-login-content-container .register-form {
    display: none;
  }

  // .register-form {
  //   display: none;
  // }

  .login-error-title {
    color: $color-tomato;
    float: left;
    font-family: $globalfont;
    font-size: 10px;
    margin-bottom: 10px;
    margin-top: -5px;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .login-success {
    display: none;
    float: left;
    font-family: $globalfont;
    font-size: 18px;
    height: 0;
    line-height: 2;
    text-align: center;
    transition: height .3s ease;
  }

  .login-success-show {
    display: block;
    height: 70px;
  }

  .login-reset {
    color: $color-gray-input-font;
    cursor: pointer;
    font-family: $globalfont;
    font-size: 1rem;
    margin-top: 17px;
    opacity: 1;
    text-align: center;
    transition: .3s ease;

    &:hover {
      color: $color-blue;
    }
  }

  // .login-reset:hover {
  //   color: rgb(36, 184, 204);
  // }

  .email-sent {
    display: none;
    float: left;
    font-family: $globalfont;
    font-size: 18px;
    height: 0;
    line-height: 2;
    text-align: center;
    transition: height .3s ease;

    &-show {
      display: block;
      height: 70px;
    }
  }

  // .email-sent-show {
  //   display: block !important;
  //   height: 70px !important;
  // }

  .error-animation {
    animation-duration: .2s;
    animation-iteration-count: 2;
    animation-name: errorAnimation;
  }

  @keyframes errorAnimation {
    0% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(-5px);
    }

    75% {
      transform: translateX(5px);
    }

    100% {
      transform: translateX(0);
    }
  }

  .reset-title-bold {
    font-weight: bold;
  }

  .reset-field-text {
    font-size: 1rem;
    font-family: $globalfont;
    color:$color-gray-font;
  }

  .contactto-reset-button {
    width: 314px;
    height: 43px;
    font-family: $globalfont;
    text-transform: uppercase;
    outline: 0;
    background: $color-blue;
    margin-top: 18px;
    border: 0;
    color: $color-white;
    font-size: 12px;
    -webkit-transition: inherit;
    transition: inherit;
    cursor: pointer;
  }

  .contactto-reset-button--body {
    text-decoration: none;
  }

  .contactto-reset-button--text {
    padding-top: 15px;
  }

  .contactto-reset-error1 span{
    display: none;
  }

  .contactto-reset-error1:after {
    color: red;
    font-size: 1rem;
    content: 'Erro, insira a senha novamente.'
  }

  .contactto-reset-error2 span {
    display: none;
  }

  .contactto-reset-error2:after {
    color: red;
    font-size: 1rem;
    content: 'A senha deve conter no mínimo 8 caracteres.'
  }

  .contactto-reset-error-text {
    font-size: 11px;
  }

  .contactto-button {
    background: transparent;
    border: 0;
    outline: none;
    padding: 0;
  }
  .contactto-login-locale-container {
    margin-top: 10px;
  }

  .contactto-login-locale-button {
    background: transparent;
    border: 0;
    // color: $color-white;
    font-size: 1rem;
    font-weight: bold;
    font-family: $globalfont;
    cursor: pointer;
    position: relative;
    outline: 0;
  }

  .contactto-login-locale-chosen {
    border: 2px;
    border-color: $color-gray-form;
    // border-color: $color-white;
    border-radius: 5px;
    border-style: solid;
  }
