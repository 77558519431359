@import '../../../css/variables';
@import "../../../css/mixins/default_buttons";
@import "../../../css/mixins/default_media_query";

.btn {
  @include btn;
  padding: 0.5rem;
  text-decoration: none;
 }

// .report-btn{

// }

.wrapper {
  border: 0;
  border-radius: 5px;
  color: $bg-color;
  cursor: pointer;
  float: left;
  font-size: 14px;
  margin-left: 50%;
  margin-top: 9px;
  padding: 7px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translateX(-50%);
  transition: 0.3s ease;
  width: 200px;

  &:hover {
    transform: translateX(-50%) scale(1.1);
  }
}
