@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.detail-wrapper {
  float: left;
  width: 100%;
}

.left-wrapper {
  float: left;
  width: 70%;
}

.right-wrapper {
  float: right;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client-header {
  color: $color-tecno;
  float: left;
  font-size: 1.1rem;
  margin-bottom: 20px;
  @include mobile-sm {
    margin-left: 0;
    text-align: left;
  }
  @include mobile-md {
    margin-left: 0;
    text-align: left;
  }
  @include mobile-lg {
    margin-left: 0;
    text-align: left;
  }
  @include sm {
    margin-left: 0;
    text-align: left;
  }
}

.client-header-left {
  float: left;
  text-align: right;
  width: 25%;
}

.client-header-right {
  float: left;
  text-align: left;
  padding: 0 10px;
  width: 75%;
}

.alarm-header {
  float: left;
  width: 100%;
  font-size: 0.7rem;
  font-weight: bold;
  color: $color-tecno;
  // text-align: center;
  @include mobile-sm {
    margin-top: 1rem;
    text-align: left;
    padding: 0 10px;
  }
  @include mobile-md {
    margin-top: 1rem;
    text-align: left;
    padding: 0 10px;
  }
  @include mobile-lg {
    margin-top: 1rem;
    text-align: left;
    padding: 0 10px;
  }
  @include sm {
    margin-top: 1rem;
    text-align: left;
    padding: 0 10px;
  }
}

.label {
  box-sizing: border-box;
  color: $color-gray;
  float: left;
  font-size: 0.7em;
  height: 26px;
  text-transform: uppercase;
  position: relative;
  text-align: right;
  height: auto;
  width: 25%;
  font-weight: bold;
  padding-top: 0.5rem;
  @include mobile-sm {
    width: 100%;
    text-align: left;
    height: auto;
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
    text-align: left;
    height: auto;
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
    text-align: left;
    height: auto;
    width: 100%;
  }
  @include sm {
    width: 100%;
    text-align: left;
    height: auto;
    width: 100%;
  }
  @include md-sm {
    width: 100%;
    text-align: left;
    height: auto;
    width: 100%;
  }
}

.value {
  float: left;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 0.9rem;
  padding-top: 0.6rem;
  margin-left: 1rem;
  line-height: 1.2rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.alarm-row {
  float: left;
   width: 100%;
  //  height: 2.5rem;
   vertical-align: middle;
}
