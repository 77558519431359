
@media screen {
    .hide-after-print {
        display: none;
    }
}

@media print {
  * {
    background: transparent !important;
        text-shadow: none !important;
        filter:none !important;
  }

  body {
        margin: 0;
        padding: 0;
        line-height: 1.4em;
    }

    @page {
      size: A4 landscape;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
        margin: 10mm 10mm 10mm 10mm;
    }

    .no-print, nav, footer, video, audio {
  //   .no-print, nav, footer, video, audio, object, embed, div {
        display: none;
    }

    .print {
        display: block !important;
        overflow: visible !important;
    }

    .page-break {
        page-break-inside: avoid;
    }

    .contactto-middle-content {
        zoom: 70%;
    }

    div {
        float: none !important;
    }

    .print-logo {
        display: fixed;
        float: left;
        zoom: 70%;
    }

    .app, .app__container, .middle {
        height: auto !important;
        overflow: visible !important;
    }
}
