@import '../../../css/mixins/default_media_query.scss';
@import '../../../css/variables';
:root {
  --sidebar-width: 50;
  --padding: 10;
}

.trial-wrapper {
  background-color: $color-tecno;
  display: block;
  height: 55px;
  position: absolute;
  width: 100%;
  z-index: 996;
}

.content {
  @include mobile-sm {
    margin-left: 1%;
    transform: translateY(8px);;
    visibility: hidden;
  }
  @include mobile-md {
    margin-left: 2%;
    transform: translateY(8px);;
    visibility: hidden;
  }
  @include mobile-lg {
    margin-left: 2%;
    transform: translateY(8px);;
    visibility: hidden;
  }
  @include sm {
    margin-left: 2%;
    transform: translateY(8px);;
  }
  @include md-sm {
    margin-left: 3%;
    transform: translateY(8px);;
  }
  @include md {
    margin-left: 3%;
    transform: translateY(8px);;
  }
  float: left;
  margin-left: 2%;
  transform: translateY(8px);
  position: relative;
  // top: 30%;
}

.trial {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include lg{
    top: 0.5rem;
  }
  @include max-screen{
    top: 0.5rem;
  }
  color: $color-white;
  float: left;
  font-weight: 700;
  font-family: $globalfont;
  position: relative;
  
}

.btn {
  @include mobile-sm {
    margin-left: 3rem;
    width: 75px;
    margin-top: -10px;
  }
  @include mobile-md {
    margin-left: 3rem;
    width: 75px;
    margin-top: -10px;
  }
  @include mobile-lg {
    width: 75px;
    margin-left: 4rem;
    margin-top: -10px;
  }
  @include sm {
    width: 75px;
    margin-left: 4rem;
    margin-top: -10px;
  }
  @include md-sm {
    margin-left: 2rem;
    width: 110px;
  }
  @include md {
    margin-left: 2rem;
    width: 110px;
  }
  @include lg {
    margin-left: 2rem;
    width: 110px;
    bottom:0px;
    top:0px;
  }
  @include max-screen {
    margin-left: 2rem;
    width: 110px;
    bottom:0px;
    top:0px;
  }
  background: #21b31a;
  border: 0;
  border-radius: 5px;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 37px;
  font-weight: 700;
  position: relative;
  text-align: center;
  top: -40%;
  transition: background .3s ease;

  &:hover {
    background: #0f5d0b;
  }
}
