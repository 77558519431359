@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.comment-wrapper {
  float: left;
  width: 100%;
  font-size: 1rem;
}

.comment {
  margin: 10px;
  white-space: pre-line;
  word-wrap: break-word;
  @include mobile-sm {
    font-size: 1rem;
  }
  @include mobile-md {
    font-size: 1rem;
  }
  @include mobile-lg {
    font-size: 1rem;
  }
  @include sm {
    font-size: 1rem;
  }
  @include md-sm {
    font-size: 1rem;
  }
}

.text-area {
  @include mobile-sm {
    height: 150px;
    width: 98%;
  }
  @include mobile-md {
    height: 150px;
    width: 98%;
  }
  @include mobile-lg {
    height: 150px;
    width: 98%;
  }
  @include sm {
    height: 200px;
    width: 99%;
  }
  @include md-sm {
    height: 334px;
    width: 97%;
  }
  @include md {
    height: 334px;
    width: 97%;
  }
  @include lg {
    width: 98.5%;
    height: 330px;
  }
  @include max-screen {
    width: 100%;
    height: 330px;
  }
  height: 314px;
}

.comment-btn-save {
  border: 0;
  border-radius: 5px;
  background: $color-green;
  font-weight: bold;
  color: $color-white;
  float: right;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  margin-left: 5px;
  width: 35px;
  &:hover {
    background: $color-green-hover;
  }
}

.comment-btn-cancel {
  border: 0;
  border-radius: 5px;
  background: $color-red;
  font-weight: bold;
  color: $color-white;
  float: right;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  margin-right: 5px;
  width: 35px;
  &:hover {
    background: $color-red-hover;
  }
}

.comment-btn-edit {
  border: 0;
  border-radius: 5px;
  background: $btn-bg-color;
  font-weight: bold;
  color: $color-white;
  float: right;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  width: 35px;
  &:hover {
    background-color: $btn-hover;
  }
}
