@import 'mixins/default_media_query';
@import 'mixins/default_title';
@import 'variables';

.wrapper {
  float: left;
  height: inherit;
  left: 50%;
  overflow-y: auto;
  padding: 0 10px;
  position: relative;
  transform: translateX(-50%);
}

.title {
  @include general-title;
  width: 100%;
}

.tecno {
  color: $color-tecno;
}

.gray {
  color: $color-gray;
}

.container {
  float: left;
  position: relative;
  width: 100%;
}

.container-title {
    border-bottom: solid 1px #4196cd;
    box-sizing: border-box;
    color: $color-grafite;
    float: left;
    font-size: 1rem;
    // height: 1.3rem;
    position: relative;
    width: 100%;
    text-transform: uppercase;
}

.container-content {
  float: left;
  padding: 35px 0;
  position: relative;
  width: 100%;
}

.row {
  float: left;
  font-size: 1rem;
  height: 34px;
  margin: 5px 0;
  position: relative;

  &--50 {
    width: 50%;
  }

  &--100 {
    width: 100%;
  }
}

.field {
  @include sm {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
  }
  box-sizing: border-box;
  color: $color-gray;
  float: left;
  font-size: 1rem;
  height: 26px;
  line-height: 2;
  padding: 0 10px;
  position: relative;
  text-align: right;
  width: 148px;

  &--130 {
    width: 130;
  }
}

.field130 {
  @include sm {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;
  }
  box-sizing: border-box;
  color: $color-gray;
  float: left;
  font-size: 1rem;
  height: 26px;
  line-height: 2;
  padding: 0 10px;
  position: relative;
  text-align: right;
  width: 130px;
}

.value {
  // @include sm {
  //   width: 50%;
  // }
    /* float: left; */
    font-size: 1rem;
    height: 2rem;
    /* line-height: 2; */
    position: relative;
    width: auto;
    left: 2rem;
}

.value130 {
  @include sm {
    width: 50%;
  }
  float: left;
  font-size: 1rem;
  height: 26px;
  line-height: 2;
  position: relative;
  width: calc(100% - 130px);
}

.textarea {
  box-sizing: border-box;
  float: left;
  position: relative;
  resize: none;
  width: 100%;
}
