@import 'react-datepicker/dist/react-datepicker';
@import 'mixins/default_media_query';
@import 'variables';

.middle {
  @include mobile-sm {
    padding: 10px 10px 10px 60px;
    padding-top: 70px;
    width: 100%;
  }
  @include mobile-md {
    padding: 10px 10px 10px 60px;
    padding-top: 70px;
    width: 100%;
  }
  @include mobile-lg {
    padding: 10px 10px 10px 60px;
    padding-top: 70px;
    width: 100%;
  }
  @include sm {
    padding: 10px 10px 10px 60px;
    padding-top: 70px;
    width: 100%;
  }
  @include md-sm {
    // padding-left: 50px;
    width: 100%;
    padding-left: 50px;
  }
  @include md {
    // padding-left: 50px;
    width: 100%;
    padding-left: 50px;
  }
  @include lg {
    // padding-left: 50px;
    position: absolute;
    width: 100%;
  }
  @include max-screen {
    // padding-left: 50px;
    position: absolute;
    width: 100%;
  }
  box-sizing: border-box;
  display: block;
  float: left;
  height: 100%;
  padding-top: 55px;
  position: relative;
  z-index: -999;
  overflow-x: hidden;
}

.middle-with-chat {
  width: 80%;
}

.contactto-middle-content {
  @include mobile-sm {
    height: 100%;
    padding: 0;
    width: 100%;
  }
  @include mobile-md {
    height: 100%;
    padding: 0;
    width: 100%;
  }
  @include mobile-lg {
    height: 100%;
    padding: 0;
    width: 100%;
  }
  @include sm {
    height: 100%;
    padding: 0;
    width: 100%;
  }
  @include md-sm {
    height: 100%;
    padding: 25px 25px 0 25px;
    width: 100%;
  }
  @include md {
    height: 100%;
    padding: 25px 25px 0 25px;
    width: 100%;
  }
  @include lg {
    height: 100%;
    margin-left: 50%;
    padding: 15px 15px 0 75px;
    transform: translateX(-50%);
    width: 100%;
  }
  @include max-screen {
    height: 100%;
    margin-left: 50%;
    padding: 15px 15px 0 75px;
    transform: translateX(-50%);
    width: 100%;
  }
  box-sizing: border-box;
  font-family: $globalfont;
  overflow-y: auto;
  overflow-x: hidden;
}

.contactto-nav-wrapper {
  width: 100%;
  float: left;
  margin-bottom: 65px;
}

.contactto-nav-tab {
  @include mobile-sm {
    height: auto;
    padding: 0;
    width: 100%;
    font-size: 0.7rem;
  }
  @include mobile-md {
    height: auto;
    padding: 0;
    width: 100%;
    font-size: 0.7rem;
  }
  @include mobile-lg {
    height: auto;
    padding: 0;
    width: 100%;
    font-size: 0.7rem;
  }
  @include sm {
    height: auto;
    padding: 0;
    width: 100%;
    font-size: 0.9rem;
  }
  @include md-sm {
    height: auto;
    padding: 25px 25px 0 25px;
    width: 100%;
    font-size: 0.9rem;
  }
  @include md {
    height: auto;
    padding: 25px 25px 0 25px;
    width: 100%;
    font-size: 0.9rem;
  }
  @include lg {
    height: auto;
    margin-left: 50%;
    padding: 25px 25px 0 75px;
    transform: translateX(-50%);
    width: 100%;
    font-size: 1rem;
  }
  @include max-screen {
    height: auto;
    margin-left: 50%;
    padding: 25px 25px 0 75px;
    transform: translateX(-50%);
    width: 100%;
    font-size: 1rem;
  }
  box-sizing: border-box;
  font-family: $globalfont;
  overflow-y: auto;
  overflow: hidden;
  
}

.update-info-notification {
  background: #73ce91;
  border-radius: 5px;
  color: $color-gray-icons;
  font-family: $globalfont;
  font-size: 18px;
  opacity: 0;
  padding: 10px;
  position: absolute;
  right: 60px;
  top: 100px;
  transition: opacity 0.3s ease;
  width: auto;
  z-index: 999;

  &--active {
    animation-duration: 1.9s;
    animation-name: notificationInfo;
  }
}
@keyframes notificationInfo {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.contactto-middle-content--attends {
  width: 810px !important;
}

.contactto-middle-content-title h3 {
  font-size: 16px;
  font-family: $globalfont;
  width: 100%;
  text-align: center;
  font-weight: 300;
  color:$color-gray-input-font
  // margin-bottom: 20px;
}

// Custom classes global

.hrcolor {
  background-color:$color-system;
}

.paddingtitle {
  padding-top: 1em;
  display: inline-block;
  font-size: 1.1rem;
  text-align: left;
}


// Custom classes bootstrap & general

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.col-sm-12 {
  padding-right: 0px;
  padding-left: 0px;
}
.button {
  &:focus {
    outline: none;
  }
}
a {
  &:focus {
    outline: none;
  }
}

::-webkit-scrollbar {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md-sm {
    display: none;
  }
  @include md {
    // display: none;
  }
  width: 15px;
  background-color: $btn-hover;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $color-blue;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $btn-hover;
}

.Select, .Select div, .Select input, .Select span {
  border-radius: 5px;
}

.DateRangePickerInput, .DateInput {
font-size: 1rem;
border-radius: 5px;

}

input[type=checkbox], input[type=radio] {
width: 2rem;
height: 2rem;
vertical-align: middle;
}

.pagetitle {
color: $color-grafite;
  float: left;
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
  width: auto;
  margin-top: 0.3rem;
}
hr{
margin-top: 0.3rem;
margin-bottom: 1rem;
height: 1px;
}

.text-muted{
color: $color-gray;
font-size: 0.8rem;
text-transform: uppercase;
font-weight: bold;
}

.Select-menu-outer{top: auto; bottom: 100%}


a:hover {
text-decoration: none;
}

.datepicker-custom {
  width: 330px;
}
