@import '../../../css/variables';
@import '../../../css/mixins/default_table';
@import '../../../css/mixins/default_media_query';
@import '../../../css/mixins/default_title';
@import "../../../css/mixins/default_buttons";
@import "../../../css/paginate";


.title {
  @include general-title;
  @include sm {
    width:100%; 
  }
  @include md-sm {
    
    width: 100%;
  }
  // @include md {
  //   width: 100%;
  // }
}

.subtitle {
  @include subtitle;
 @include sm {
    margin-bottom: 0;
    width:100%; 
  }
  float:none;
  line-height:unset;
}

.paginate {
  @include paginate;
}

.table {
  @include wrapper;
  margin-top: 5px;
}

.header {
  @include header;
}

.header-column {
  @include header-column;
}

.row {
  @include rowtb;
}

.column {
  @include column;
}

.removed {
  @include removed;
}

.actions {
  @include actions;
}

.icons {
  @include icons;
  margin-top: 0.3rem;
}

.add {
  @include add;
}

.btn-add {
  @include btn-add;
  @include sm {
    float: left;
    margin-top: 0;
    width:100%; 
  }
  @include md-sm {
    float: left;
    margin-top: 1rem;
  }
  @include md {
    float: left;
    margin-left: 1rem;
  }
  @include lg {
    float: left;
    margin-left: 1rem;
   
  }
  @include max-screen {
    float: left;
    margin-left: 1rem;
  }

}

.btn{
  @include btn;
 }

.rotate {
  transform: rotate(180deg);
}


// .btn-add {
//   @include mobile-sm {
//     width: 100%;
//     margin-bottom: 2%;
//     margin-left: 0px !important;
//   }
//   @include mobile-md {
//     width: 100%;
//     margin-bottom: 2%;
//     margin-left: 0px !important;
//   }
//   @include mobile-lg {
//     width: 100%;
//     margin-bottom: 2%;
//     margin-left: 0px !important;
//   }
//   @include sm {
//     width: 30%;
//     margin-right: 5px;
//   }
//   @include md {
//     width: 173px;
//   }
//   @include lg {
//     width: 173px;
//   }
//   @include max-screen {
//     width: 173px;
//   }
//   background: $btn-bg-color;
//   border: 0;
//   border-radius: 5px;
//   clear: left;
//   color: $color-white;
//   cursor: pointer;
//   float: left;
//   font-family: $globalfont;
//   font-size: 1rem;
//   height: 37px;
//   padding: 0;
//   position: relative;
//   text-align: center;
//   transition: background .3s ease;
//   &:hover {
//     background-color: #284f6c;
//   }
// }

.btn-content {
  @include mobile-sm {
    height: auto;
    width: 100%;
  }
  @include mobile-md {
    height: auto;
    width: 100%;
  }
  @include mobile-lg {
    height: auto;
    width: 100%;
  }
  @include sm {
    height: auto;
    width: 100%;
    // margin-bottom: 5px;
    // width: 369px;
  }
  @include md {
    width: 530px;
  }
  @include lg {
    width: 530px;
  }
  @include max-screen {
    width: 530px;
  }
  float: left;
  height: 37px;
}


.company {
  @include mobile-sm {
    width: 100px;
  }
  @include mobile-md {
    width: 100px;
  }
  @include mobile-lg {
    width: 100px;
  }
  @include sm {
    width: 135px;
  }
  @include md {
    width: 130px;
  }
  @include lg {
    width: 250px;
  }
  @include max-screen {
    width: 350px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.companyHeader {
  @extend .company;
  @include mobile-sm {
    margin-right: 30px;
    width: 100px;
  }
  @include mobile-md {
    margin-right: 30px;
    width: 100px;
  }
  @include mobile-lg {
    margin-right: 30px;
    width: 100px;
  }
}

.zone {
  @include mobile-sm {
    width: 75px;
  }
  @include mobile-md {
    width: 75px;
  }
  @include mobile-lg {
    width: 135px;
  }
  @include sm {
    width: 135px;
  }
  @include md {
    width: 150px;
  }
  @include lg {
    width: 350px;
  }
  @include max-screen {
    width: 550px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  @include mobile-sm {
    width: 125px;
  }
  @include mobile-md {
    width: 125px;
  }
  @include mobile-lg {
    width: 135px;
  }
  @include sm {
    width: 135px;
  }
  @include md {
    width: 150px;
  }
  @include lg {
    width: 350px;
  }
  @include max-screen {
    width: 550px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.subtitleContainer {
  @include mobile-sm {
    width: 57%;
  }
  @include mobile-md {
    width: 60%;
  }
  @include mobile-lg {
    width: 65%;
  }
  @include sm {
    width: 75%;
  }
  @include md {
    width: 80%;
  }
  @include lg {
    width: 85%;
  }
  @include max-screen {
    width: 90%;
  }
  float: right;
  height: auto;
  margin-left: 10px;
  margin-top: 22px;
  position: relative;
}

.subtitle {
  @include mobile-sm {
    float: left;
    margin-top: 15px;
  }
 @include mobile-md {
    float: left;
    margin-top: 15px;
  }
  @include mobile-lg {
    float: left;
    margin-top: 15px;
  }
  @include sm {
    float: right;
  }
  @include md {
    float: right;
  }
  @include lg {
    float: right;
  }
  @include max-screen {
    float: right;

  }
  color: #999;
  font-size: 1.1rem;
}

// .subtitle {
//   color: #999;
//   float: left;
//   font-size: 17px;
//   height: auto;
//   position: relative;
//   line-height: 1.6;
// }

.emptyRow {
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 0;
  box-sizing: border-box;
  color: #333;
  display: flex;
  fill: #666;
  float: left;
  font-weight: 300;
  height: calc(var(37) * 1px);
  justify-content: space-between;
  padding: 4px 10px;
  position: relative;
  transition: .3s ease;
  width: 100%;
  &:hover {
    background-color: $color-gray-soft;
  }
}


.table-body {
  @include mobile-sm-height {
    min-height: 100px;
    max-height: 165px;
  }
  @include mobile-md-height {
    min-height: 100px;
    max-height: calc(90% - 10px);
  }
  @include mobile-lg-height {
    min-height: 100px;
    max-height: calc(100% - 10px);
  }
  @include sm-height {
    min-height: 100px;
    max-height: 530px;
  }
  @include md-height {
    min-height: 100px;
    max-height: 530px;
  }
  @include lg-height {
    min-height: 100px;
    max-height: 650px;
  }
  @include max-screen-height {
    min-height: 100px;
    max-height: 780px;
  }
  box-sizing: border-box;
  float: left;
  overflow-y: overlay;
  min-height: 100px;
  max-height: 780px;
  width: 100%;
  padding: 0px 0px 10px 0px;
}

.toggle-container { 
  @include mobile-sm {
    right: 55%;
  }
  @include mobile-md {
    right: 56%;
  }
  @include mobile-lg {
    right: 56%;
  }
  @include sm {
    right: 0;
    position: absolute;
    top: 70px;
    width: 130px;
  }
  @include md {
    right: 0;
    bottom: 40px;
    width: 130px;
  }
  @include lg {
    right: 10%;
  }
  @include max-screen {
    right: 10%;
  }
  position: relative;
  right: 10%;
  float: right;
}

.logsIcon {
  font-size: 26px;
  height: 26px;
  width: 26px;
}

.paginate-wrapper {
  position: absolute;
  right: 0;
  line-height: 25px;
  top: -37px;
  color: #999;
  font-size: 1rem;
}

.account {
  @include mobile-sm {
    width: 55px;
  }
  @include mobile-md {
    width: 55px;
  }
  @include mobile-lg {
    width: 55px;
  }
  @include sm {
    width: 55px;
  }
  @include md {
    width: 55px;
  }
  @include lg {
    width: 100px;
  }
  @include max-screen {
    width: 100px;
  }
}

.btn-import{
  @include mobile-sm {
    display: none
  }
  @include mobile-md {
    display: none
  }
  @include mobile-lg {
    display: none
  }
  @include sm {
    display: none
  }
}


.trackerActions {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 65px;
  }
  @include md {
    width: 65px;
  }
  @include lg {
    width: 65px;
  }
  @include max-screen {
    width: 65px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.toggleButtonWrapper {
  @include mobile-sm {
    text-align: center;
    padding: 4px;
    width: 30px;
  }
  @include mobile-md {
    text-align: center;
    padding: 4px;
    width: 30px;
  }
  @include mobile-lg {
    text-align: center;
    padding: 4px;
    width: 30px;
  }
}

.toggleButton {
  background: $color-tecno;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1.5rem;
  height: 30px;
  width: 30px;
  line-height: 30px;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  &:hover {
    background-color: $btn-hover;
  }
}

.adminActions {
  @include mobile-sm {
    width: 30px;
  }
  @include mobile-md {
    width: 30px;
  }
  @include mobile-lg {
    width: 30px;
  }
  @include sm {
    width: 200px;
  }
  @include md {
    width: 200px;
  }
  @include lg {
    width: 200px;
  }
  @include max-screen {
    width: 200px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.otherActions {
  @include mobile-sm {
    width: 30px;
  }
  @include mobile-md {
    width: 30px;
  }
  @include mobile-lg {
    width: 30px;
  }
  @include sm {
    width: 160px;
  }
  @include md {
    width: 160px;
  }
  @include lg {
    width: 160px;
  }
  @include max-screen {
    width: 160px;
  }
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.tableRowWrapper {
  border: 1px solid #ddd;
  border-top: 0;
  transition: .3s ease;
  font-weight: 300;
  font-size: 1rem;
  width: 99.2%;
  height:3rem;

  &:hover {
    background-color: $color-gray-soft;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.tableRow {
  border-top: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  fill: #666;
  float: left;
  font-weight: 300;
  font-size: 1rem;
  // line-height: 2.5;
  // height: 37px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 4px 10px;
  padding-right: 25px;
  position: relative;
  -webkit-transition: .3s ease;
  transition: .3s ease;
  width: 100%;
}

.rowExtraInfoWrapper {
  float: left;
  padding: 4px 10px 4px 35px;
  width: calc(100% - 60px);
}

.buttonMenuWrapper {
  display: flex;
  justify-content: space-evenly;
  float: left;
  margin-right: 10px;
  margin-left: 10px;
  width: 270px;
}

.mobileTable {
  @include mobile-sm {
    height: 63%;
  }
  @include mobile-md {
    height: 63%;
  }
  @include mobile-lg {
    height: 63%;
  }
}
