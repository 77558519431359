@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.container {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
}

.label {
  color: #b4b4b4;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: bold;
  height: auto;
}

.client-info,
.call-info,
.contact-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.client-container,
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.softphone-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.separator {
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0;
}

.client-title {
  color: #00b8cb;
  font-size: 0.7rem;
  font-weight: bold;
}

.contact-title {
  margin-left: 15px;
  font-weight: bold;
  color: #00b8cb;
  font-size: 0.7rem;
  font-weight: bold;
}

.select-btn {
  background: $btn-bg-color;;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 37px;
  padding: 0;
  margin-top: 10px;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  width: 160px;
  &:hover {
    background: $color-tecno-hover;
  }
}

.extension-select {
  margin-left: 10px;
  padding: .375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
}
