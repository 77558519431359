@import 'default_media_query';
@import '../variables';

@mixin table {
  margin-top: 5px;
  @include mobile-sm {
    height: calc(100% - 32px);
  }
  @include mobile-md {
    height: calc(100% - 32px);
  }
  @include mobile-lg {
    height: calc(100% - 32px);
  }
  @include sm {
    height: calc(100% - 32px);
  }
  @include md-sm {
    height: calc(100% - 25px);
  }
  @include md {
    height: calc(100% - 25px);
  }
  @include lg {
    height: calc(100% - 25px);
  }
  @include max-screen {
    height: calc(100% - 25px);
  }
}

@mixin table-body {
  @include mobile-sm-height {
    min-height: 100px;
    max-height: calc(100% - 90px);
  }
  @include mobile-md-height {
    max-height: calc(100% - 90px);
  }
  @include mobile-lg-height {
    max-height: calc(100% - 30px);
  }
  @include sm-height {
    max-height: calc(100% - 30px);
  }
  @include md-height {
    max-height: calc(100% - 90px);
  }
  @include lg-height {
    height: calc(100% - 90px);
  }
  @include max-screen-height {
    height: calc(100% - 30px);
  }
  box-sizing: border-box;
  float: left;
  overflow-y: overlay;
  min-height: 100px;
  width: 100%;
  padding: 0px 0px 10px 0px;
  height: calc(100% - 90px);
}


@mixin tableRowWrapper {
  border-bottom: 1px solid $row-border-color;
  border-top: 0;
  transition: .3s ease;
  font-weight: 300;
  font-size: 1rem;
  height: 3rem;
  width: 99.2%;

  &:hover {
    background-color: $color-gray-input-bg;
  }

  &:last-child {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

@mixin tableRow {
  border-top: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: $color-black-light;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;  
  fill: #666;
  float: left;
  font-weight: 300;
  font-size: 1rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px;
  padding-right: 25px;
  position: relative;
  -webkit-transition: .3s ease;
  transition: .3s ease;
  width: 100%;
  height: 3rem;
}
  
@mixin wrapper {
    float: left;
    height: auto;
    position: relative;
    width: 100%;
  }
  
@mixin title {
    background: $title-bg;
    box-sizing: border-box;
    color: $title-color;
    font-size: 1.1rem;
    font-weight: 300;
    height: 30px;
    padding: 7px 15px;
  }
  
@mixin subtitle {
    clear: left;
    float: left;
    font-size: 0.9rem;
    margin-bottom: 20px;
    position: relative;
  }
  
@mixin header {
   // background-color: $header-bg;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    box-sizing: border-box;
    display: flex;
    font-size: 0.7rem;
    font-weight: 700;
    height: calc(#{$row-height} * 1px);
    justify-content: space-between;
    line-height: 2.5;
    padding: 3px 0px 0px 10px;
    width: 100%;
    border-bottom: 3px solid $color-tecno;
    text-transform: uppercase;
  }
  
@mixin header-column {
    background: transparent;
    border: 0;
    color: #333;
    cursor: pointer;
    float: left;
    font-size: 0.7rem;
    font-weight: 700;
    padding: 0;
    position: relative;
    text-transform: uppercase;
  }
  
@mixin body {
    float: left;
    height: calc(100% - calc(#{$row-height} * 1px));
    position: relative;
    width: 100%;
  }
  
@mixin rowtb {
    border-bottom: 1px solid $row-border-color;
    border-top: 0;
    box-sizing: border-box;
    color: $row-color;
    display: flex;
    fill: $row-fill-color;
    float: left;
    font-weight: 300;
    height: calc(#{$row-height} * 1px);
    justify-content: space-between;
    padding: 4px 10px;
    position: relative;
    transition: .3s ease;
    width: 100%;
  
    &:hover {
      background-color: $color-gray-input-bg;
    }
  
    &:last-child {
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
    }
  }
  
@mixin removed {
    border-bottom: 0;
    border-color: $removed-color !important;
    border-top: solid 1px;
    color: $removed-color !important;;
    fill: $removed-color !important;;
  
    &:last-child {
      border-bottom: solid 1px;
    }
  }
  
@mixin column {
    color: inherit;
    fill: inherit;
    float: left;
    font-size: 1rem;
    line-height: 2.5;
    position: relative;
    transition: .5s ease;
  }
  
@mixin actions {
    color: inherit;
    cursor: pointer;
    fill: inherit;
    float: left;
    position: relative;
  
    &:hover {
      fill: $actions-fill-color;
    }
  }
  
@mixin icons {
    cursor: pointer;
    // fill: inherit;
    transition: fill .3s ease;
  }
  
@mixin paginate {
    color: $paginate-color;
    font-size: 0.8rem;
    line-height: 2rem;
    position: absolute;
    right: 0;
    top: -30px;
  }
  
@mixin field {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
@mixin add {
    background-color: $download-bg;
    border-radius: 10px;
    clear: left;
    font-size: calc(#{$add-font-size} * 1px);
    height: calc(#{$add-height} * 1px);
    line-height: calc(#{$add-height} / #{$add-font-size});
    padding: 0 15px;
    text-decoration: none;
      &:hover {
      background-color: $download-bg-hover;
    }
  }

@mixin download {
    background-color: $download-bg;
    border-radius: 10px;
    display: inline;
    height: 40px;
    width: 45px;
    &:hover {
      background-color: $download-bg-hover;
  
      // &::after {
      //   background: #000;
      //   border-radius: 5px;
      //   bottom: 47px;
      //   color: $color-white;
      //   content: attr(title);
      //   left: 0;
      //   opacity: .8;
      //   padding: 5px 15px;
      //   position: absolute;
      //   width: 80px;
      //   z-index: 98;
      // }
  
      // &::before {
      //   border: solid;
      //   border-color: #333 transparent;
      //   border-width: 6px 6px 0;
      //   bottom: 41px;
      //   content: '';
      //   left: 16px;
      //   position: absolute;
      //   z-index: 99;
      // }
    }
  }


  