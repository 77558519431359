//To find the front-end elements that are using these variables, do a search, with the variable name throughout the SCSS code.
// Some variables have the same values (i.e.: $color-tecno: #00b8cb | $icon-standard-color: #00b8cb). 
//This is not a redundancy, the purpose of it is use the variable with a suitable name, to change the layout colors and other attributes easily.

//General
$bg-color-hover: #d6d6d6;
$bg-color: #f7f7f7; // general background color
$block-title-color: #4196CD;
$btn-bg-color: #4196CD; //button background color
$btn-color: #fff; // button text color
$btn-hover:#284f6c; // button backgound hover color
$color-audio-blue-hover: #295173;
$color-audio-blue: #57a0dd;
$color-audio-red-hover: #750404;
$color-audio-red: #db0707;
$color-audio-yellow-hover: #a08d1a;
$color-audio-yellow: #e2c729;
$color-black-light: #333; // 
$color-black: #000; // General black color
$color-blue: #4196CD;
$color-blue-hover: #265b7e;
$color-deleted-row: #ff6347;
$color-grafite: #415057; //main container/ page titles
$color-gray-disable: #c7c7c7;
$color-gray-font: #999;
$color-gray-form: #808285;
$color-gray-icons: #85878A;
$color-gray-input-bg: #efefef;
$color-gray-input-font: #808285;
$color-gray-input: #ccc;
$color-gray-invalid: #ddd;
$color-gray-soft: #efefef;
$color-gray-table: #ddd;
$color-gray-title: #999;
$color-gray-hover: #717171;
$color-gray: #b4b4b4;
$color-green-hover: #284f6c;
$color-green: #6aca6b;
$color-odd: #cbf1f4;
$color-orange-hover: #b36e00;
$color-orange: #ea9103;
$color-red-hover: #650205;
$color-red: #980000;
$color-system: #438bc7;
$color-tecno-hover:#284f6c;
$color-tecno: #00b8cb;
$color-title-form:#4196CD;
$color-tomato: #ff6347;
$color-white: #fff;
$conf-standard-color: #0d7380;
$done-table-color: #00bf81;
$emergency-table-color: #ff6347;
$emergency-table-width: 175px;
$icon-bg-color-hover: #555;
$icon-color-hover: #fff;
$icon-standard-color: #00b8cb;
$icon-white: #fff;
$info-color: #333;
$input-border: #ccc;
$notification-background: rgba(165, 234, 239, 0.9);
$phone-table-color: rgb(0, 183, 206);
$priority-hight-hover: #a50505;
$priority-hight: #ec0900;
$priority-low-hover: #057532;
$priority-low: #0da950;
$priority-medium-hover: #bf9202;
$priority-medium: #f8c402;
$priority-system-hover: #095289;
$priority-system: #0a6cc1;
$select-border-color: #ccc;
$sidebar-background-color-hover: #284f6c;
$sidebar-background-color: #4196CD;
$sidebar-border-color: #4196DC;
$sidebar-icon-color: #fff;
$text-color: #888;
$tooltip-bg-color: #000;
$tooltip-border-color: #333;
$tooltip-border: #333;
$tooltip-font-color: #fff;
$wrapper-border-color: #efefef;


//Fonts
$globalfont: Montserrat,sans-serif; // See 

//General Measures
$border-height: 7px;
$border-radius: 5px;
$field-width: 50%;
$height: 32px;
$height2: 2rem;
$heightform: 26px;
$heightformpage: 38px;
$padding-icon-value: 3px;
$table-attend-width: 600px;

//Headers
$sm-header-width: 110px;
$md-lg-header-width: 160px;

//Paginate
$paginate-font-size: 0.8rem;
$paginate-text-color: #999;

// Search color variables
$button-background-active-color: #fff;
$button-background-color: #efefef;
$button-background-disable-color: #f5f5f5;
$button-border-color: #ddd;
$button-text-color-hover: #00b8cb;
$button-text-color: #999;
$element-main-color: #000;
$element-text-color: #b4b4b4;

//SVG sizes
$svg_size: 36px;
$down_size: 30px;
$svg_icons: 66px;

//Table
$actions-fill-color: #00b8cb;
$add-bg: #00b8cb;
$add-height: 37px;
$add-font-size: 1;
$download-bg: #00b8cb;
$download-bg-hover: #284f6c;
$header-bg: #ddd;
$paginate-color: #999;
$row-height: 37px;
$radius: 5px;
$row-border-color: #ddd;
$row-color: #333;
$row-fill-color: #666;
$removed-color: #ff6347;
$title-bg: #00b8cb;
$title-color: #fff;

//Transitions parameters
$transition-time: .3s;


