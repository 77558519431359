@import '../attends.scss';

.wrapper {
  @include attend-wrapper
}

.title {
  @include attend-title;
  margin-top: 0.4rem;
}

.content {
  @include attend-content
}

.empty {
  @include attend-empty
}

.border {
  @include attend-border
}

.text {
  @include attend-text
}

