@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';
@import '../../../../css/mixins/default_table';

.table {
  @include table;
  float: left;
  height: auto;
  position: relative;
  width: 100%;
}

.content {
  @include sm {
    margin-top: 5px;
     }
  width: 100%;
  float: left;
  position: relative;
}

.headerText {
  @include mobile-sm {
    margin-top: 10px;
    line-height: 1;
  }
  @include mobile-md {
    margin-top: 10px;
    line-height: 1;
  }
  @include mobile-lg {
    margin-top: 10px;
    line-height: 1;
  }
  @include sm {
    margin-top: 10px;
    line-height: 1;
  }
  width: 100%;
  color: $color-grafite;
  float: left;
  font-size: 0.8;
  height: 1.2rem;
  line-height: 3;
  margin-bottom: 1rem;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
}

.active {
  border-bottom: solid 1px;
  color: black;
  font-weight: bold;
}

.header {
  @include header;
}

.body {
  @include body;
}

.removed {
  @include removed;
  border-top: solid 1px !important;
}

.row {
  @include tableRow;
  border-bottom: 1px solid #ddd;
   &:hover {
    background-color: $color-gray-soft;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.headerBtn {
  background: transparent;
  border: 0;
  color: #333;
  cursor: pointer;
  float: left;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0;
  position: relative;
  text-transform: uppercase;
}

.headerTextContainer {
  width: 270px;
}

.headerAccountContainer {
  @extend .headerTextContainer;
  @include sm {
    width: 130px;
  }
}

.headerStatusContainer {
  @extend .headerTextContainer;
  @include sm {
    width: 130px;
  }
}

.headerNameContainer {
  @extend .headerTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 130px;
  }
}

.headerAtaContainer {
  @extend .headerTextContainer;
  @include sm {
    width: 130px;
  }
}

.headerPortText {
  @extend .headerTextContainer;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 130px;
  }
}

.tableBodyField {
  width: 245px;
  float: left;
  font-size: 1rem;
  line-height: 2.5;
}

.tableAccountField {
  @extend .tableBodyField;
  @include sm {
    width: 130px;
  }
}

.tableStatusField {
  @extend .tableBodyField;
  @include sm {
    width: 130px;
  }
}

.tableNameField {
  @extend .tableBodyField;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 130px;
  }
}

.tableAtaField {
  @extend .tableBodyField;
  @include sm {
    width: 130px;
  }
}

.tablePortField {
  @extend .tableBodyField;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    width: 130px;
  }
}
