@import '../../../../css/mixins/default_media_query';
@import '../../../../css/mixins/default_table';
@import '../../../../css/variables';
@import '../../../../css/mixins/default_title';
@import '../../../../css/mixins/default_buttons';

.headerWrapper {
  width: 100%;
}

.headerTitle {
@include general-title;
line-height: unset;
}

.headerSubtitle {
  @include subtitle;
  margin-top: 0.3rem;
}

.buttonsContainer {
  float: left;
  width: 100%;
  text-align: center;
}

.changeButton {
  @include btn;
  @include mobile-sm{
    margin: 0.3rem 0;
  }
  @include mobile-md{
    margin: 0.3rem 0;
  }
  @include mobile-lg{
    margin: 0.3rem 0;
  }
  margin: 0.3rem;
}

.buttonSelected {
  background-color:$btn-hover;
  cursor: unset;
}

.tableHeader {
  // background-color: #ddd;
  // border-top-left-radius: 5px;
  // border-top-right-radius: 5px;
  // -webkit-box-sizing: border-box;
  // box-sizing: border-box;
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: flex;
  // font-size: 1rem;
  // font-weight: 700;
  // height: 37px;
  // -webkit-box-pack: justify;
  // -ms-flex-pack: justify;
  // justify-content: space-between;
  // line-height: 1.7;
  // padding: 9px 10px;
  // width: 100%;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
  box-sizing: border-box;
  display: flex;
  font-size: 0.7rem;
  font-weight: 700;
  height: calc(37 * 1px);
  justify-content: space-between;
  line-height: 2.5;
  padding: 3px 0px 0px 10px;
  width: 100%;
  border-bottom: 3px solid $color-tecno;
  text-transform: uppercase;
}

.tableHeaderColumn {
  fill: inherit;
  float: left;
  position: relative;
  width: auto;
}

.table {
  @include table;
  @include wrapper;
  margin-top: 5px;
}

.header {
  @include header;
  height: 2rem;
}

.header-column {
  @include header-column;
 }

.row {
  @include rowtb;
}

.column {
  @include column;
}




.headerChangedByColumn {
  @extend .tableHeaderColumn;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
}

.headerDataColumn {
  @extend .tableHeaderColumn;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
}

.tableBody {
  @include mobile-sm-height {
    min-height: 100px;
    max-height: 165px;
  }
  @include mobile-lg-height {
    min-height: 100px;
    max-height: 365px;
  }
  @include sm-height {
    min-height: 100px;
    max-height: 515px;
  }
  @include md-height {
    min-height: 100px;
    max-height: 530px;
  }
  @include lg-height {
    min-height: 100px;
    max-height: 650px;
  }
  @include max-screen-height {
    min-height: 100px;
    max-height: 780px;
  }
  overflow-y: overlay;
  overflow-x: hidden;
}

.contactTableBody {
  @include mobile-sm-height {
    min-height: 95px;
    max-height: 95px;
  }
  @include mobile-lg-height {
    min-height: 100px;
    max-height: 330px;
  }
  @include sm-height {
    min-height: 100px;
    max-height: 470px;
  }
  @include md-height {
    min-height: 100px;
    max-height: 485px;
  }
  @include lg-height {
    min-height: 100px;
    max-height: 605px;
  }
  @include max-screen-height {
    min-height: 100px;
    max-height: 735px;
  }
  overflow-y: overlay;
  overflow-x: hidden;
}

.tableBodyColumn {
  width: 20%;
  @include mobile-sm {
    width: 70px;
  }
  @include mobile-md {
    width: 70px;
  }
  @include mobile-lg {
    width: 70px;
  }
}

.bodyChangedByColumn {
  @extend .tableBodyColumn;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
}

.bodyDataColumn {
  @extend .tableBodyColumn;
  overflow: hidden;
  @include mobile-sm {
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
  }
  @include mobile-md {
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
  }
  @include mobile-lg {
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
  }
}

.bodyTransmitterDataColumn {
  @extend .tableBodyColumn;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
}

.bodyToggleButton {
  @extend .tableBodyColumn;
  @include mobile-sm {
    width: 85px;
  }
  @include mobile-md {
    width: 85px;
  }
  @include mobile-lg {
    width: 85px;
  }
}

.tableRowWrapper {
  @include tableRowWrapper;
  @include mobile-sm {
    width: 99.2%;
  }
  @include mobile-md {
    width: 99.2%;
  }
  @include mobile-lg {
    width: 99.2%;
  }
  width: 100%;

  &:hover {
    background-color: $color-gray-soft;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}


.tableRow {
  border-top: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  fill: #666;
  float: left;
  font-weight: 300;
  font-size: 1rem;
  line-height: 2.5;
  height: 37px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 4px 10px;
  padding-right: 25px;
  position: relative;
  -webkit-transition: .3s ease;
  transition: .3s ease;
  width: 100%;
}

.rowExtraInfoWrapper {
  float: left;
  padding: 4px 10px 4px 35px;
  width: calc(100% - 60px);
}

.extraInfoMainPoint {
  display: list-item;
  font-size: 18px;
  list-style-type: disc;
}

.extraInfoLabel {
  font-size: 1rem;
  font-weight: bold;
}

.extraInfoSubPoint {
  display: list-item;
  margin-left: 25px;
  list-style-type: circle;
  list-style-position: outside;
  font-size: 1rem;  
  float: left;
  width: 99%
}

.subPointLabel {
  position: relative;
  float: left;
  font-weight: bold;
  width: 65px;
}

.extraInfoField {
  font-size: 1rem;
  margin-left: 5px;
}

.extraInfoValue {
  float: left;
  width: calc(100% - 60px);
}

.toggleButton {
  @include btn;
  min-width: 90px;
  padding: 0;
  float: right;
}


.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: solid 1px #ccc;
  // border-radius: 0;
  clear: left;
  min-height: 26px;
  height: auto;
  outline: 0;
  padding: 0 8px;
  background: white url("../../../../img/select-icon.png") no-repeat center right
    8px;
  background-size: 12px;
  // margin-bottom: 15px;
  margin-left: 1rem;
  color: #808285;
  cursor: pointer;
  transition: 0.3s ease;
  width: 275px;
  &:focus {
  border-color: $color-tecno;
  }

  &:hover {
  border-color: $color-tecno;
  }
}

.extraInfoTextWrapper {
  float: left;
  width: 50%;
}

.infoLabel {
  color: #b4b4b4b4;
  font-size: 0.8rem;
  font-weight: bold;
}

.infoText {
  margin-left: 5px;
  font-size: 0.8rem;
}

.deletedText {
  color: red;
}
