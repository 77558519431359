@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.return-container {
  @include sm {
    // left: 15%;
    z-index: 1001;
  }
  @include md-sm {
    // left: 15%;
    z-index: 1001;
  }
  @include sm-md {
    // left: 15%;
    z-index: 1001;
  }
  @include md {
    // left: 15%;
    z-index: 1001;
  }
  @include lg {
    // left: 30%;
    z-index: 999;
  }
  @include max-screen {
    // left: 30%;
    z-index: 999;
  }
  background: #fff;
  border: solid 1px #999;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  font-family: $globalfont;
  height: auto;
  // padding: 0px 20px 20px 20px;
  padding-bottom: 20px;
  position: fixed;
  // outline: 9999px solid rgba(0, 0, 0, 0.5);
  width: 700px;
}

.modal-header {
  padding: 5px;
  cursor: move;
  background-color: $sidebar-background-color;
  color: #fff;
}

.return-call-button {
  background: $btn-bg-color;;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 37px;
  padding: 0;
  margin-top: 10px;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  width: 260px;
  &:hover {
    background: $color-tecno-hover;
  }
}

.button-disabled {
  cursor: default;
  background: $color-gray-disable;
}

.return-close-modal-button {
  background: none;
  color: inherit;
  outline: inherit;
  border: none;
  cursor: pointer;
  float: right;
}

.modal-middle-wrapper {
  height: 100%;
  padding-top: 20px;
  width: 100%;
}

.modal-middle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
}
