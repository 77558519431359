
@import 'variables';

.error {
  float: left;
  font-family: $globalfont;
  font-size: 40px;
  margin-left: 50%;
  margin-top: 40px;
  transform: translate(-50%);
}
