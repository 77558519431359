@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

$height: 26px;

.trackerMenuContainer {
  display: block;
  float: left;
  margin-left: 0.5rem;
  width: 100%;
}

.trackerMenuValue {
  display: block;
  float: left;
  margin-top: 15px;
  width: 100%;
}

.trackerMenuText {
  border: 0;
  color: #415057;
  float: left;
  font-size: 0.9rem;
  text-align: left;
}

.rotate {
  transform: rotate(180deg);
}

.trackerMenuBtn {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  display: block;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 37px;
  margin-top: 15px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  transition: background 0.3s ease;
  font-weight: bold;
  width: 50%;
  &:hover {
    background-color: $btn-hover;
  }
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: 100%;
  }
  @include md-sm {
    width: 100%;
  }
}

.checkbox {
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
  @include mobile-sm {
    padding-top: 0.5rem;
  }
  @include mobile-md {
    padding-top: 0.5rem;
  }
  @include mobile-lg {
    padding-top: 0.5rem;
  }
  @include sm {
    padding-top: 0.5rem;
  }
  @include md-sm {
    padding-top: 0.5rem;
  }
}

.selectInput {
  -moz-appearance: none;
  -webkit-appearance: none;
  // appearance: none;
  // border: solid 1px #ccc;
  // border-radius: 0;
  clear: left;
  float: left;
  height: 26px;
  // margin-left: 10px;
  outline: 0;
  padding: 0 8px;
  width: 90%;
  font-size: 0.9rem;

  &--enable {
    background: transparent url("../../../../img/select-icon.png") no-repeat center right 8px;
    background-size: 12px;
    color: $color-gray;
    cursor: pointer;
    transition: 0.3s ease;

    &:focus {
      border-color: $color-tecno;
    }

    &:hover {
      border-color: $color-tecno;
    }
  }
}

.selectInterval {
  @include mobile-sm {
    width: 60%;
  }
  @include mobile-md {
    width: 60%;
  }
  @include mobile-lg {
    width: 60%;
  }
  @include sm {
    width: 50%;
  }
  @include md-sm {
    width: 30%;
  }
  @include md {
    width: 30%;
  }
  @include lg {
    width: 30%;
  }
  @include max-screen{
    width: 17%;
  }
}

.blockValue {
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: calc(100% - 25%);
  }

  @include md-sm {
    margin-top: 2px;
    width: calc(100% - 25%);
  }

  @include md {
    margin-top: 2px;
    width: calc(100% - 25%);
  }

  @include lg {
    margin-top: 2px;
    width: calc(100% - 20%);
  }

  @include max-screen {
    margin-top: -3px;
    width: calc(100% - 50%);
  }
  float: left;
  font-size: 1rem;
  // height: 32px;
  line-height: 2;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.tooltipContainer {
  // float: left;
  color: $btn-bg-color;
  @include mobile-sm {
    margin-right: 1.5rem;
  }
  @include mobile-md {
    margin-right: 1.5rem;
  }
  @include mobile-lg {
    margin-right: 1.5rem;
  }
}

.programmingInput {
  width: 90%;
  height: 26px;
}
