@import 'default_media_query';


@mixin priority {
    @include sm {
      display: none;
      /*overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100px;*/
    }
    @include md-sm {
      display: none;
    }
    @include md {
      width: 150px;
    }
    @include lg {
      width: 100px;
    }
    width: 100px;
  }
  
  @mixin adminActions {
    @include mobile-sm {
      width: 30px;
    }
    @include mobile-md {
      width: 30px;
    }
    @include mobile-lg {
      width: 30px;
    }
    @include sm {
      width: 75px;
    }
    @include md-sm {
      width: 75px;
    }
    @include md {
      width: 85px;
    }
    @include lg {
      width: 120px;
    }
    @include max-screen {
      width: 200px;
    }
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
  
  
  
  @mixin otherActions {
    @include mobile-sm {
      width: 30px;
    }
    @include mobile-md {
      width: 30px;
    }
    @include mobile-lg {
      width: 30px;
    }
    @include sm {
      width: 30px;
    }
    @include md-sm {
      width: 45px;
    }
    @include md {
      width: 60px;
    }
    @include lg {
      width: 160px;
    }
    @include max-screen {
      width: 160px;
    }
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
  
  
  @mixin trackerActions {
    @include mobile-sm {
      display: none;
    }
    @include mobile-md {
      display: none;
    }
    @include mobile-lg {
      display: none;
    }
    @include sm {
      width: 65px;
    }
    @include md-sm {
      width: 65px;
    }
    @include md {
      width: 65px;
    }
    @include lg {
      width: 65px;
    }
    @include max-screen {
      width: 65px;
    }
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
  
  