@import "../../../css/mixins/default_media_query";
@import "../../../css/mixins/default_title";
@import "../../../css/mixins/default_table";
@import "../../../css/mixins/default_buttons";
@import "../../../css/variables";
@import "../../../css/paginate";
@import "../../../css/mixins/base";

.title {
  @include general-title;
  margin-top: 0.3rem;
  height:auto;
}

.subtitle {
  @include subtitle;
  @include sm {
    margin-bottom: 0;
  }
}

.paginate {
  @include paginate;
}

.table {
  @include table;
  @include wrapper;
  margin-top: 5px;
}

.table-body {
  @include table-body;
}

.header {
  @include header;
}

.header-column {
  @include header-column;
}

.row {
  @include rowtb;
  // display: block;
}

.column {
  @include column;
  // margin-right: 15px;
}

.removed {
  @include removed;
}

.actions {
  @include actions;
}

.nameBtn {
  width: 60%;
}

.icons {
  @include icons;
}

.btn-add {
  @include btn-add;
  line-height: initial;
  padding-top: 0.5rem;
  @include md-sm {
    float: right;
    margin-top: 0;
    position: absolute;
    right: 1rem;  
  }
  @include md {
    float: left;
    margin-left: 1rem;
  }
  @include lg {
    float: left;
    margin-left: 1rem;   
  }
  @include max-screen {
    float: left;
    margin-left: 1rem;
  }
}

.add {
  @include add;
}

.priority {
  @include priority;
}

.adminActions {
  @include adminActions;
}

.otherActions {
  @include otherActions;
}

.rotate {
  transform: rotate(180deg);
}


.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.nameHeader{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%;
}

.subtitleContainer {
  @include mobile-sm {
    width: 57%;
  }
  @include mobile-md {
    width: 60%;
  }
  @include mobile-lg {
    width: 65%;
  }
  @include sm {
    width: 75%;
  }
  @include md-sm {
    width: 80%;
  }
  @include md {
    width: 80%;
  }
  @include lg {
    width: 85%;
  }
  @include max-screen {
    width: 90%;
  }
  float: right;
  height: auto;
  margin-left: 10px;
  margin-top: 22px;
  position: relative;
}

.emptyRow {
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 0;
  box-sizing: border-box;
  color: #333;
  display: flex;
  fill: #666;
  float: left;
  font-weight: 300;
  height: calc(var(37) * 1px);
  justify-content: space-between;
  padding: 4px 10px;
  position: relative;
  transition: 0.3s ease;
  width: 100%;
  &:hover {
    background-color: $color-gray-soft;
  }
}

.toggle-container {
  @include mobile-sm {
    float: left;
    right: auto;
    left:1rem;
  }
  @include mobile-md {
    float: left;
    right: auto;
    left:1rem;
  }
  @include mobile-lg {
    float: left;
    right: auto;
    left:1rem;  
  }
  @include sm {
    right: 1rem;
    position: absolute;
    margin-top: 4.5rem;
    width: 130px;
  }
  @include md-sm {
    right: 1rem;
    position: absolute;
    width: 130px;
    margin-top: 3.5rem;
  }
  @include md {
    right: 1rem;
    position: relative;
    width: 130px;
  }
  @include lg {
    right: 1.5rem;
    position: relative;
  }
  @include max-screen {
    right: 1.5rem;
    position: relative;
  }
  position: relative;
  right: 1rem;
  float: right;
}

.logsIcon {
  font-size: 26px;
  height: 26px;
  width: 26px;
}

.paginate-wrapper {
  position: absolute;
  right: 0;
  line-height: 25px;
  top: -37px;
  color: #999;
  font-size: 1rem;
}

.btn-import {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
}

.trackerActions {
  @include trackerActions;
}

.toggleButtonWrapper {
  @include toggleButtonWrapper;
}

.toggleButton {
  @include toggleButton;
}

.tableRowWrapper {
  @include tableRowWrapper;
}
.tableRow {
  @include tableRow;
}

.rowExtraInfoWrapper {
  float: left;
  padding: 4px 10px 4px 35px;
  width: calc(100% - 60px);
}

.buttonMenuWrapper {
  display: flex;
  justify-content: space-evenly;
  float: right;
  margin-right: 10px;
  margin-left: 10px;
  width: 150px;
}

.divTitleHeader {
width: auto;
display: inline;
@include mobile-sm {
  width: 100%;
  display: block;
}
@include mobile-md {
  width: 100%;
  display: block;
}
@include mobile-lg {
  width: 100%;
  display: block;
}
@include sm {
  width: 100%;
  display: block;
}
@include md-sm {
  width: 100%;
  display: block;
}
}

.divButtonHeader{
  width:50%;
  display: inline-block;
  @include mobile-sm {
    width: 100%;
    display: block;
  }
  @include mobile-md {
    width: 100%;
    display: block;
  }
  @include mobile-lg {
    width: 100%;
    display: block;
  }
  @include sm {
    width: 100%;
    display: block;
  }
  @include md-sm {
    width: 100%;
    display: block;
    min-height: 50px;
  }
}
