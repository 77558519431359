@import '../../../css/mixins/default_media_query';
@import '../../../css/variables';

.title {
  line-height: 1;
  color: $color-grafite;
  font-size: 1.1rem;
  height: 28px;
  margin-bottom: 2rem;
  margin-top: 15px;
  // text-align: center;
  width: 100%;
  @include mobile-sm {
    margin-bottom: 3rem;
    
  }
  @include mobile-md {
    margin-bottom: 3rem;
    
  }
  @include mobile-lg {
    margin-bottom: 3rem;
    
  }
}
