@import "../../../../css/mixins/reports";
@import "../../../../css/mixins/default_media_query";
@import "../../../../css/mixins/default_buttons";
@import "../../../../css/variables";

.title {
  @include report-title;
 @include sm {
    line-height: 1;
    margin-bottom: 60px;
    margin-top: 15px;
  }
 @include md {
    margin-bottom: 0;
  }
 @include lg {
    margin-bottom: 0;
  }
}

.subtitle {
 @include sm {
    line-height: 1;
    margin-top: 15px;
  }
 @include md {
    line-height: 1;
  }
 @include lg {
    line-height: 4;
  }
}

.table {
  @include report-table;
 @include sm {
    display: none;
  }
}

.paginate {
  @include report-paginate;
}

.header {
  @include report-header;
}

.header-column {
  @include report-header-column;
  text-align: left;
  width: 100%;
}

.body {
  @include report-body;
}

.row {
  @include report-row;
  font-size: 1rem;
  line-height: 2.5;
}

.column {
  @include report-column;
  text-align: left;
  width: 100%;
}

.download {
  //@include report-download;
   border-radius: 5px;
    width: 40px;
    height: 40px;
    text-align: center;
    color: white;
    background-color: $btn-bg-color;
}

.btn-content {
 @include sm {
    display: none;
  }
}

.account {
  @include report-field;
 @include sm {
    width: 145px;
  }
 @include md {
    width: 145px;
  }
 @include lg {
    width: 145px;
  }
}

.user {
  @include report-field;
 @include sm {
    width: 300px;
  }
 @include md {
    width: 300px;
  }
 @include lg {
    width: 300px;
  }
}

.day {
  text-align: center;
  @include report-field;
 @include sm {
    width: 200px;
  }
 @include md {
    width: 200px;
  }
 @include lg {
    width: 200px;
  }
}

.date-created {
  @include report-field;
 @include sm {
    width: 200px;
  }
 @include md {
    width: 200px;
  }
 @include lg {
    width: 200px;
  }
}

.date-deleted {
  @include report-field;
 @include sm {
    width: 200px;
  }
 @include md {
    width: 200px;
  }
 @include lg {
    width: 200px;
  }
}

.btn-print {
  @include button;
 @include sm {
    
    height: 37px;
    margin-top: 85px;
    width: 100%;
  }
  background: $btn-bg-color;
}

.rotate {
  transform: rotate(180deg);
}
