@import 'mixins/default_media_query';
@import 'variables';

.detail-container {
  @include sm {
    // padding: 10px 10px;
    width: 100%;
  }
  float: left;
  height: auto;
  // left: 50%;
  overflow-y: auto;
  overflow-x: hidden;
  // padding: 0 10px;
  position: relative;
  // transform: translateX(-50%);
  width: 100%;

  &--100 {
    width: 100%;
  }
}

.detail-title {
  color: $color-grafite;
  font-size: 1.1rem;
  height: auto;
  text-align: left;
  width: 100%;
  margin-top: 0.3rem;

  &--left {
    text-align: left;
  }
}

.detail-line {
  border-bottom: 2px solid $sidebar-background-color;
  float: left;
  font-size: 22px;
  // margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.detail-bar {
  border-bottom: 3px solid $sidebar-background-color;
  float: left;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.detail-btn {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid $sidebar-background-color;
  float: left;
  font-size: 0.9rem;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
  background: transparent;
  &:focus {
    outline: none;
  }
}

.detail-header {
  float: left;
  width: 100%;
}

.detail-info {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  flex-flow: column;
  float: left;
  justify-content: flex-start;
  position: relative;

  &--100 {
    width: 100%;
  }
}
