@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.container {
  @include md-sm {
    margin-right: 10%;
  }
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-left: auto;
  overflow: hidden;
  transition: .3s ease;
  height: 55px;
  font-family: $globalfont;
  width: 280px;
}

.container-expanded {
  height: 180px;
}

.selected-domain-container {
  list-style-type: none;
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0;
}

.selected-domain-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: solid 1px $color-gray-soft;
  border-top: 0;
  transition: background .3s ease;
  fill: $color-white;
  height: 55px;
  background-color: $color-tecno;
  border: 0;
  color: $color-white;
  padding: 0 5px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    color: $color-white;
    fill: $color-gray-soft;
    background-color: $color-blue;
  }
}

.menu-list {
  list-style-type: none;
  float: left;
  width: 100%;
  padding: 0;
  overflow-y: auto;
}


.menu-list-items:hover {
  color: $color-blue;
}

.menu-list-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: solid 1px $color-gray-soft;
  border-top: 0;
  transition: background .3s ease;
  color: #333;
  fill: $color-white;
  height: 35px;
  background-color: $color-gray-soft;
}

.menu-button-expanded {
  background-color: $color-blue !important
}

.menu-list-items__text {
  overflow: hidden;
  text-overflow: ellipsis;
  background: none;
  border: none;
  outline: none !important;
  text-decoration: none;
  color: inherit;
  float: left;
  width: 100%;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.rotate {
  transform: rotate(180deg);
}
