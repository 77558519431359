@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.menuContainer {
  display: flex;
  flex-direction: column;
  width: 100%
}

.menuOptionBtn {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  height: 100%;
  text-align: left;
}

.menuOptionBtnText {
  border: 0;
  color: #415057;
  font-size: 0.8rem;
  text-align: left;
  text-transform: uppercase;
  font-weight: bolder;
}

.menuTriangle {
  float: right;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  height: 0;
  margin-left: 2px;
  margin-top: 5px;
  width: 0;
}

.rotate {
  transform: rotate(180deg);
}

.submenuOption {
  margin-bottom: 15px;
}

.submenuText {
  float: left;
  height: auto;
  width: 180px;
  padding: 0.375rem 0.75rem;
}

.toggleContainer{
  float: left;
  margin-left: 20px;
}

.input {
  float: left;
  width: 55%;
}

.selectInput {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: solid 1px #ccc;
  float: left;
  height: 26px;
  outline: 0;
  padding: 0 8px;
  width: 55%;

  &--enable {
    background-size: 12px;
    color: $color-gray;
    cursor: pointer;
    transition: 0.3s ease;

    &:focus {
      border-color: $color-tecno;
    }

    &:hover {
      border-color: $color-tecno;
    }
  }
}

.workdayContainer {
  float: left;
  margin-right: 15px;
  height: auto;
  padding: 0.375rem 0.75rem;
}

.menuSaveBtn {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  display: block;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 37px;
  margin-top: 15px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  transition: background 0.3s ease;
  font-weight: bold;
  width: 50%;
  &:hover {
    background-color: $btn-hover;
  }
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: 100%;
  }
  @include md-sm {
    width: 100%;
  }
}
