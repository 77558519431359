@import '../../css/mixins/default_media_query';
@import '../../css/variables';

.filter-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
}

.filter-container {
  @include mobile-sm {
    top: inherit;
    left: 16%;
    width: 280px
  }
  @include mobile-md {
    top: inherit;
    left: 16%;
    width: 300px
  }
  @include mobile-lg {
    top: inherit;
    left: 16%;
    width: 360px
  }
  @include sm {
    @include mobile-sm-height {
      top: inherit;
      bottom: 10px;
    }
    left: 15%;
    width: 455px;
    padding: 5px 15px 5px 15px;
    top: 5rem;
  }
  @include md-sm {
    left: 15%;
    width: 500px;
  }
  @include md {
    left: 15%;
    width: 500px;
  }
  @include lg {
    left: 30%;  
  }
  @include max-screen {
    left: 30%;  
  }
  background: #fff;
  border: solid 1px #999;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: auto;
  left: 30%;
  padding: 20px;
  position: fixed;
  z-index: 999;
  width: 700px;
  outline: 9999px solid rgba(0, 0, 0, .5);
}

.filter-header {
  color: $color-grafite;
  font-size: 1.1rem;
  width: 100%;
  height: auto;
  text-align: center;
  @include mobile-sm {
    font-size: 1rem;
  }
  @include mobile-md {
    font-size: 1rem;
  }
  @include mobile-lg {
    font-size: 1rem;
  }
  @include sm {
    font-size: 1rem;
     }
  @include md-sm {
    font-size: 1.2rem;
  }
 
}

.input-wrapper {
  width: 100%;
}

.filter-header {
  font-size: 22px;
  width: 100%;
  height: auto;
  text-align: center;
}

.filter-companies-container {
  float: left;
  font-size: 1rem;
  height: 34px;
  margin: 5px 0;
  position: relative;
  width: 100%;
}

.filter-companies-label {
  box-sizing: border-box;
  color: $color-gray;
  float: left;
  font-size: 1rem;
  height: 26px;
  line-height: 2;
  padding: 0 10px;
  position: relative;
  text-align: right;
  width: 148px;
}

.filter-trackers-container {
  float: left;
  font-size: 1rem;
  height: 34px;
  margin: 5px 0;
  position: relative;
  width: 100%;
}

.filter-trackers-label {
  box-sizing: border-box;
  color: $color-gray;
  float: left;
  font-size: 1rem;
  height: 26px;
  line-height: 2;
  padding: 0 10px;
  position: relative;
  text-align: right;

}

.filter-button-container {
  float: left;
  height: 37px;
  width: auto;
  height: auto;
}

.filter-btn {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 2rem;
  margin-left: calc(100% - 70%);
  padding: 0;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  width: 173px;
  &:hover {
    background-color: $btn-hover;
  }
}

.filter-btn-disabled {
  cursor: default;
  background: $color-gray-disable;
}
