@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

.font-300{
	font-weight: 300;
}

.font-400{
	font-weight: 400;
}

.font-500{
	font-weight: 500;
}

.font-700{
	font-weight: 700;
}