@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.wrapper {
  width: 100%;
  float: left;
  // display: flex;
  // justify-content: space-between;
  padding: 10px;
}

.btn-download {
  border: 0;
  border-radius: 5px;
  background: $btn-bg-color;
  font-weight: bold;
  color: $color-white;
  float: right;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  width: 35px;
  &:hover {
    background-color: $btn-hover;
  }
}
