@import '../../../css/mixins/default_media_query';  
@import '../../../css/variables';  

.info {
  box-sizing: border-box;
  color: $info-color;
  display: flex;
  flex-direction: column;
  float: left;
  justify-content: space-around;
  padding: 15px 0;
  position: relative;
  width: 100%;
}

.icon {
  border-radius: 5px;
  box-sizing: border-box;
  color: $icon-standard-color;
  float: left;
  padding: $padding-icon-value;
  position: relative;
  transition: .3s ease;

  &:hover {
    background-color: $icon-bg-color-hover;
    color: $icon-color-hover;

    & + .description {
      top: -34px;
    }
  }
}

.text {
  box-sizing: border-box;
  font-size: 17px;
  overflow: hidden;
  padding: 5px 0;
  width: 100%;
}

.value {
  box-sizing: border-box;
  float: left;
  margin-left: 10px;
  overflow: hidden;
  padding: $padding-icon-value;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 35px);
}

.description {
  background-color: $icon-bg-color-hover;
  border-radius: 5px;
  box-sizing: border-box;
  color: $icon-color-hover;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  top: -100px;
  transition: .3s ease;
  width: 100%;
  z-index: 2;
}

.subtitle-content {
  @include sm {
    width: 50%;
  }
  @include md-sm {
    /*width: 100%;*/
  }

  @include md {
    /*width: 100%;*/
  }
  @include lg {
    /*width: 100%;*/
  }
}

.prevDate {
  border-radius: $border-radius;
  border-right: 1px solid #dedede;
  color: #333;
  flex-direction: column;
  text-decoration: none;
  transition: .3s ease;
  width: 25%;

  &:hover {
    background-color: $bg-color-hover;
  }
}

.currDate {
  border-radius: $border-radius;
  color: #333;
  flex-direction: column;
  text-decoration: none;
  transition: .3s ease;
  width: 25%;

  &:hover {
    background-color: $bg-color-hover;
  }
}
