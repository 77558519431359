.scroll {
	overflow-y: auto;
}

.scroll::-webkit-scrollbar {
    width: 8px;
}
 
.scroll::-webkit-scrollbar-track{
	background-color: rgba(0,0,0,0.05);
}
 
.scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
