@import '../../../css/mixins/default_media_query';
@import "../../../css/variables";

.observations-container {
  height: auto !important;
}


.full-block {
  height: auto !important;
  width: calc(100% - 132px) !important;
}

.age-number {
  float: right;
  font-size: 40px;
  width: 60px;
  line-height: 1;
  height: 60px;
  color: $color-tecno;
}

.age-years {
  font-size: 20px;
  color: black;
  position: relative;
  line-height: 0.7;
  float: left;
  padding: 0;
  margin: 0;
}

.detail-button-container {
  float: left;
  height: 37px;
  width: auto;
  position: relative;
  margin: 10px 0;
}

.header-icon-age {
  @include mobile-sm{
    float: left;
  }
  @include mobile-md{
    float: left;
  }
  @include mobile-lg{
    float: left;
  }
  @include sm {
    float: left;
  }
  @include md-sm {
    float: left;
  }
  float: right;
}

.detail-call-btn {
  background: $color-tecno;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 37px;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  width: 290px;
  &:hover {
      background-color: $btn-hover;
  }
}

.detail-btn-dl {
  border: 0;
  border-radius: 5px;
  background: $btn-bg-color;
  font-weight: bold;
  color: $color-white;
  float: right;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;
  width: 35px;
  &:hover {
    background-color: $btn-hover;
  }
}
