@import "../../../../css/mixins/default_media_query";
@import "../../../../css/mixins/default_buttons";
@import '../../../../css/variables';


.client-wrapper {
  float: left;
  width: 100%;
}

.label {
  box-sizing: border-box;
  color: $color-gray;
  float: left;
  font-size: 0.8rem;
  height: 26px;
  text-transform: uppercase;
  text-align: right;
  position: relative;
  height: auto;
  width: 25%;
  padding-bottom: 5px;
  @include mobile-sm {
    width: 100%;
    text-align: left;
    font-size: 1rem;
  }
  @include mobile-md {
    width: 100%;
    text-align: left;
    font-size: 1rem;
  }
  @include mobile-lg {
    width: 100%;
    text-align: left;
    font-size: 1rem;
  }
  @include sm {
    width: 100%;
    text-align: left;
    font-size: 1rem;
  }
  @include md-sm {
    width: 100%;
    text-align: left;
    font-size: 1rem;
  }
  @include md {
    width: 25%;
  }
  @include lg {
    width: 25%;
  }
  @include max-screen {
    width: 25%;
  }
}

.text {
  box-sizing: border-box;
  font-size: 0.8rem;
  padding: 0 10px;
  float: left;
  max-height: 4rem;
  @include mobile-sm {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    max-height: 4rem;
  }
  @include mobile-md {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    max-height: 4rem;
  }
  @include mobile-lg {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    max-height: 4rem;
  }
  @include sm {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    max-height: 4rem;
  }
  @include md-sm {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    max-height: 4rem;
  }
  @include md {
    width: 75%;
  }
  @include lg {
    width: 75%;
  }
  @include max-screen {
    width: 75%;
  }
}

.bigtext {
  overflow-y: auto;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 0 10px;
  width: 75%;
  line-height: 1.2rem;
  @include mobile-sm {
    width: 100%;
    font-size: 1rem;
  }
  @include mobile-md {
    width: 100%;
    font-size: 1rem;
  }
  @include mobile-lg {
    width: 100%;
    font-size: 1rem;
  }
  @include sm {
    width: 100%;
    font-size: 1rem;
  }
  @include md-sm {
    width: 100%;
    font-size: 1rem;
  }
  @include md {
    width: 75%;
  }
  @include lg {
    width: 75%;
  }
  @include max-screen {
    width: 75%;
  }
}

.clientRow {
  float: left;
  width: 100%;
  padding-top: 0.35rem;
  border-bottom: 1px solid #babbbd;
  min-height:2.5rem;
}

.contrastRow {
  font-weight: bold;
}

.rotate {
  transform: rotate(180deg);
}


.observationLabel {
  box-sizing: border-box;
  float: left;
  font-size: 0.8rem;
  height: auto;
  position: relative;
  text-align: right;
  text-transform: uppercase;
  width: 25%;
  // padding-bottom: 5px;
  font-weight:bold; 
  @include mobile-sm {
    width: 100%;
    text-align: left;
  }
  @include mobile-md {
    width: 100%;
    text-align: left;
  }
  @include mobile-lg {
    width: 100%;
    text-align: left;
  }
  @include sm {
    width: 100%;
    text-align: left;
  }
  @include md-sm {
    width: 100%;
    text-align: left;
  }
  @include md {
    width: 25%;
  }
  @include lg {
    width: 25%;
  }
  @include max-screen {
    width: 25%;
  }
}
