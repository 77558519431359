@import '../../css/mixins/default_media_query';

.wrapper {
  background: #fff;
  border: solid 1px #999;
  border-radius: 5px;
  display: inline-block;
  flex-direction: column;
  left: 50%;
  padding: 20px;
  position: fixed;
  top: 135px;
  transform: translateX(-50%);
  width: 80%;
  z-index: 999;
  outline: 9999px solid rgba(0, 0, 0, .5);
}
