@import 'mixins/default_media_query';
@import 'variables';

$color-gray: #999;


.contactto-timer-dashboard {
  font-family: $globalfont;
  position: absolute;
  right: 10px;
  width: 200px;
}

.contactto-timer-detail {
  @include sm {
    right: 15px;
    top: 60px;
    width: 245px;
  }
  @include sm {
    margin-top: 30px;
  }
  font-family: $globalfont;
  position: absolute;
  right: 30px;
  top: 10px;
  width: 275px;
  z-index: 999;
}

.call-timer-clock {
  border: solid 6px;
  border-radius: 100%;
  color: $color-gray;
  float: right;
  font-size: 19px;
  height: 67px;
  line-height: 3.52;
  position: relative;
  text-align: center;
  width: 67px;
  @include mobile-sm {
    font-size: 17px;
    height: 50px;
    line-height: 2;
    margin-right: 15px;
    top: 25px;
    width: 50px;
  }
  @include mobile-md {
    font-size: 17px;
    height: 50px;
    line-height: 3;
    margin-right: 15px;
    top: 25px;
    width: 50px;
  }
  @include mobile-lg {
    font-size: 17px;
    height: 50px;
    line-height: 3;
    right: 10px;
    top: 26px;
    width: 50px;
  }
}

.call-timer-text {
  clear: left;
  color: $color-gray;
  float: left;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 305px;
  @include mobile-sm {
    top: 15%;
    transform: translateY(0);
    width: auto;
  }
  @include mobile-md {
    top: 15%;
    transform: translateY(0);
    width: auto;
  }
  @include mobile-lg {
    margin-left: 90px;
    top: 15%;
    transform: translateY(0);
    width: auto;
  }
}
