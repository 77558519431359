@import 'mixins/default_media_query';
@import 'mixins/utils';
@import 'variables';


.rowc{
  width:100%;
}
.contactto-49 {
  width: 49%;
}

.contactto-50 {
  width: 50%;
}

.contactto-100 {
  width: 100%;
}

.contactto-left-20 {
  margin-left: 20px;
}

.contactto-left-50 {
  margin-left: 50%;
}

.contactto-bottom-20 {
  margin-bottom: 20px;
}

.contactto-rotate-180 {
  transform: rotate(180deg);
}

.contactto-hide {
  display: none;
}

.grey {
  color: $color-gray;
}

.left {
  float: left;
  position: relative;
}

.text-left {
  text-align: left;
}

.right {
  float: right;
  position: relative;
}

.height-auto {
  height: auto;
}

.clickable {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.contactto-flex-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.contactto-flex-even {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
}

.contactto-flex-column {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.contactto-horizontal-center {
  @include transform('translateX(-50%)');
  margin-left: 50%;
}

.opacity-0 {
  opacity: 0;
}

.hide-sm {
  @include sm {
    display: none;
  }
}

.hide-md-sm {
  @include md-sm {
    display: none;
  }
}
.hide-md {
  @include md {
    display: none;
  }
}

.hide-lg {
  @include lg {
    display: none;
  }
}

.hide-md-lg {
  @include md-lg {
    display: none;
  }
}
