@import '../../css/mixins/default_media_query';
@import '../../css/variables';


.reminder-container {
  @include sm {
    left: 15%;
    z-index: 1001;
  }
  @include md-sm {
    left: 15%;
    z-index: 1001;
  }
  @include sm-md {
    left: 15%;
    z-index: 1001;
  }
  @include md {
    left: 15%;
    z-index: 1001;
  }
  @include lg {
    left: 30%;
    z-index: 999;
  }
  @include max-screen {
    left: 30%;
    z-index: 999;
  }
  background: #fff;
  border: solid 1px #999;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  font-family: $globalfont;
  height: auto;
  padding: 20px;
  position: fixed;
  outline: 9999px solid rgba(0, 0, 0, 0.5);
  width: 700px;
}

.reminder-header {
  color: $color-tecno;
  font-size: 22px;
  width: 100%;
  height: auto;
  text-align: center;
}

.reminder-text-container {
  float: left;
  position: relative;
  width: 100%;
  height: auto;
  font-size: 1rem;
  margin: 5px 0;
}

.reminder-text-block {
  @include sm {
    width: calc(100% - 125px);
  }
  @include md {
    width: calc(100% - 125px);
  }
  height: auto;
  padding-left: 20px;
  width: calc(100% - 104px);
  float: left;
}

.reminder-text-label {
  float: left;
  box-sizing: border-box;
  color: $color-gray;
  padding: 0 9px;
  text-align: left;
  width: 78px;
}

.reminder-text-limit {
  box-sizing: border-box;
  color: $color-gray;
  text-align: right;
  float: right;
  width: 26px;
  padding: 0;
}

.reminder-textarea {
  box-sizing: border-box;
  float: left;
  position: relative;
  resize: none;
  width: 100%;
}

.reminder-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: solid 1px #ccc;
  border-radius: 0;
  clear: left;
  min-height: 26px;
  height: auto;
  outline: 0;
  padding: 0 8px;
  background: white;
  background-size: 12px;
  color: #808285;
  cursor: pointer;
  transition: 0.3s ease;
  &:focus {
    border-color: $color-tecno;
  }

  &:hover {
    border-color: $color-tecno;
  }
}

.reminder-select-container {
  float: left;
  width: 80px;
}

.reminder-timer-label {
  float: left;
  color: #808285;
  margin-right: 5px;
  position: relative;
}

.reminder-timer-block {
  float: left;
}

.reminder-timer-container {
  margin-top: 10px;
  float: left;
  font-size: 1rem;
  width: 100%;
}

.reminder-button-container {
  float: left;
  height: 37px;
  bottom: 5%;
  width: 95%;
  height: auto;
  position: relative;
} 

.reminder-btn {
  background: $color-tecno;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 37px;
  margin-left: 38%;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  width: 173px;
  &:hover {
    background-color: $btn-hover;
  }
}

.reminder-btn-disabled {
  background: #ededed;
  cursor: default;
  pointer-events: none;
}

.reminder-inform-container {
  float: left;
  position: relative;
  width: 100%;
  height: auto;
  font-size: 1rem;
  margin: 10px 0 5px 0;
}

.reminder-inform-label {
  float: left;
  box-sizing: border-box;
  color: $color-gray;
  line-height: 33px;
  padding: 0 9px;
  text-align: left;
  width: 250px;
}

.reminder-error-label {
  float: left;
  box-sizing: border-box;
  color: $color-red;
  line-height: 33px;
  padding: 0 9px;
  text-align: left;
  width: 100%;
}

.reminder-help-label {
  color: $color-gray;
  font-size: 12px;
  width: 100%;
  float: left;
  bottom: 40px;
  text-align: center;
}
