@import '../../../css/mixins/default_media_query';
@import '../../../css/variables';

$width: 520px;

.reminder-container {
  // @include sm {
  //   bottom: 5%;
  //   height: 675px;
  // }
  // @include md {
  //   bottom: 5%;
  //   height: 675px;
  // }
  // @include lg {
  //   bottom: 5%;
  // }
  // @include max-screen {
  //   bottom: 15%;
  // }
  background-color: $color-white;
  border: solid 1px $color-tecno;
  box-sizing: border-box;
  font-family: $globalfont;
  height: inherit;
  overflow-y: auto;
  position: absolute;
  right: 0;
  // transition: right .3s ease;
  width: $width;
  &:first-child {
    outline: 9999px solid rgba(0, 0, 0, .5);
  }
}

.reminder-hide-modal {
  // right: -$width;
  &:first-child {
    outline: 1px;
  }
}

.reminder-header {
  @include sm {
    margin-top: 0;
  }
  @include md {
    margin-top: 0;
  }
  margin-top: 10px;
  width: 100%;
  height: auto;
  text-align: center;
}

.reminder-title {
  color: $color-tecno;
  font-size: 20px;
  text-align: center;
  width: 100%;
}

.reminder-subtitle {
  @include sm {
    float: left;
  }
  @include md {
    float: left;
  }
  margin-left: 5px;
  color: $color-gray;
  font-size: 16px;
  text-align: center;
  width: 100%;
}

.reminder-hide {
  right: -$width;
}

.reminder-info-wrapper {
  float: left;
  height: auto;
  width: 100%;
}

.reminder-info-container {
  float: left;
  box-sizing: border-box;
  margin-top: 15px;
  padding: 0 20px;
  width: 100%;
}

.reminder-text-label {
  color: $color-gray;
  text-align: right;
  padding-right: 5px;
  width: 32%;
}

.reminder-text-container {
  float: left;
  position: relative;
  padding: 0 20px;
  width: 100%;
  height: auto;
  margin-top: 15px;
}

.reminder-text-block {
  height: auto;
  width: calc(100% - 55px);
  float: left;
}

.reminder-text-limit {
  box-sizing: border-box;
  color: $color-gray;
  text-align: right;
  float: left;
  font-size: 12px;
  width: 26px;
  padding: 0;
}

.reminder-textarea {
  box-sizing: border-box;
  float: left;
  position: relative;
  resize: none;
  width: 100%;
}

.reminder-button-container {
  display: flex;
  justify-content: center;
  float: left;
  height: 37px;
  width: 95%;
  height: auto;
  position: relative;
  margin: 10px 0;
}

.softphone-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.reminder-btn {
  background: $color-tecno;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 37px;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  width: 240px;
  &:hover {
      background-color: $btn-hover;
  }
}

.reminder-btn-disabled {
  background: #ededed;
  cursor: default;
  pointer-events: none;
}

.reminder-help-label {
  color: $color-gray;
  font-size: 12px;
  margin-left: 25px;
  position: absolute;
  width: 100%;
  float: left;
  bottom: 40px;
  text-align: center;
}

.reminder-contacts-wrapper {
  float: left;
  border: 1px solid #ddd;
  width: 96%;
  max-height: 100px;
  overflow-y: auto;
}

.reminder-contacts-row {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  margin: 5px 0;
  &:hover {
    background-color: $bg-color-hover;
  }
}

.reminder-contacts-expanded-row {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  margin: 5px 0;
}

.reminder-hide-container {
  @include sm {
    float: left;
  }
  @include md {
    float: left;
  }
  display: flex;
  justify-content: flex-start;
}

.reminder-arrow-container {
  @include sm {
    float: left;
  }
  @include md {
    float: left;
  }
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.reminder-hide-button {
  -webkit-appearance: none;
  border: none;
  background: none;
  cursor: pointer;
}

.btn-outline {
  outline: none !important;
}

.extension-container {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
}

.extension-label {
  color: #b4b4b4;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: bold;
  height: auto;
}

.extension-client-info,
.extension-call-info,
.extension-contact-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.extension-client-container,
.extension-contact-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.extension-separator {
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 0;
}

.extension-client-title {
  color: #00b8cb;
  font-size: 0.7rem;
  font-weight: bold;
}

.extension-contact-title {
  margin-left: 15px;
  font-weight: bold;
  color: #00b8cb;
  font-size: 0.7rem;
  font-weight: bold;
}

.extension-select-btn {
  background: $btn-bg-color;;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: $globalfont;
  font-size: 1rem;
  height: 37px;
  padding: 0;
  margin-top: 10px;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  width: 160px;
  &:hover {
    background: $color-tecno-hover;
  }
}

.extension-select {
  margin-left: 10px;
  padding: .375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
}
