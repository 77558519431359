@import 'mixins/default_media_query';
@import 'variables';

.toggle-wrapper {
  float: right;
  font-size: 0.8rem;
  @include mobile-sm {
    width: auto;
    margin-bottom: 2%;
    margin-left: 0px ;
    margin-right: 0.2rem;
  }
  @include mobile-md {
    width: auto;
    margin-bottom: 2%;
    margin-left: 0px ;
    margin-right: 0.2rem;
  }
  @include mobile-lg {
    width: auto;
    margin-bottom: 2%;
    margin-left: 0px ;
    margin-right: 0.2rem;
  }
  @include sm {
    margin-bottom: 0;
    position:relative;
    right: 0.1rem;
    bottom: 0;
    
  }
  @include md-sm {
    margin-bottom: 0;
    position: relative;
    right: 0.1rem;
    bottom: 0;
  }
  @include md {
    position: relative;
    right: 0.1rem;
    // top:0.2rem;
  }
  @include lg {
    position: relative;
    right: 0.5rem;
    width: auto;
    // top:0.5rem;
  }
  @include max-screen {
    position: relative;
    right: 1.5rem;
    width: auto;
  }

}
.pagination {
  @include sm {
    margin-right: 15% !important;
  }
  @include md {
    margin-right: 13%;
  }
  @include lg {
    margin-right: 10%;
  }
  @include max-screen {
    margin-right: 8%;
  }
}
.switch {
  background: transparent;
  border: 0;
  display: inline-block;
  float: right;
  height: 34px;
  position: relative;
  width: 60px;

  &--input {
    display: none;
  }
}

.slider {
  background-color: $color-gray;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;

  &::before {
    background-color: $color-white;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 26px;
  }

  &:focus {
    box-shadow: 0 0 1px $btn-bg-color;
  }

  &--checked {
    background-color: $btn-bg-color;

    &::before {
      transform: translateX(26px);
    }
  }
}

.round {
  border-radius: 34px;

  &::before {
    border-radius: 50%;
  }
}
