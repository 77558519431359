@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.contactted-wrapper {
  width: 100%;
  float: left;
  font-size: 1rem;
}

.contactted-container {
  width: 100%;
  float: left;
}

.text {
  width: 20%;
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: 100%;
  }
  @include md-sm {
    width: 100%;
  }
}

.label{
  margin-left: 10px;
  @include mobile-sm {
    margin-left: 0;
  }
  @include mobile-md {
    margin-left: 0;
  }
  @include mobile-lg {
    margin-left: 0;
  }
  @include sm {
    margin-left: 0;
  }
  @include md-sm {
    margin-left: 0;
  }
}

.no-contactted {
  margin: 10px;
  float: left;
  width: 100%;
  @include mobile-sm {
    font-size: 1rem;
  }
  @include mobile-md {
    font-size: 1rem;
  }
  @include mobile-lg {
    font-size: 1rem;
  }
  @include sm {
    font-size: 1rem;
  }
  @include md-sm {
    font-size: 1rem;
  }
}
