@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';

.wrapper {
  display: block;
  float: left;
  margin-left: 0.5rem;
  width: 95%;
  height: 100%;
}

.container {
  float: left;
  width: 100%;
  height: auto;
}

.menuContainer {
  display: block;
  float: left;
  margin-top: 15px;
  width: 100%;
}

.menuOption {
  background-color: transparent;
  border: 0;
  color: #415057;
  cursor: pointer;
  float: left;
  height: 100%;
  text-align: left;
}

.menuText {
  border: 0;
  color: #415057;
  float: left;
  font-size: 0.8rem;
  text-align: left;
  text-transform: uppercase;
  font-weight: bolder;
}

.menuTriangle {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  float: right;
  height: 0;
  margin-left: 2px;
  margin-top: 5px;
  position: relative;
  width: 0;
}

.rotate {
  transform: rotate(180deg);
}

.submenuContainer {
  float: left;
  width: 100%;
  height: auto;
  font-size: 0.9rem;
}

.submenuLabel {
  color: #415057;
  clear: left;
  float: left;
  height: auto;
  margin-left: 0.5rem;
  margin-top: 10px;
  margin-right: 5px;
  position: relative;
  width: 100%;
  font-size: 0.9rem;
}

.selectContainer {
  -moz-appearance: none;
  -webkit-appearance: none;
  // appearance: none;
  // border: solid 1px #ccc;
  // border-radius: 0;
  float: left;
  height: 26px;
  margin-top: 6px;
  outline: 0;
  padding: 0 8px;
  width: 45px;
  background: transparent url("../../../../img/select-icon.png") no-repeat center right 8px;
  background-size: 12px;
  color: $color-gray;
  cursor: pointer;
  transition: 0.3s ease;

  &:focus {
    border-color: $color-tecno;
  }

  &:hover {
    border-color: $color-tecno;
  }
}

.selectContainerMin {
  -moz-appearance: none;
  -webkit-appearance: none;
  // appearance: none;
  // border: solid 1px #ccc;
  // border-radius: 0;
  float: left;
  height: 26px;
  margin-top: 6px;
  outline: 0;
  padding: 0 8px;
  width: 55px;
  background: transparent url("../../../../img/select-icon.png") no-repeat center right 8px;
  background-size: 12px;
  color: $color-gray;
  cursor: pointer;
  transition: 0.3s ease;

  &:focus {
    border-color: $color-tecno;
  }

  &:hover {
    border-color: $color-tecno;
  }
}

.submenuBtn {
  background: $btn-bg-color;
  border: 0;
  border-radius: 5px;
  clear: left;
  color: $color-white;
  cursor: pointer;
  display: block;
  float: left;
  font-family: $globalfont;
  font-size: 0.8rem;
  height: 37px;
  margin-top: 15px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  transition: background 0.3s ease;
  font-weight: bold;
  width: 100%;
  &:hover {
    background-color: $btn-hover;
  }
}

.toggleWrapper {
  @include mobile-sm {
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 80%;
  }
  @include mobile-md {
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 80%;
  }
  @include mobile-lg {
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 80%;
  }
  @include sm {
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 80%;
  }
  @include md-sm {
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 80%;
  }

  float: right;
  padding-left: 4rem;
}
