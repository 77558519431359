@import '../../../../css/mixins/reports';
@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';
@import '../../../../css/mixins/default_buttons';

.title {
  @include report-title;
  @include mobile-sm {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-md {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include mobile-lg {
    line-height: 1;
    float: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  @include sm {
    line-height: 1;
    margin-top: 15px;
  }
  @include md {
    margin-bottom: 0;
  }
  @include lg {
    margin-bottom: 0;
  }
  @include max-screen {
    margin-bottom: 0;
  }
  width: 100%;
}

.subtitle {
  @include sm {
     line-height: 1;
     margin-top: 15px;
  }
  @include md {
     line-height: 1;
  }
 @include lg {
     line-height: 4;
  }
}

.table {
  @include report-table;
  // @include mobile-sm {
  //   display: none;
  // }
  // @include mobile-md {
  //   display: none;
  // }
  // @include mobile-lg {
  //   display: none;
  // }
  // @include sm {
  //   display: none;
  // }
}

.paginate {
  @include report-paginate;
}

.header {
  @include report-header;
}

.header-column {
  @include report-header-column;
  text-align: left;
  width: 100%;
}

.body {
  @include report-body;
}

.row {
  @include report-row;
}

.column {
  @include report-column;
  text-align: left;
  width: 100%;
}

.download {
  //@include report-download;
   border-radius: 5px;
    width: 40px;
    height: 40px;
    text-align: center;
    color: white;
    background-color: $btn-bg-color;
}

.id {
  @include report-field;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
}

.client {
  @include report-field;
}

.alarm {
  @include report-field;
}

.company {
  @include report-field;
}




.date {
  @include report-field;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
}

.attendant {
  @include report-field;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
}

.duration {
  @include report-field;
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
}

.btn-print {
  @include button;
  @include sm {
    background: $btn-bg-color;
    height: 37px;
    margin-top: 85px;
    width: 100%;
  }
  @include md {
    display: none;
  }
 @include lg {
    display: none;
  }
}

.rotate {
  transform: rotate(180deg);
}
