@import '../../../../css/mixins/default_media_query.scss';
@import '../../../../css/variables';

.button-container {
  font-size: 0.8rem;
  float: left;
  flex-basis: auto;
  padding: 0.5rem 1rem;
  @include mobile-sm {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }
  @include mobile-md {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }
  @include mobile-lg {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }
  @include sm {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }
  @include md-sm {
    width: 100%;
    text-align: left;
    font-size: 1rem;
  }
}


.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.answered-column {
  margin-left: -3rem;
  @include mobile-sm {
    margin-left: initial;
    }
  @include mobile-md {
    margin-left: initial;
  }
  @include mobile-lg {
    margin-left: initial;
  }
  @include sm {
    margin-left: initial;}
}

.contacted-column {
  margin-left: -1.5rem;
  @include mobile-sm {
    margin-left: initial;
    }
  @include mobile-md {
    margin-left: initial;
  }
  @include mobile-lg {
    margin-left: initial;
  }
  @include sm {
    margin-left: initial;}
}
