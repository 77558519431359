@import '../../../../css/mixins/default_media_query';
@import "../../../../css/variables";

// .checkbox {
//   margin-top: 12px;
// }
.checkbox {
  width:2rem;
  height:2rem;
  margin-left: 0.5rem;
  @include mobile-sm {
    padding-top:0.5rem;
  } 
  @include mobile-md {
    padding-top:0.5rem;
  } 
  @include mobile-lg {
    padding-top:0.5rem;
  } 
  @include sm {
    padding-top:0.5rem;
  } 
  @include md-sm {
    padding-top:0.5rem;
  }
}

.errorMessage {
  bottom: -3px;
  color: #ff6347;
  font-size: 10px;
  position: absolute;
  text-align: right;
  white-space: nowrap;
  width: 100%;
}

.block {
  float: left;
  position: relative;
  width: 100%;
  height: auto;
}

.block-text-container {
  float: left;
  font-size: 1rem;
  height: auto;
  margin: 5px 0;
  position: relative;
  width: 100%;
}

.form-text-area {
  height: auto;
  width: calc(100% - 148px);
}

// .title {
//   @include mobile-sm{
//     margin-bottom: 2rem;
//   }
//   @include mobile-md{
//     margin-bottom: 2rem;
//   }
//   @include mobile-lg{
//     margin-bottom: 2rem;
//   }
//   @include sm{
//     margin-bottom: 1.5rem;
//   }
//   @include md-sm{
//     margin-bottom: 1.5rem;
//   }
//   @include md{
//     margin-bottom: 1rem;
//   }
//   @include lg{
//     margin-bottom: 1rem;
//   }
//   @include max-screen{
//     margin-bottom: 1rem;
//   }
// }
 
.btn {
  border: 0;
  background: transparent;
  color: $color-gray;
  cursor: pointer;
  float: right;

  &:hover {
    color: #717171;
  }
}

.input-wrapper {
  @include sm {
    width: 100%;
  }
  @include md {
    width: 50%;
  }
  @include lg {
    width: 50%;
  }
  float: left;
}

.textarea {
  @include sm {
    border: solid 1px #ccc;
    width: 49%;
  }
  float: right;
  height: 100px;
}

.container {
  @include sm {
    margin-top: 110px;
  }
}

.block-value {
  @include sm {
    height: auto;
  }
  @include md {
    height: auto;
  }
  @include lg {
    height: auto;
  }
  display: flex;
  float: left;
  font-size: 1rem;
  height: 2rem;
  line-height: 1.5rem;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  width: calc(100% - 50%);
}

.btn-price {
  @include sm {
    margin-left: 25%;
    width: 60%;
  }
  @include md {
    margin-left: 15%;
    width: 100px;
  }
  @include lg {
    margin-left: 15%;
    width: 100px;
  }
  background: $color-tecno;
  border: 0;
  border-radius: 5px;
  color: $color-white;
  cursor: pointer;
  float: left;
  font-family: "Roboto";
  font-size: 0.9rem;
  height: 26px;
  padding: 0;
  position: relative;
  text-align: center;
  transition: background 0.3s ease;

  &:hover {
    background: $btn-hover;
  }
}

.label-price {
  border: 1px;
  border-color: #808285;
  border-style: solid;
  color: #808285;
  display: block;
  padding-left: 10px;
  width: 40%;
}

.btn {
  @include sm  {
    width: 47% !important;
  }
  @include md-sm {
    width: 175px;
  }
  @include md {
    width: 175px;
  }
  @include lg {
    width: 175px;
  }
  border: 0;
  border-radius: 5px;
  color: $color-white;
  float: left;
  font-family: $globalfont;
  font-size: 0.9rem;
  height: 2rem;
  //padding: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  transition: background .3s ease;
  text-decoration: none;

  &--secondary {
    margin: 5px 5px 5px 0px;
  }

  &--color-red {
    background: $color-red;
    font-weight: bold;

    &:hover {
      background: $color-red-hover;
    }
  }

  &--color-blue {
    background: $color-blue;
    font-weight: bold;

    &:hover {
      background: $color-blue-hover;
    }
  }

  &--color-yellow {
    background: #cab226;
    font-weight: bold;

    &:hover {
      background: $color-audio-yellow-hover;
    }
  }

  &--color-green {
    background: $color-green;
    font-weight: bold;

    &:hover {
      background: $color-green-hover;
    }
  }

  &--color-gray {
    background: $color-gray;
    font-weight: bold;

    &:hover {
      background: $color-gray-hover;
    }
  }

}

.active {
  // background: $color-tecno;
  cursor: pointer;

  &:hover {
    background: $btn-hover;
  }
}

.btn-disabled {
  cursor: default;
  background: $color-gray-disable;
}

.savetip-container {
  background-color: $btn-bg-color;
  border-radius: 6px;
  color: $color-white;
  float: left;
  font-family: "Montserrat", sans-serif;
  padding: 10px;
  /* display: inline-block; */
  position: absolute;
  bottom: 100%;
  // left: 2rem;
  transform: translateY(-40%);
  text-align: center;
  visibility: hidden;
  width: 450px;
  z-index: 1002;
  left: 0;
}

.savetip-text {
  float: left;
  width: 100%;
  text-align: left;
  text-transform: none;
}

.btn-disabled:hover .savetip-container {
  visibility: visible;
}

.accordion-header {
  float: right;
  margin-top: 5px;
}

.rotate {
  transform: rotate(180deg);
}

.placeholder-text::placeholder {
  color: rgb(203, 201, 201);
  font-weight: bold;
}
