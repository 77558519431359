@import 'default_table';
@import 'default_title';
@import '../variables';

@mixin report-title {
  @include general-title;
}

@mixin report-table {
  @include wrapper;
  margin-top: 5px;
}

@mixin report-paginate {
  @include paginate;
}

@mixin report-header {
  @include header;
}

@mixin report-header-column {
  @include header-column;
}

@mixin report-body {
  @include body;
}

@mixin report-row {
  @include rowtb;
  text-decoration: none;
  &:hover {
    background-color: $color-gray-soft;
  }
}

@mixin report-column {
  @include column;
}

@mixin report-field {
  @include field;
}

@mixin report-download {
  @include download;
}
