@import '../../../../css/mixins/default_media_query';
@import '../../../../css/variables';
@import '../../../../css/mixins/default_buttons';
@import '../../../../css/mixins/default_table';

.content {
  @include mobile-sm {
    margin-top: 20px;
  }
  @include mobile-md {
    margin-top: 20px;
  }
  @include mobile-lg {
    margin-top: 20px;
  }
  @include sm {
    margin-top: 20px;
  }
  @include md {
    // margin-top: 20px;
  }
  @include lg {
    // margin-top: 10px;
  }
  @include max-screen {
    // margin-top: 10px;
  }
  width: 100%;
  float: left;
  position: relative;
}

.companyContainer {
  @include mobile-sm {
    margin-top: 5px;
  }
  @include mobile-md {
    margin-top: 5px;
  }
  @include mobile-lg {
    margin-top: 5px;
  }
  @include sm {
    margin-top: 5px;
  }
  float: left;
  margin-top: 10px;
  position: relative;
  width: 100%;
}

.companyTitleContainer {
  margin-bottom: 30px;
  float: left;
  position: relative;
  width: 100%;
}

.companyTitle {
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: 100%;
  }
  color: $color-tecno;
  font-size: 20px;
  float: left;
  height: 30px;
  line-height: 1.5;
  position: relative;
  width: 450px;
}

.btnContainer {
  float: left;
  position: relative;
  width: 100%;
}

.btn {
  @include btn;
  @include sm {
    margin-left: 1rem;
    width: 185px;
  }
  @include md {
    margin-left: 1rem;
    width: 185px;
  }
  @include lg {
    margin-left: 1rem;
    width: 185px;
  }
  @include max-screen {
    margin-left: 1rem;
    width: 185px;
  }
  &:hover {
    background-color: $btn-hover;
  }
  padding: 0;
}

.personBtn {
  @include btn;
  @include mobile-sm {
    margin-left: 0;
  }
  @include mobile-md {
    margin-left: 0;
  }
  @include mobile-lg {
    margin-left: 0;
  }
  @include sm {
    margin-left: 0;
  }
  @include sm-md {
    margin-left: 0;
  }
  @include md-sm {
    margin-left: 0;
  }
  @include md {
    margin-left: 0;
  }
  @include lg {
    margin-left: 0;
  }
  padding: 0 0.5rem;
}

.editBtn {
  @include btn;
  padding: 0 0.5rem;
  margin-left: 20px;
  @include mobile-sm {
    margin-left: initial;
    margin: 1rem 0;
  }
  @include mobile-md {
    margin-left: initial;
    margin: 1rem 0;
  }

  @include mobile-lg {
    margin-left: initial;
    margin: 1rem 0;
  }
}

.removeBtn {
  @include btn;
  padding: 0 0.5rem;
  margin-left: 20px;
  background: $color-audio-red;
  @include mobile-sm {
    margin-left: initial;
    margin: 1rem 0;
  }
  @include mobile-md {
    margin-left: initial;
    margin: 1rem 0;
  }

  @include mobile-lg {
    margin-left: initial;
    margin: 1rem 0;
  }
  &:hover {
    background: $color-audio-red-hover;
  }
}

.table {
  @include table;
}

.header {
  @include header;
}

.column {
  @include column;
}

.row {
  @include rowtb;
}

.username {
  @include field;
  width: 250px;
}

.name {
  @include field;
  width: 250px;
}

.email {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
}

.columnIcons {
  width: 63px;
}
