@import "../../../css/mixins/default_media_query";
@import '../../../css/variables';
@import '../../../css/mixins/default_buttons';

.wrapper {
  @include mobile-sm {
    float: left;
    width: 100%;
    padding: 0;
  }
  @include mobile-md {
    float: left;
    width: 100%;
    padding: 0;
  }
  @include mobile-lg {
    float: left;
    width: 100%;
    padding: 0;
  }
  height: 98%;
  margin-bottom: 20px;
  overflow-y: auto;
  // padding: 25px 25px 0 75px;
}

// .container {
//  padding: 25px 25px 0 75px;
// }

.row {
  color: $color-grafite;
  float: left;
  font-size: 1.1rem;
  height: 3rem;
  // line-height: 3rem;
  // margin-bottom: 10px;
  position: relative;
  width: 100%;
  margin-top:0.3rem;
}

.saveContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  height: 48px;
  line-height: 3;
  margin-bottom: 10px;
  margin-top: 15px;
  position: relative;
  width: 100%;
}

.innerCard {
  @include sm {
    width: 98%;
    float: left;
  }
  @include md {
    // margin-left: 10px;
    width: 98%;
    float: left;
  }
  @include lg {
    // margin-left: 45px;
    width: 45%;
    float: left;
  }
  @include max-screen {
    width: 45%;
    // margin-left: 45px;
  }
}

.outerCard {
  margin-bottom: 15px;
  width: 100%;
}

.header {
  width: 100%;
  background-color: $conf-standard-color;
  height: 30px;
  padding-top: 10px;
  color: white;
  text-align: center;
}

.labelText {
  @include mobile-sm {
    width: 100%;
    float: left;
  }
  @include mobile-md {
    width: 100%;
    float: left;
  }
  @include mobile-lg {
    width: 100%;
    float: left;
  }
  @include sm {
    width: 100%;
    float: left;
  }
  font-family: $globalfont;

}

.content {
  margin-top: 70px;
}

.item {
  @include sm {
    width: 96%;
    margin-top: 15px;
    float: left;
  }

  @include md {
    width: 96%;
    margin-top: 15px;
    float: left;
  }
  @include lg {
    width: 96%;
    margin-top: 15px;
    float: left;
  }
  @include max-screen {
    width: 96%;
    margin-top: 15px;
    float: left;
  }
}

.body {
  margin: 50px 25px 0px 25px;
}

.options {
  @include mobile-sm {
    margin-left: 0;
    float: left;
    width: 100%;
  }
  @include mobile-md {
    margin-left: 0;
    float: left;
    width: 100%;
  }
  @include mobile-lg {
    margin-left: 0;
    float: left;
    width: 100%;
  }
  @include sm {
    margin-left: 0;
    float: left;
    width: 100%;
  }
  margin-left: 20px;
}

.text {
  @include mobile-sm {
    float: left;
    width: 70%;
    margin-top: 15px;
    margin-left: 10px;
  }
  @include mobile-md {
    float: left;
    width: 70%;
    margin-top: 15px;
    margin-left: 10px;
  }
  @include mobile-lg {
    float: left;
    width: 70%;
    margin-top: 15px;
    margin-left: 10px;
  }
  @include sm {
    float: left;
    width: 70%;
    margin-top: 15px;
    margin-left: 10px;
  }
  color: $color-grafite;
  font-size: 18px;
  font-family: $globalfont;
}

.toggle {
  @include mobile-sm {
    float: right;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 30px;
    margin-top: 15px;
  }
  @include mobile-md {
    float: right;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 30px;
    margin-top: 15px;
  }
  @include mobile-lg {
    float: right;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 30px;
    margin-top: 15px;
  }
  @include sm {
    float: right;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 30px;
    margin-top: 15px;
  }
  float: right;
  margin-bottom: 20px;
  margin-right: 45px;
}

.divider {
  @include mobile-sm {
    margin-top: 0;
    float: left;
  }
  @include mobile-md {
    margin-top: 0;
    float: left;
  }
  @include mobile-lg {
    margin-top: 0;
    float: left;
  }
  @include sm {
    margin-top: 0;
    float: left;
  }
  width: 96%;
  border-bottom-style: solid;
  border-bottom-color: #cecccc;
  border-bottom-width: 1px;
  float: left;
  margin-bottom: 30px;
  margin-left: 1%;
}

.btnSave {
  @include btn;
  width: 100%;
  font-weight: bold;
  background-color: $btn-bg-color;
  &:hover {
    background-color: $btn-hover;
  }
}

.btnSave:hover {
  background-color: $btn-bg-color;
}

.middleContent {
  @include mobile-sm {
    padding: 0;
  }
  @include mobile-md {
    padding: 0;
  }
  @include mobile-lg {
    padding: 0;
  }
  height: 100%;
  margin-left: 50%;
  // padding: 25px 25px 0 75px;
  transform: translateX(-50%);
  width: 100%;
}

.hideModal {
  visibility: hidden;
}
