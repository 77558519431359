@import 'variables';

$height: 200px;
$width: 305px;

.notification-wrapper {
  background-color: $notification-background;
  border: solid 1px $color-tecno;
  border-bottom: 0;
  bottom: 0;
  box-sizing: border-box;
  font-family: $globalfont;
  height: $height;
  position: absolute;
  right: 66px;
  transition: bottom .3s ease;
  width: $width;
}

.notification-hide {
  bottom: -$height;
}

.notification-text-container {
  float: left;
  position: relative;
  top: 30px;
  width: 100%;
}

.notification-text-alarm {
  // color: $color-tecno;
  color: $color-grafite;
  font-size: 21px;
  height: 28px;
  text-align: center;
  width: 100%;
}

.notification-text-alarm-item {
  box-sizing: border-box;
  padding: 0 5px;
  position: absolute;
  transition: opacity .7s ease;
  width: $width;
}

.notification-text-client {
  // color: $sidebar-icon-color;
  color: $color-grafite;
  font-size: 1rem;
  margin-top: 25px;
  text-align: center;
  width: 100%;
}

.notification-button-wrapper {
  float: left;
  height: 28px;
  left: 50%;
  margin-top: 15px;
  position: relative;
  transform: translateX(-50%);
  width: 145px;
}
