@import 'mixins/default_media_query';
@import 'variables';

.icon-add {
  color: $color-white;
  fill: $color-white;
  font-size: 16px;
  height: 52px;
  line-height: 3.25;
  position: absolute;
  right: 15px;
  text-decoration: none;
  top: 0;
  transition: .3s ease;

  &:hover {
    color: $color-gray;
    fill: $color-gray;
  }
}

.icon-add-logo {
  @include sm {
    margin-top: 7px;
  }
  margin-top: 14px;
  fill: inherit;
  float: left;
  left: -5px;
  position: relative;
}

.icon-order {
  fill: inherit;
}

.icons-order {
  transition: .3s ease;

  &:hover {
    color: $color-tecno;
    fill: $color-tecno;
  }
}

.icons-order--rotate {
  transform: rotate(180deg);
}

.icon-download {
  fill: $color-white;
  float: left;
  margin-left: 10px;
  margin-top: 7px;
}

.icon-arrowdown {
  fill: $color-white;
  float: right;
  margin-right: 5px;
  margin-top: 7px;
}
