@import '../../../css/mixins/default_media_query';
@import '../../../css/mixins/default_title';
@import '../../../css/mixins/default_table';
@import '../../../css/mixins/default_buttons';
@import '../../../css/variables';


@mixin attend-wrapper {
  float: left;
  margin-top: 15px;
  position: relative;
  width: 100%;
}

@mixin attend-title {
  box-sizing: border-box;
  color: $color-grafite;
  float: left;
  // padding: 8px;
  position: relative;
  width: 100%;
  font-size: 1.1rem;
  line-height: initial;
  // font-weight: bold;
}

@mixin attend-content {
  display: grid;
  float: left;
  grid-gap: 10px;
  margin-top: 2px;
  position: relative;
  width: 100%;
}

@mixin attend-empty {
  padding-left: 15px;
  float: left;
  height: 45px;
  position: relative;
  width: 100%;
}

@mixin attend-border {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  height: $border-height;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@mixin attend-text {
  box-sizing: border-box;
  color: --color-white;
  float: left;
  height: calc(100% - 7px);
  line-height: 2.37;
  margin-top: $border-height;
  padding-left: 5px;
  position: relative;
}
