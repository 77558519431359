@import '../../css/mixins/default_media_query';
@import '../../css/variables';


.wrapper {
  background-color: $color-tecno;
  display: block;
  height: 55px;
  position: absolute;
  width: 100%;
  z-index: 996;
}


.content {
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: 100%;
  }
  @include md-sm {
    width: 100%;
  }
  @include md {
    width: 100%;
  }
  @include lg {
    width: calc(100% - 30px);
  }
  @include max-screen {
    width: calc(100% - 40px);
  }
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  float: right;
  height: inherit;
  position: relative;
  width: 100%;
}




.icon {
  cursor: pointer;
  float: right;
  margin-right: 15px;
  position: relative;
  transform: translateY(15px);
}

.icon .iconText {
  background-color: $color-tecno;
  border-radius: 6px;
  color: $color-white;
  font-family: $globalfont;
  padding: 5px 0;
  position: absolute;
  text-align: center;
  visibility: hidden;
  width: 365px;
  z-index: 1;
  margin-top: 45px;
  float: left;
  margin-left: -185px;
}

.icon:hover .iconText {
  visibility: visible;
}

.localeContainer {
  @include mobile-sm {
    width: 150px;
  }
  @include mobile-md {
    width: 150px;
  }
  @include mobile-lg {
    width: 150px;
  }
  @include sm {
    width: 150px;
  }
  box-sizing: border-box;
  color: $color-white;
  float: right;
  font-family: $globalfont;
  padding-top: 3px;
  position: relative;
  padding: 9px 6px 6px;
}

.localeButton {
  background: transparent;
  border: 0;
  color: $color-white;
  font-size: 1rem;
  font-weight: 800;
  font-family: $globalfont;
  cursor: pointer;
  position: relative;
  padding: 5px 6px;
}

.localeChosen {
  border: 2px;
  border-color: $color-white;
  border-radius: 5px;
  border-style: solid;
}

.searchbar {
  @include mobile-sm {
    visibility: hidden;
    width: 0;
  }
  @include mobile-md {
    visibility: hidden;
    width: 0;
  }
  @include mobile-lg {
    box-sizing: border-box;
    float: left;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
  }
  @include sm {
    float: right;
    height: 100%;
    width: auto;
  }
  @include md-sm {
    float: right;
    height: 100%;
    width: auto;
  }
  @include md {
    float: right;
    height: 100%;
    width: auto;
  }
  @include lg {
    float: right;
    height: 100%;
    width: auto;
  }
  @include max-screen {
    float: right;
    height: 100%;
    width: auto;
  }
  position: relative;
}

.user {
//   @include mobile-sm {
//    margin-top: -20px;
//      }
//   @include mobile-md {
//     margin-top: -20px;
//    }
//   @include mobile-lg {
//     margin-top: -20px;
//   }
//   @include sm {
//     margin-top: -20px;
//   }
//   @include md-sm {
//     margin-top: -20px
//   }
//   @include md {
//     margin-top: -20px
//   }
//   @include lg {
//  margin-top: -10px
//   }
//   @include max-screen {
//     margin-top: -10px
//   }
  float: right;
  position: relative;
}

.phone {
    padding: 15px 6px 6px;
    float: right;
    position: relative;
    // margin-top: -3px;
  }

.iconContainer {
  @include mobile-sm {
    transform: translateY(2px);
    // top: -10px;
    }
  @include mobile-md {
    transform: translateY(2px);
    // top: -10px;
    }
  @include mobile-lg {
    transform: translateY(2px);
    // top: -10px;
    }
  @include sm {
    transform: translateY(2px);
    // top: -10px;
    }
  @include md-sm {
      transform: translateY(2px);
      // top: -10px;
    }
  @include md {
      transform: translateY(2px);
      // top: -10px;
    }
  @include lg {
      transform: translateY(2px);
      // top: -4px;
    }
  @include max-screen {
      transform: translateY(2px);
      // top: -4px;
    }
    
  float: right;
  position: relative;
  transform: translateY(10px);
  padding-top: 7px;
}

.iconButton {
  -webkit-appearance: none;
  border: none;
  background: none;
  cursor: pointer;
}

.iconActive {
  color: rgb(255, 255, 255);
}

.remindersDeactivated {
  color: rgba(255, 255, 255, 0.5);
}

.reminderDot {
  background: rgba(239, 93, 88, 0.95);
  border-radius: 100%;
  color: $color-white;
  display: block;
  height: 20px;
  margin: 0;
  width: 20px;
  position: absolute;
  bottom: 5px;
  right: 3px;
}

.chatDot {
  background: rgba(255, 196, 100, 1);
  border-radius: 100%;
  color: $color-white;
  display: block;
  height: 15px;
  margin: 0;
  width: 15px;
  position: absolute;
  bottom: 0px;
  right: 5px;
}

.reminderNumber {
  float: left;
  width: 100%;
  padding-top: 0;
}
