@import 'mixins/default_media_query';
@import 'variables';


.form-container {
  float: left;
  height: inherit;
  left: 50%;
  overflow-y: auto;
  padding: 0 15px;
  position: relative;
  transform: translateX(-50%);
  width: 100%;
}

.form-title {
  // @include sm {
  //   line-height: 2;
  // }
  color: $color-grafite;
  font-size: 1.1rem;
  // height: 28px;
    // text-align: center;
  width: 100%;
  margin-top: 0.3rem;

@include mobile-sm{
  margin-bottom: 2rem;
}
@include mobile-md{
  margin-bottom: 2rem;
}
@include mobile-lg{
  margin-bottom: 2rem;
}
@include sm{
  margin-bottom: 1.5rem;
}
@include md-sm{
  margin-bottom: 1.5rem;
}
@include md{
  margin-bottom: 1rem;
}
@include lg{
  margin-bottom: 1rem;
}
@include max-screen{
  margin-bottom: 1rem;
}
&--client-name {
    color: $color-gray-title;
  }
}

// .form-title--client-name {
//   color: $color-gray-title;
// }

.form-input {
  border: solid 1px $color-gray-input;
  box-sizing: border-box;
  color: $color-gray-form;
  display: block;
  float: left;
  font-family: $globalfont;
  height: $heightformpage;
  padding: 0 5px;
  position: relative;
  transition: .3s ease;

  &:focus {
    border-color: $color-tecno;
  }

  &:hover {
    border-color: $color-tecno;
  }

  &--disable {
    color: $color-gray-disable;
  }

  &--error {
    border: solid 1px $color-tomato;
    white-space: nowrap;
  }

  &--ok {
    border: solid 1px $color-gray-input;
  }
}

.form-text-error {
  @include sm {
    display: none;
  }
  color: $color-tomato;
  display: block;
  float: left;
  font-size: 10px;
  margin-top: 4px;
  position: absolute;
  right: 0;
  text-align: right;
  width: 200%;
}

.form-btn-container {
  @include sm {
    float: left;
    position: relative;
    width: 100%;
  }
  @include md {
    bottom: 0;
    position: relative;
    right: 0;

    &--client {
      left: 50%;
      transform: translateX(-50%);
      width: 368px;
    }
  }
  @include lg {
    bottom: 0;
    position: relative;
    right: 0;

    &--client {
      left: 50%;
      transform: translateX(-50%);
      width: 368px;
    }
  }
}

.form-btn-container--management {
  width: 100%;
}

.form-checkbox {
  @include sm {
    margin-top: 5px;
    transform: translateY(0%);
  }
  border-radius: 0;
  top: 50%;
  transform: translateY(-50%);
}

.form-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  // appearance: none;
  border: solid 1px $color-gray-input;
  // border-radius: 0;
  clear: left;
  float: left;
  height: $heightformpage;
  padding-right: 8px;
  // padding: 0px 8px;
  width: 100%;

  &--enable {
    background: transparent url('../img/select-icon.png') no-repeat center right 8px;
    background-size: 12px;
    color: $color-gray-form;
    cursor: pointer;
    transition: .3s ease;

    &:focus {
      border-color: $color-tecno;
    }

    &:hover {
      border-color: $color-tecno;
    }
  }

  &--disable {
    background: transparent;
    color: $color-gray-disable;
  }

  &--delete-icon {
    width: calc(100% - 26px);
  }
}

.form-required {
  color: $color-tecno;
}

.form-icon-delete {
  box-sizing: border-box;
  height: 26px;
  position: absolute;
    
  @include mobile-sm{
    right: 0;
    top: 2.5rem;
  }
  @include mobile-md{
    right: 0;
    top: 2.5rem;
  }
  @include mobile-lg{
    right: 0;
    top: 2.5rem;
  }
  @include sm{
    right: 0;
    top: 2.5rem;
  }
  @include md-sm{
    right: -0.2rem;
    top: 2.5rem;
    
  }
  @include md{
    right: -0.5rem;
    top: 2.5rem;
    
  }
  @include lg{
    right: -0.1rem;
    top: 2.5rem;
    
  }
  @include max-screen{
    right: -0.1rem;
    top: 2.5rem;
  }
}

.form-textarea {
  box-sizing: border-box;
  float: left;
  position: relative;
  resize: none;
  width: 100%;
}

.form-monitoring {
  font-size: 16px;
  height: 35px;
  margin-top: 20px;
  padding-left: 10px;
}
