@import 'mixins/default_media_query';
@import 'variables';
@import 'mixins/default_buttons';

.btn {
  @include btn;
  padding:0.3rem;
  &:hover {
    text-decoration: none;
  }

  &--add {
    clear: left;
    // height: 37px;
    width: 173px;
  }

  &--add-contact-phone {
    @include mobile-sm {
      width: 100%;
    }
    @include mobile-md {
      width: 100%;
    }
    @include mobile-lg {
      width: 100%;
    }
    @include sm {
      width: 100%;
    }
    height: 26px;
    // margin-top: 10px;
    width: 380px;
  }

  &--add-transmissor {
    clear: left;
    // height: 37px;
    width: 240px;
  }

  &--attend-emergency {
    float: left;
    font-family: "Montserrat", sans-serif;
    font-size: 0.8rem;
    width: 100%;
    padding: 0;
    height: 2.5rem;
  }

  &--bold {
    font-weight: bold;
  }

  &--bottom {
    bottom: 42px;
  }

  &--color-gray {
    background: $color-gray;
    color: $color-gray-font;
  }

  &--color-green {
    background: $color-green;
    font-weight: bold;

    &:hover {
      background: $color-green-hover;
    }
  }

  &--color-invalid {
    background: $color-gray-invalid;
    cursor: default;
    pointer-events: none;
  }

  &--color-orange {
    background: $color-orange;

    &:hover {
      background: $color-orange-hover;
    }
  }

  &--color-red {
    background: $color-red;
    font-weight: bold;

    &:hover {
      background: $color-red-hover;
    }
  }

  &--color-tecno {
    background-color: $btn-bg-color;
    font-weight: bold;
    &:hover {
      background-color: $color-tecno-hover;
    }
  }

  &--color-loading {
    background-color: $color-tecno-hover;
    font-weight: bold;
  }

  &--confirm-remove-modal {
    font-size: 0.9rem;
    // height: 35px;
    // width: 130px;
    height: 2rem;
    width: auto;
  }

  &--download {
    float: right;
    // height: 37px;
    top: 15px;
    height: 2rem;
    width: auto;
  }

  &--download-text {
    font-weight: bold;
    margin-right: 5px;
    position: relative;
    top: 30%;
  }

  &--get-attend-or-attend-voice-call {
    height: 37px;
    width: 197px;
  }

  &--modal-attend {
    bottom: 20px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  &--right {
    float: right;
    line-height: initial;
    height: 2rem;
    width: auto;
  }

  &--multi {
    clear: left;
    height: 2rem;
    width: auto;
  }

  &--form {
    height: 2rem;
    width: auto;
  }
}

.btn-active {
  cursor: pointer;

  &:hover {
    background: $btn-hover;
  }
}

.btn-mobile {
  @include sm {
    overflow: hidden;
    padding-left: 2px;
    text-overflow: ellipsis;
  }
}

.btn-call {
  border: 0;
  border-radius: 5px;
  bottom: 25px;
  color: $color-white;
  float: left;
  font-family: $globalfont;
  font-size: 0.9rem;
  padding-left: 15px;
  position: relative;
  transition: background 0.3s ease;

  &--cursor {
    cursor: pointer;
  }

  &--right {
    @include sm {
      float: left;
      margin-top: 70px;
    }
    @include md {
      float: left;
    }
    @include lg {
      float: right;
      margin-right: 325px;
    }
  }

  &--text {
    float: left;
    padding-top: 10px;
    position: relative;
  }
}

.btn-audio-control {
  @include sm {
    clear: left;
    margin-bottom: 5px;
    margin-top: -15px;
  }
  @include md {
    clear: left;
    margin-bottom: 5px;
    margin-top: -20px;
  }
  @include lg {
    margin-top: -40px;
  }
  display: inline-flex;
  float: left;
}

.btn-audio-single {
  @include sm {
    margin-right: 5px;
  }
  border-radius: 100%;
  height: 50px;
  margin-right: 15px;
  overflow: hidden;
  width: 50px;
}

.btn-delete {
  background: transparent;
  border: 0;

  &--alarm {
    margin-left: -10px;
  }
}

.btn-search {
  float: right;
  height: 35px;
  line-height: 2.8;
  width: 40px;
}

.btn-start {
  fill: $color-white;
  padding-right: 10px;
  stroke: $color-white;
}

.btn-listen-color {
  background: $color-audio-blue;
}

.btn-speak-color {
  background: $color-audio-yellow;
}

.btn-two-way-audio-color {
  background: $color-audio-red;
}

.btn-report {
  border-radius: 10px;
  height: 40px;
  position: relative;
  width: 45px;
}

.tooltip {
  display: inline;
  float: left;
  position: relative;

  &:hover {
    &::after {
      background: $color-black;
      border-radius: 5px;
      bottom: 47px;
      color: $color-white;
      content: attr(title);
      left: 0;
      opacity: 0.8;
      padding: 5px 15px;
      position: absolute;
      width: 80px;
      z-index: 98;
    }

    &::before {
      border: solid;
      border-color: $color-black-light transparent;
      border-width: 6px 6px 0;
      bottom: 41px;
      content: "";
      left: 16px;
      position: absolute;
      z-index: 99;
    }
  }
}

