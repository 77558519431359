
.high {
  fill: #EC0900;
  transition: .3s ease;

  &:hover {
    fill: #A50505;
  }
}

.medium {
  fill: #F8C402;
  transition: .3s ease;

  &:hover {
    fill: #BF9202;
  }
}

.low {
  fill: #0DA950;
  transition: .3s ease;

  &:hover {
    fill: #057532;
  }
}

.system {
  fill: #0A6CC1;
  transition: .3s ease;

  &:hover {
    fill: #095289;
  }
}
