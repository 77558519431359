@import '../../../css/mixins/default_media_query';
@import '../../../css/mixins/default_title';
@import '../../../css/mixins/default_table';
@import '../../../css/mixins/default_buttons';
@import '../../../css/variables';
@import '../../../css/paginate';


.title {
  @include general-title;
}

.subtitle {
  @include subtitle;
}

.paginate {
  @include paginate;
  position: absolute;
  right: 0;
  top: -2.8rem;
  @include mobile-sm {
    position: relative;
    float: right;
    top: 0;
  }
  @include mobile-md {
    position: relative;
    float: right;
    top: 0;
  }
  @include mobile-lg {
    position: relative;
    float: right;
    top: 0;
  }
  
}

.table {
  @include table;
  @include wrapper;
  margin-top: 5px;
}

.header {
  @include header;
  height: 2rem;
}

.header-column {
  @include header-column;
 }

.row {
  @include rowtb;
}

.column {
  @include column;
}

.removed {
  @include removed;
}

.actions {
  @include actions;
}

.icons {
  @include icons;
}

.add {
  @include add;
}


.table-body {
  @include table-body
}


.rotate {
  transform: rotate(180deg);
}


.id {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md-sm {
    width: 55px;
  }
  @include md {
    width: 55px;
  }
  @include lg {
    width: 55px;
  }
  width: 55px;
  margin-right: 15px;
}

.filterBtn {
  @include btn;
  @include mobile-sm {
    width: 100%;
  }
  @include mobile-md {
    width: 100%;
  }
  @include mobile-lg {
    width: 100%;
  }
  @include sm {
    width: 30%;
    margin-left:1rem;
  }
  @include md-sm {
    margin-left:2rem;
    width: 173px;
  }
  @include md {
    margin-left:2rem;
    width: 173px;
  }
  @include lg {
    margin-left:2rem;
    width: 173px;
  }
  @include max-screen {
     margin-left:2rem;
     width: 173px;
  }
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  &:hover {
    background-color:$btn-hover;
  }
}

.dateClosed {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  };
  @include md-sm {
    display: none;
  }
  @include md {
    display: none;
  }
  @include lg {
    display: none;
  }
  @include max-screen
  { display: block;} 
  @include field;
  width: 200px;

}

.dateOpen {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md {
    display: none;
  }
  @include lg {
    width: 200px;
  }
  @include field;
  width: 200px;
}

.priority{
  margin-top: 0.1rem;
}

.priority,
.audioIcon {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include field;
  width: 150px;
  font-size: 0.7rem;
}

.resolvedBy {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md-sm {
    display: none;
  }
  @include field;
  width: 300px;
}

.attendant {
  @include mobile-sm {
    display: none;
  }
  @include mobile-md {
    display: none;
  }
  @include mobile-lg {
    display: none;
  }
  @include sm {
    display: none;
  }
  @include md-sm {
    display: none;
  }
  @include field;
  width: 300px;
}
.client {
  @include field;
  width: 300px;
}

.button {
  @include button;
  width: 100px;
}

.headerBtn {
  width: 100px;
  margin-right: 1rem;
}
